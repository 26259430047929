import React, { useEffect, useState, createContext } from "react";
import "./App.css";
import "./slideTransition.scss";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {AppContent} from "./AppContent";
import RouterUtils from "./StableNavigateContext";

import { Logic } from "./functions";
import theme from "./theme";
import { useIdToken } from "react-firebase-hooks/auth";
import { LoginMode } from "./components";


const ThemeContext = createContext<any>({ theme: { ...theme, mode: "chalk" } });

function App() {

  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const [user, loading, error] = useIdToken(logic.fb.auth);

  const [mode, setMode] = useState("chalk");

  const [currentTheme, setCurrentTheme] = useState({ ...theme, mode: mode });


  useEffect(() => {
    localStorage.setItem("colorMode", mode);

    setCurrentTheme({ ...currentTheme, mode: mode });
  }, [mode]);


  useEffect(() => { 
    
    let test1 = localStorage.getItem("colorMode");
    if (test1) {
      setMode(test1);
    }
    let test2 = localStorage.getItem("theme");
    if (test2) {
      let parsed = JSON.parse(test2);
      setCurrentTheme(parsed);
    } else {
      setCurrentTheme({ ...theme, mode: "chalk" });
    }
  }, []);



  useEffect(() => {


    const bootstrapAsync = async () => {


      if (user) {
        let x = await user.getIdToken();
        localStorage.setItem("AuthToken", `Bearer ${x}`);
        let test = localStorage.getItem("userData");
        if (!test) {
          let newData = await logic.generic.getUser();

          if (newData) {
            localStorage.setItem(
              "userData",
              JSON.stringify(newData.userCredentials)
            );
          }
        }
        await logic.generic.getHistory();

        window.dispatchEvent(new Event("auth"));
      }
      
      }
    

    bootstrapAsync();
  }, [user]);


  return (
    <div
      style={{
        backgroundColor: currentTheme.mode == "chalk" ? "black" : "white",
      }}
    >


      <BrowserRouter>
        <RouterUtils>
          <ThemeContext.Provider
            value={{ theme: currentTheme, changeMode: setMode }}
          >
            <AppContent setTheme={setCurrentTheme} />
          </ThemeContext.Provider>
        </RouterUtils>
      </BrowserRouter>
    </div>
  );
}

export default App;
export { ThemeContext };
