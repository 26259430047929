import React, { useContext, useEffect, useState } from 'react';

import { Logic } from '../../functions';


import { DoodleDiv } from '../../styled';
import { Loading } from '.';
import { ThemeContext } from '../../App';

const Doodle = (props:{doodle?: any})=>{
    const themeC = useContext(ThemeContext).theme
    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

    const [image, setImage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(()=>{

        const bootstrapAsync  = async () =>{

            let newImage = await logic.eloise.getDoodle(themeC.mode)

            if(newImage){

                setImage(newImage.b64_json)
                setLoading(false)
            }


        }

        if(props.doodle){
            setImage(props.doodle.image.b64_json)
            setLoading(false)
        }
        else{
        bootstrapAsync()
        }
        

    },[])

    return(
        <>
        {loading?  <Loading /> :
        <DoodleDiv className="mx-auto row">
            <img className="mx-auto" style={{ filter: "grayscale(100%)", maxHeight: "28vh" , maxWidth:"256px"}}  src={`data:image/png;base64,${image}`} />
        </DoodleDiv>}
        
        </>
    )
}


export {Doodle}