import axios from "axios";

import { fb } from "./firebase";



export interface interaction {
  human?: string;
  eloise?: string;
}

class Eloise {
  perms: Function;
  auth:Function;
  other: Function;
  api:string

  constructor(perms: Function, auth:Function, other: Function, api:string) {
    this.api = api
    this.perms = perms;
    this.other = other;
    this.auth = auth
  }

  apiCall: Function = async (route: string, body?: { [key: string]: any }) => {
    let user = await fb.auth.currentUser?.getIdToken();
   
      axios.defaults.headers.common = { Authorization: `Bearer ${user}` };
      return new Promise(async (resolve, reject) => {
        if (body) {
          await axios
            .post(`${this.api}/${route}`, body)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response.status === 403) {
                reject(this.auth())
              } else if (error.response.status === 402) {
                reject(this.perms())
              } else {
                reject(this.other())
              }
            });
        } else {
          await axios
            .get(`${this.api}/${route}`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response.status === 403) {
                reject(this.auth())
              } else if (error.response.status === 402) {
                reject(this.perms())
              } else {
                reject(this.other())
              }
            })
        }
      });
    
  };
  // /editQuery -interfaces with Edit Model  - collab
  editWrite = async (x: {
    type: string;
    length: string;
    topic: string;
    course: string;
    existing: string;
    instruction: string;
  }) => {
    return await this.apiCall("editQuery", x);
  };
  // Chat or Info, Main chat route for Eloise - eloise
  coi = async (
    userInput: string,
    data: any,
    first: string,
    prev?: interaction[]
    ) => {
    let prevFormatted = `${
      prev
        ? prev.map(
            (el: any) => `${first}: "${el.human}" \n eloise: ${el.eloise}\n`
          )
        : ""
    }`;
    let ots = prev
      ? { query: userInput, prev: prevFormatted, app: "eloise", chatData: data }
      : { query: userInput, app: "eloise", chatData: data };
    return await this.apiCall("coi", ots);
  };

  // Validates User Input  -- tour write learn flash //! Not working Well!// 
  adderall = async (userInput: any, expected: any, examples: any) => {
    // let query = `indicate if "${userInput}" is "${expected} examples include:  ${examples}.  respond y or n`;
    // let ots = { query: query, app:"write" };

    // return await this.apiCall("adderall", ots);
    return "y";
  };
  // Just Like Coi //! Needs addapted for better eloise
  remember = async (userInput: string) => {
    let ots = { query: userInput };

    return await this.apiCall("remember", ots);
  };
  
  game = async (userInput: string, prev?: {
    eloise:string,
    human:string
  }[]) => {
    let prevFormatted = `${
      prev
        ? prev
            .map((el: any) => `human: "${el.human}" \n game: "${el.eloise}"\n`)
            .join(" ")
        : ""
    }`;

    let ots = prev
      ? { query: userInput, prev: prevFormatted, app: "game" }
      : { query: userInput, app: "game" };

    return await this.apiCall("zork", ots);
  };

  flash = async (x:{
    name:string,
   topic: string,
    specific: { word: string, def: string, id: string }[],
    course: string,
  }) => {
   
    let ots = await this.apiCall("flashCards", {...x, app:"flash"});
    console.log(ots)
    return ots;
  };
  
  manualFlash = async (x:any) => {
    return await this.apiCall("manualFlash", x);
  }

  editFlash = async (name: string, deck: any[]) => {
    let ots = { name: name, deck: deck };

    return await this.apiCall("editFlash", ots);
  };

  deleteFlash = async (name: string) => {
    let ots = { name: name };

    return await this.apiCall("deleteFlash", ots);
  };

  writeOutline = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("writeOutline", ots);
  };

  addWriting = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("addWriting", ots);
  };
  writeTopics = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("writeTopics", ots);
  };
  writeNext = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("writeNext", ots);
  };
  writeFeedback = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("writeFeedback", ots);
  };
  rewrite = async (x: any) => {
    let ots = { ...x, app: "write" };

    return await this.apiCall("rewrite", ots);
  };

  parseList = async (text: string) => {
    let ots = { text: text };

    return await this.apiCall("parseList", ots);
  };

  notes = async (
    answerKey: { question: string; answer: string },
    student: string
  ) => {
    let ots = { answerKey: answerKey, student: student, app: "learn" };

    return await this.apiCall("notes", ots);
  };

  practiceQuestion = async (course: string, topic: string, prev?: any) => {
    let ots = prev
      ? { course: course, topic: topic, prev: prev, app: "learn" }
      : { course: course, topic: topic, app: "learn" };

    return await this.apiCall("practiceQuestion", ots);
  };

  studyTopics = async (course: string, count: number) => {
    let ots = { course: course, count: count, app: "learn" };

    return await this.apiCall("studyTopics", ots);
  };
  getUpcoming = async (noLocal?: boolean) => {
    if (!noLocal) {
      let prevTest = localStorage.getItem("upcoming");

      if (prevTest) {
        return JSON.parse(prevTest);
      }
    } else {
      return await this.apiCall("upcoming");
    }
  };
  overview = async (course: string, topic: string) => {
    let ots = { topic: topic, course: course, app: "learn" };

    return await this.apiCall("overview", ots);
  };
  getDoodle = async (type: string) => {
    return await this.apiCall("getDoodle", { type: type });
  };

  isVerified = async () => {
    return await this.apiCall("isVerified", { place: "place" });
  };

  addTest = async (date: any, course: string, topic: string) => {
    let ots = { date: date, course: course, topic: topic };

    return await this.apiCall("addTest", ots);
  };
  grade = async (x: any) => {
    let ots = { ...x, app: "learn" };

    return await this.apiCall("grade", ots);
  };
  freeStyle = async (userInput: string, prev?: any) => {
    let ots = prev
      ? { query: userInput, prev, app: "eloise" }
      : { query: userInput, app: "eloise" };

    return await this.apiCall("freeStyle", ots);
  };
  loadFlash = async (deck: any) => {
    let ots = { id: deck, app: "eloise" };
    return await this.apiCall("loadFlash", ots);
  };
  loadOverview = async (id: any) => {
    let ots = { id: id, app: "eloise" };
    return await this.apiCall("loadOverview", ots);
  };
  getMood = async (convo: any) => {
    return await this.apiCall("getMood", { convo: convo, app: "eloise" });
  };

  haiku = async (topic?:string) => {
    let ots =  topic? {topic: topic}: {placeHolder:"place"}

    return await this.apiCall("haiku", ots);
  }

  signUpFill = async()=>{

    return await this.apiCall("signUpFill")
  }

  getDef = async(word:string)=>{

    let ots = { word: word, app:"notes" };

    return await this.apiCall("define",ots)
  }

  contList = async(title:string, exi: string)=>{
    let ots = { title, exi,  app:"notes" };

    return await this.apiCall("continueList", ots)
  }
}

export { Eloise };
