import { Logic } from "./functions"

const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)


const themes = [
    {
        primary: 'hsla(286, 33%, 58%)',
        secondary: 'hsla(159, 31%, 78%)',
        white: 'hsla(90, 60%, 96%)',
        font: "roboto",
        color: "white",
        red: "hsla(351, 70%, 38%)",
        relFontSize: "1.25rem",
        borderRadius: true,
        border: "none",
        accent: "hsla(5, 88%, 57%)",
        grey: "hsla(180, 6%, 56%)",
        borderImage: `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20`
    },
    {
        primary: 'hsla(163, 23%, 53%)',
        secondary: 'hsla(9, 8%, 54%)',
        white: 'hsla(90, 60%, 96%)',
        font: "roboto",
        color: "white",
        red: "hsla(351, 70%, 38%)",
        relFontSize: "1.25rem",
        borderRadius: true,
        border: "none",
        accent: "hsla(5, 88%, 57%)",
        grey: "hsla(180, 6%, 56%)",
        borderImage: `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20`

    },
    {
        primary: 'hsla(25, 97%, 41%)',
        secondary: 'hsla(37, 100%, 68%)',
        white: 'hsla(90, 60%, 96%)',
        font: "roboto",
        color: "white",
        red: "hsla(351, 70%, 38%)",
        relFontSize: "1.25rem",
        borderRadius: true,
        border: "none",
        accent: "hsla(5, 88%, 57%)",
        grey: "hsla(180, 6%, 56%)",
        borderImage: `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20`

    },
   
     {
        primary: 'hsla(184, 9%, 32%)',
        secondary: 'hsla(36, 87%, 67%)',
        white: 'hsla(90, 60%, 96%)',
        font: "roboto",
        color: "white",
        red: "hsla(351, 70%, 38%)",
        relFontSize: "1.25rem",
        borderRadius: true,
        border: "none",
        accent: "hsla(5, 88%, 57%)",
        grey: "hsla(180, 6%, 56%)",
        borderImage: `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20`

    },
    {
        primary: 'hsla(78, 33%, 50%)',
        secondary: 'hsla(113, 33%, 26%)',
        white: 'hsla(90, 60%, 96%)',
        font: "roboto",
        color: "white",
        red: "hsla(351, 70%, 38%)",
        relFontSize: "1.25rem",
        borderRadius: true,
        border: "none",
        accent: "hsla(5, 88%, 57%)",
        grey: "hsla(180, 6%, 56%)",
                borderImage: `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20`

    },

]

const theme = logic.generic.getRandom(themes)

export default theme