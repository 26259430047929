import axios from "axios";
import { useEffect, useRef } from "react";

import { fb } from "./firebase";

import { EventInterface, ClassInterface, ActivityItem, noteItem,   } from "../AppContent";

class Generic {

  

  perms:Function
  auth:Function
  other:Function
  api:string

  constructor(perms:Function, auth:Function, other:Function, api:string){
    this.api = api
    this.perms = perms;
    this.auth = auth;
    this.other = other;

  }

  apiCall:Function = async (route: string, body?: any) => {

    let user = await fb.auth.currentUser?.getIdToken();
   
    axios.defaults.headers.common = { Authorization: `Bearer ${user}` };
    
      return new Promise(async (resolve, reject) => {
    
        if(body){
        await axios
          .post(`${this.api}/${route}`, body)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.status === 403) {
              reject("auth");
            } else if (error.status === 402) {
              reject("perms");
            } else {
              reject(error.message);
            }
          });
        }
        else{
          await axios
          .get(`${this.api}/${route}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.status === 403) {
              reject("auth");
            } else if (error.status === 402) {
              reject("perms");
            } else {
              reject(error.message);
            }
          });
        }
      });
    }
    
    
    capitalize:Function = (word: string) =>{
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  camelize:Function = (str: string) =>{
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
 }
  inputHandler:Function =(
    event: React.ChangeEvent<HTMLInputElement>,
    setter: Function
  ) => {
    const enteredName = event.target.value;
    setter(enteredName);
 }

  getRandom:Function =(x: any[]) => {
    let ots = x[Math.floor(Math.random() * x.length)];
    return ots;
  }

  getPerms: Function = async () => {

    return  await this.apiCall("perms")
 }
  resetPassword: Function = async () => {
   
   return await this.apiCall("resetPassword")
 }
  updateUser: Function = async(update:{[key:string]:any})=>{

     

      return await this.apiCall("user", update)


 }
  getHistory: Function = async () => {
    let x:any = await this.apiCall("history")
   
    return x
 }
  getUser: Function = async () => {
    
    return await this.apiCall("user")
 }

  useFocus:Function =() => {
    const domRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      domRef.current?.focus();
   },[domRef]);

    return {
      domRef,
    };
 }
  updateActivity: Function = async(x:any, noApi?:boolean)=>{
    let oldActivity = localStorage.getItem("activity")
    let newActivity 
    if(oldActivity){
      let parsed = JSON.parse(oldActivity)
      let checkExi = parsed.filter((y:any) => y.data.id === x.data.id)
      if(checkExi.length > 0){
        let others = parsed.filter((y:any) => y.data.id !== x.data.id)
         newActivity = [x,...others]
      }
      else{
        newActivity = [x,...parsed]
      }
    
    }
    else{
      newActivity = [x]
    }
    localStorage.setItem("activity", JSON.stringify(newActivity))
    if(noApi){
      return "got it"
    }
    else{
    return await this.apiCall("addActivity", x)
    }
    

 }

  updateUpcoming: Function = async(x:EventInterface, exi:EventInterface[] )=>{
    let res = await this.apiCall("addUpcoming", x)

    let ots: EventInterface[];


      let checkExi = exi.filter((y:EventInterface) => y.id === res.id)
      if(checkExi.length > 0){
        let others = exi.filter((y:EventInterface) => y.id !== res.id)
         ots = [x,...others]
      }
      else{
        ots = [x,...exi]
      }
    
 
    return ots 
 }

  addTest: Function = async(topic: string, course: string, date: any, type:string, id:string)=>{

    let ots = {topic: topic, course: course, date: date, type:type, id:id};

    return await this.apiCall("addTest", ots)

 }
  subscribe: Function = async()=>{

    return await this.apiCall("subscribe")
 }
  getSub: Function = async()=>{
    return await this.apiCall("getSub")
 }
  addClasses: Function = async(newClasses:ClassInterface, exi:ClassInterface[])=>{


     await this.apiCall("addClasses", newClasses)

     return [...exi, newClasses]
 }

 updateClass: Function = async (x: ClassInterface, exi: ClassInterface[]) => {
  await this.apiCall("addClass", x);

  let ots: ClassInterface[];

  let checkExi = exi.filter((y: ClassInterface) => y.id === x.id);
  if (checkExi.length > 0) {
    let others = exi.filter((y: ClassInterface) => y.id !== x.id);
    ots = [x, ...others];
  } else {
    ots = [x, ...exi];
  }

  return ots;
};
deleteClass: Function = async (x: ClassInterface, exi: ClassInterface[]) => {
  await this.apiCall("deleteClass", x);
  let ots: ClassInterface[] = exi.filter((y: ClassInterface) => y.id !== x.id);
  return ots;
  };

 
    calculateTimeLeft:Function =() => {
      const difference = +new Date(`2023/1/09 00:00:00`) - +new Date();
      let timeLeft: any = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      return timeLeft;
   }

   updateNote: Function = async(x:any)=>{


    let y =  await this.apiCall("updateNote",x);

    localStorage.setItem(`note.${x.id}`, JSON.stringify(x))
    return y
  }

   addNote: Function = async(x:any)=>{


    return await this.apiCall("addNote",x);
   }

   getNotes: Function = async(x:any)=>{


    return await this.apiCall("getNotes",x);
   }

   loadNote: Function = async(x:string)=>{

    return await this.apiCall("loadNote",{id:x});
   }

 
};



export { Generic };
