import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {useLocation, useNavigate} from "react-router"
import axios from "axios";
//Component Imports
import {
    Loading,
    Heading,
    Input,
    Button,
    ClearButton

} from "../";
import { Logic } from "../../functions";
import { Back } from "../../styled";
import theme from "../../theme";


const ClassPage = (props: { class: string, back: Function, next:Function, label:string }) => {


    const location = useLocation()
    const navigate = useNavigate()
  
    const errorHandler = async()=>{

        console.log("errorHandler")
      };
      const permsHandler = async()=>{
          navigate(location.pathname + "?sub")
      }
    
      const logic = new Logic(permsHandler, errorHandler, errorHandler)
    
    const [loading, setLoading] = useState<boolean>(true)

    const [topics, setTopics] = useState<string[]>()
    const [topic, setTopic] = useState<string>()
    const [ui, setUi] = useState<boolean>(true)


    const search = (x:string)=>{
        props.next(x)
    }

    useEffect(() => {
            
        const bootstrapAsync = async () => {

            let x = await logic.eloise.studyTopics(props.class, 16)

            if (x) {
                let parsed = Object.values(x)
                if(parsed){
                    localStorage.setItem(`${props.class}Topics`, JSON.stringify(parsed))
                    let y:string[] = [...parsed.map((x:any)=>x.toString())]
                    setTopics(y)
                   
    

                setLoading(false)
                }

            }
        


        }

        if(props.class == "Personal"){
            setLoading(false)
        }
        else{
        if(!topics){

            let test = localStorage.getItem(`${props.class}Topics`)
            if(test!== null){
                let parsed = JSON.parse(test)
                setTopics(parsed)
              

                setLoading(false)
            }
            else{


           if(location.search !== "?sub"){
          bootstrapAsync();
            }
    
            }
        }
        }

        



    }, [])




    return (<>

        <div className="container mx-auto ">
            {loading? <Loading />:
                <>
            <div className="row  ">

         <>
                
                <div className="row" >
                {topics && topics.map((topic:string) =>{
                    return <ClearButton onClick={()=>props.next(topic)}key={uuidv4()}transparent className="p-3" posClassName="col m-4 "  borderRadius  >{logic.generic.capitalize(topic)}</ClearButton>
                })}
                {/* <ClearButton  onClick={custom}borderColor secondary key={uuidv4()} className="p-3" border posClassName="col m-4 " fontSize="1.3rem" borderRadius  >New Custom Topic</ClearButton> */}
                </div>
              
                
            </>

            </div>
           
                </>
            }                


            </div>
            
     

    </>)

}


export { ClassPage }