import React from "react"

import Loading from "./loading"
const LoadingScreen  = ()=>{


    return(
    <div className="container-fluid p-0"> 
       <div className="mx-auto pt-5">
                <Loading />

       </div>
    </div>
    )
}



export {LoadingScreen}