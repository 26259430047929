import React from "react";


import {Heading }from ".."
const NotFound = ()=>{


    return(
        <div>
            <Heading size={3}>Hmm thats not right</Heading>
            <a href="/">Home</a>

        </div>
    )
}


export {NotFound}

