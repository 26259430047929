import React, { useState, useEffect, useContext } from "react";

import { v4 as uuidv4 } from "uuid";
import { mdiWeatherCloudy, mdiAccount, mdiSchool, mdiCogOutline, mdiPencil, mdiCards  } from "@mdi/js";
import Icon from "@mdi/react";
import { ThemeContext } from "../../App";
import { Heading } from "../";
import { HistoryItem } from "../../styled";
import { Logic } from "../../functions";
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import theme from "../../theme";

const Weather = (props: {handler:Function }) => {
  const navigate = useNavigate();
  const [history, setHistory] = useState<any[]>();
  const [refresh, setRefresh] = useState<boolean>();
  const themeC = useContext(ThemeContext).theme

  // Triggers useEffect on activity change
  useEffect(() => {
    window.addEventListener('activity', () => {
      setRefresh(!refresh);
    })
}, []);


useEffect(() => {
    let test = localStorage.getItem("AuthToken")
  
    if (test && !history) {
      let x = localStorage.getItem(`activity`);
      if (x !== null) {
        let parsed = JSON.parse(x);
        if (parsed !== history) setHistory(parsed);
        setHistory(parsed)
      }
    }
  
}, [refresh])


  const historyHandler = (x: any) => {
  
    navigate(`/${x.app}`, {state:{item: x}});
  
  };

  return (
    <div className="row  mx-4">
      <Heading
        style={{ textDecoration: "underline" }}
        size={4}
        handWritten
        white
      >
        Recent Activity
      </Heading>
      {history &&
        history.map((x: any) => {
          if (x.app === "learn") {
            return (
              <HistoryItem
                key={uuidv4()}
                className="my-3 row"
                onClick={() => {
                  historyHandler(x);
                }}
              >
              <Icon className="col"
                    path={mdiSchool}
                    style={{
                      borderRadius: "3px",
                      margin: "auto",
                    }}
                    title="write"
                    size={1}
                    color={themeC.mode =="chalk"? "white": "black"}
                  />

                <Heading style={{textDecoration: `underline solid 3px ${theme.primary} ` }} posClassName="col-lg-9" white size={1}>
                  {x.data.name}
                </Heading>
              </HistoryItem>
            );
          } else if (x.app === "write") {
            return (
              <HistoryItem
              key={uuidv4()}
              className="my-3 row"
                onClick={() => {
                  historyHandler(x);
                }}
              >

            <Icon className="col"
                    path={mdiPencil}
                    style={{
                      borderRadius: "3px",
                      margin: "auto",
                    }}
                    title="write"
                    size={1}
                    color={themeC.mode =="chalk"? "white": "black"}
                  />
                <Heading style={{textDecoration: `underline solid 3px ${theme.primary} ` }} white posClassName="col-lg-9" size={1}>
                {x.data.name}
                </Heading>
              </HistoryItem>
            );
          }
          else{
            return( <HistoryItem
            key={uuidv4()}
            className="my-3 row"
            onClick={() => {
              historyHandler(x);
            }}
          >
               <Icon className="col"
                    path={mdiCards}
                    style={{
                      borderRadius: "3px",
                      margin: "auto",
                    }}
                    title="write"
                    size={1}
                    color={themeC.mode =="chalk"? "white": "black"}
                  />
            <Heading style={{textDecoration: `underline solid 3px ${theme.primary} ` }} white posClassName="col-lg-9"size={1}>
                  {x.data.name}
            </Heading>
          </HistoryItem>


        )}
        })}
    </div>
  );
};

export default Weather;
