import React, { useEffect, useState, createContext, useContext } from "react";
import "./App.css";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeContext } from "./App";
import { doc, getDoc, getDocs } from "firebase/firestore";
import { pathMap, layOutSwitch } from "./functions/lists";
import { v4 as uuidv4 } from "uuid";

import { useIdToken } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { CSSTransition, TransitionGroup } from "react-transition-group";
//Import Modes
import {
  Admin,
  AdminSmall1,
  AdminSmall2,
  AdminSmall3,
  Learn,
  PP,
  Write,
  Game,
  NormalTour,

  WriteHistory,
  FlashOps,
  SignUp,
  Eloise,
  FrostBox, // Stuctural
  Weather,
  Loading,
  CountDown,
  Login,
  Apps, // Widgets
  Upcoming,
  LearnHistory,
  EventCatcher,
  Collab,
  Profile,
  ProfileSmall1,
  ProfileSmall2,
  ProfileSmall3,
  Flash,
  Switch,
  NotFound,
  Calander,
  Schedule,
  Sub,
  ClassMode,
  Policy,
  EventMode,
  Life,
  Todo,
  ClassSelect,
  LoginMode,
} from "./components";
import { Logic } from "./functions";

import { Icycle } from "./styled";

import {
  SignUpSmall1,
  SignUpSmall2,
  SignUpSmall3,
} from "./components/modes/signUpMode";
import { Doodle } from "./components/widgets/doodle";


export interface ActivityItem {
  app: string;
  mode?: string;
  id: string;
  created: Date;
  access: Date;
  data: any;
}


export interface Note {
  items: noteItem[];
  id: string;
}
export interface noteItem {
  type: string;
  data: any;
  id: string,
  large?: boolean;
}

export interface EventInterface {
  date: Date;
  noteId: string;
  noteLoaded?: Note;
  class: ClassInterface;
  id: string,
  name: string,
  topic: string,
  description: string,

}

export interface ClassInterface {
  id: string;
  name: string;

}


interface AppContextI {
  mode: string;
  activity: ActivityItem[];
  user: any;
  classes: ClassInterface[];
  events: EventInterface[];
  class?: ClassInterface;
  event?: EventInterface;
  setClass: Function;
  setMode: Function;
  setEvent: Function;
  setActivity: Function;
  setEvents: Function;
  setClasses: Function
}
const placeHolder = {
  place: true,
  event: {
    noteId: "asdfs",
    id: "default",
    date: new Date(),
    class: { id: "place", name: "place" },
    name: "personal",
    topic: "personal",
    description: "personal"
  },
  class: { id: "place", name: "place" },
  mode: "normal",
  classes: [{ id: "place", name: "place" }],
  activity: [
    {
      app: "default",
      mode: "default",
      id: "default",
      created: new Date(),
      access: new Date(),
      data: {}
    }
  ],
  events: [
    {
      noteId: "asdf",
      id: "default",
      date: new Date(),
      class: { id: "personal", name: "personal" },
      name: "personal",
      topic: "personal",
      description: "personal"

    }
  ],
  user: {},
  setClass: () => { },
  setEvent: () => { },
  setMode: () => { },
  setActivity: () => { },
  setEvents: () => { },
  setClasses: () => { }

}
const AppContext = createContext<AppContextI | undefined>(undefined);


function AppContent(props: { setTheme: Function }) {

  const errorHandler = async (e: any) => {

  }; const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const theme = useContext(ThemeContext)
  const themeC = theme.theme


  const [mode, setMode] = useState<string>("normal");
  const [event, setEvent] = useState<EventInterface>()
  const [SClass, setSClass] = useState<ClassInterface>()
  const [classes, setClasses] = useState<ClassInterface[]>()
  const [activity, setActivity] = useState<ActivityItem[]>()
  const [events, setEvents] = useState<EventInterface[]>()
  const [userData, setUserData] = useState<any>()
  const [user, loading, error] = useIdToken(logic.fb.auth)


  const [contextData, setContextData] = useState<AppContextI | undefined>()

  const modeToggle = () => {
    if (themeC.mode === "white") {
      theme.changeMode("chalk")
    }
    else {
      theme.changeMode("white")
    }

  }

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const [sideWidget, setSideWidget] = useState(<Eloise />)

  // Learn States

  const [learnItem, setLearnItem] = useState();
  const [learnMode, setLearnMode] = useState<string>("learn");

  // Flash States
  const [flashMode, setFlashMode] = useState<string>("normal");
  const [flashItem, setFlashItem] = useState<any>();



  // Write States
  const [writeMode, setWriteMode] = useState<string>("normal");
  const [writeItem, setWriteItem] = useState();

  //Profile States
  const [profileMode, setProfileMode] = useState<string>("account");


  //Class States
  let newClass;
  let test = localStorage.getItem("classPageClass")
  if (test) {
    let parsed = JSON.parse(test)
    if (parsed) {

      newClass = parsed

    }
    else {
      newClass = { name: "Personal" }
    }
  }
  else {
    newClass = { name: "Personal" }
  }
  const [classItem, setClassItem] = useState<any>(newClass);
  const [classEvent, setClassEvent] = useState<any>()

  // Eloise Stuff

  const [eloisePrompt, setEloisePrompt] = useState<string>()



  useEffect(() => {
    if (!user) {
      return;
    }
    const bootstrapAsync = async () => {

      let x = await logic.generic.getHistory();

      setClasses(x.classes);
      setActivity(x.activity);
      setEvents(x.upcoming);
      setUserData(x.user)
    }

    const bootstrapFromLocalStorage = () => {
      let testPrev = localStorage.getItem('appContext');
      if (testPrev) {
        let parsed = JSON.parse(testPrev);
        if (parsed.classes[0].id !== "personal") {
          setClasses(parsed.classes);
          setActivity(parsed.activity);
          setEvents(parsed.events);
          setUserData(parsed.user)
        } else {
          bootstrapAsync();
        }
      } else {
        bootstrapAsync();
      }
    };

    bootstrapFromLocalStorage();
  }, [user]);



  useEffect(() => {
    let ots = {
      place: false,
      mode: mode ?? "default",
      classes: classes ?? placeHolder.classes,
      event: event,
      class: SClass ?? placeHolder.class,
      events: events ?? placeHolder.events,
      activity: activity ?? placeHolder.activity,
      user: userData,
      setClass: setSClass,
      setMode: setMode,
      setEvent: setEvent,
      setEvents: setEvents,
      setActivity: setActivity,
      setClasses: setClasses

    }

    if (classes) {
      setContextData(ots)
      localStorage.setItem("appContext", JSON.stringify(ots))

    }


  }, [activity, events, classes, mode, event, SClass])



  useEffect(() => {
    let width = window.innerWidth

    if (location.search) {
      sideComp(location.search)
    }


    let newPath =   layOutSwitch(location, width > 960 ? "desktop" : "mobile")

    setToUse(newPath);



  }, [location, displayLocation]);


  const toggleEl = () => {
    setToUse({ ...toUse, icycle1: !toUse.icycle1 })
  }
  let newPath = pathMap[location.pathname] ? pathMap[location.pathname] : pathMap["/404"]

  const [toUse, setToUse] = useState<{ [key: string]: any }>(newPath);






  const sideComp = (x: string) => {

    switch (x) {

      case "?cal":
        setSideWidget(<Upcoming />)
        break;
      case "?event":
        setSideWidget(<EventCatcher course={location.state.course} next={location.pathname} type={location.state.type} />)
        break;
      case "?sub":
        setSideWidget(<Sub />)
        break;
      default:
        if (location.state) {
          setSideWidget(<Eloise special={location.state.special} done={location.pathname} prompt={location.state.prompt} />)
        }
        else {
          setSideWidget(<Eloise />)

        }
        break;

    }

  }

  const historyHandler = (x: any) => {

    switch (x.app) {
      case "flash":

        setFlashItem(x)
        setMode("load")
        break;
      case "write":
        localStorage.setItem("currentCollabWriting", x.data.answer)
        localStorage.setItem("writeItem", x.data.display)
        setWriteItem(x)
        setWriteMode("history")
        break;

      case "learn":
        setLearnMode("history");
        setLearnItem(x);
        break;

    }
  };

  const login = (email: string, password: string) => {
    signInWithEmailAndPassword(logic.fb.auth, email, password);
  };

  if (location.pathname == "/tour") {
    return <NormalTour setEloise={setEloisePrompt} />
  }
  else {
    return (
      <AppContext.Provider value={contextData}>

        <div className="  row   g-0  flex-lg-nowrap" style={{
          maxHeight: "100vh"
        }}>

          {toUse.icycle1 &&
          <Icycle
            xs={{ span: 12, order: 2 }}

            lg={{ span: ((user !== undefined && user!== null )|| location.pathname === "/signup") ?  toUse.icycle1Width:12 , order: 1 }}
            md="12"
            sm="12"
          >
            {((user !== undefined && user!== null )|| location.pathname === "/signup") && <>

              {toUse.frost1 && (
                <>
                  <FrostBox
                    noTop
                    noRight
                    noLeft
                    noBottom
                    gridId={uuidv4()}
                    key={uuidv4()}
                    noControls
                    minHeight={toUse.frost1Height}
                    mode={themeC.mode}
                  >
                    {" "}
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="fade"
                        timeout={800}
                      >
                        <div
                          className={`${transitionStage}`}
                          onAnimationEnd={() => {
                            if (transitionStage === "fadeOut") {
                              setTransistionStage("fadeIn");
                              setDisplayLocation(location);
                            }
                          }}
                        >
                          <Routes>
                            <Route path="/signup" element={<SignUpSmall1 />} />
                            <Route
                              path="/write"
                              element={
                                <WriteHistory
                                  historyHandler={historyHandler}
                                ></WriteHistory>
                              }
                            />
                            <Route
                              path="/flash"
                              element={
                                <LearnHistory
                                  topic={flashItem}
                                  historyHandler={historyHandler}
                                  mode={"flash"}
                                />
                              }
                            />
                            <Route
                              path="/learn"
                              element={
                                <LearnHistory
                                  historyHandler={historyHandler}
                                  mode={"learn"}
                                  topic={learnItem}
                                />
                              }
                            />
                            <Route path="*" element={<></>} />
                            <Route path="/calendar" element={<></>} />

                            <Route path="/present" element={<PP />} />
                            <Route path="/profile" element={<ProfileSmall1 setMode={setProfileMode} />} />
                            <Route path="/game" element={<Game />} />
                            <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                            <Route path="/" element={<Upcoming /> } />
                            <Route path="/collab" element={<></>} />
                            <Route path="/admin" element={<AdminSmall1 />} />
                            <Route path="/schedule" element={<></>} />
                            <Route path="/class" element={<></>} />
                            <Route path="/event" element={<></>} />
                            <Route path="/policy" element={<></>} />

                          </Routes>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </FrostBox>
                </>
              )}
              {toUse.frost2 && (
                <>
                  <FrostBox
                    noRight
                    noLeft
                    noBottom={!toUse.frost3}
                    gridId={uuidv4()}
                    key={uuidv4()}
                    noControls
                    minHeight={toUse.frost2Height}
                    mode={themeC.mode}

                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="fade"
                        timeout={800}
                      >
                        <div
                          className={`${transitionStage}`}
                          onAnimationEnd={() => {
                            if (transitionStage === "fadeOut") {
                              setTransistionStage("fadeIn");
                              setDisplayLocation(location);
                            }
                          }}
                        >
                          <Routes>
                            <Route path="/signup" element={<></>} />
                            <Route
                              path="/write"
                              element={
                                <></>
                              }
                            />
                            <Route
                              path="/flash"
                              element={
                                <FlashOps foL="flash" mode={setFlashMode}></FlashOps>
                              }
                            />
                            <Route
                              path="/learn"
                              element={
                                <FlashOps foL="learn" mode={setLearnMode}></FlashOps>
                              }
                            />
                            <Route path="/calendar" element={<></>} />

                            <Route path="/present" element={<PP />} />
                            <Route path="/profile" element={<ProfileSmall2 setMode={setProfileMode} />} />
                            <Route path="/game" element={<Game />} />
                            <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                            <Route path="/" element={<Apps />} />
                            <Route path="/collab" element={<></>} />
                            <Route path="/admin" element={<AdminSmall2 />} />
                            <Route path="/schedule" element={<></>} />
                            <Route path="/class" element={<></>} />
                            <Route path="/event" element={<></>} />
                            <Route path="/policy" element={<></>} />

                            <Route element={<></>} />


                          </Routes>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </FrostBox>
                </>
              )}
            </>}
            {toUse.frost3 && (
              <>
            {((user !== undefined && user!== null )|| location.pathname === "/signup") ?
                
                    <FrostBox
                      noTop
                      noLeft
                      noRight
                      noBottom
                      gridId={uuidv4()}
                      key={uuidv4()}
                      noControls
                      minHeight={toUse.frost3Height}
                    >
                      <TransitionGroup>
                        <CSSTransition
                          key={location.pathname}
                          classNames="fade"
                          timeout={800}
                        >
                          <div
                            className={`${transitionStage}`}
                            onAnimationEnd={() => {
                              if (transitionStage === "fadeOut") {
                                setTransistionStage("fadeIn");
                                setDisplayLocation(location);
                              }
                            }}
                          >
                            <Routes>
                              <Route path="/signup" element={<></>} />
                              <Route path="/write" element={<Write mode={writeMode} item={writeItem} />} />
                              <Route
                                path="/flash"
                                element={
                                  <LearnHistory
                                    historyHandler={historyHandler}
                                    mode={"flash"}
                                  />
                                }
                              />
                              <Route
                                path="/learn"
                                element={
                                  <></>
                                }
                              />
                              <Route path="*" element={<></>} />
                              <Route path="/calendar" element={<></>} />

                              <Route path="/present" element={<PP />} />
                              <Route path="/profile" element={<ProfileSmall3 setMode={setProfileMode} />} />
                              <Route path="/game" element={<Game />} />
                              <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                              <Route path="/" element={<Doodle />} />
                              <Route path="/collab" element={<></>} />
                              <Route path="/admin" element={<AdminSmall3 />} />
                              <Route path="/schedule" element={<></>} />
                              <Route path="/class" element={<></>} />
                              <Route path="/event" element={<></>} />
                              <Route path="/policy" element={<></>} />

                            </Routes>
                          </div>
                        </CSSTransition>
                      </TransitionGroup>
                    </FrostBox>
                    :
                    <LoginMode size={ window.innerWidth
                      > 960 ? "desktop" : "mobile"} next={login}  />
                }
                  </>
          )}
              </Icycle>
  }
              {((user !== undefined && user!== null )|| location.pathname === "/signup") && <>
              {toUse.icycle2 &&

              <Icycle
                xs={{ span: 12, order: 1 }}
                lg={{ span: toUse.icycle2Width, order: 2 }}
                md="12"
                sm="12"
              >
                <FrostBox
                  noTop
                  home={location.pathname !== "/"}
                  noLeft={!toUse.icycle1}
                  mode={themeC.mode}
                  main
                  noRight={!toUse.icycle3}
                  noBottom
                  gridId={uuidv4()}
                  key={uuidv4()}
                  noControls
                  minHeight={100}
                >
                  <TransitionGroup>
                    <CSSTransition
                      key={location.pathname}
                      classNames="fade"
                      timeout={800}
                    >
                      <div
                        className={`${transitionStage}`}
                        onAnimationEnd={() => {
                          if (transitionStage === "fadeOut") {
                            setTransistionStage("fadeIn");
                            setDisplayLocation(location);
                          }
                        }}
                      >
                        <Routes>
                          <Route path="/signup" element={<SignUp login={login}  />} />
                          <Route path="/write" element={<Write />} />
                          <Route
                            path="/flash"
                            element={
                              <Flash save={() => { }} deck={flashItem} mode={flashMode} back={setFlashMode} />
                            }
                          />
                          <Route
                            path="/learn"
                            element={
                              <Learn
                                history={historyHandler}
                                toggleEl={toggleEl}
                                mode={learnMode}
                                item={learnItem}
                                setMode={setLearnMode}
                              />
                            }
                          />
                          <Route path="*" element={<NotFound />} />

                          <Route path="/present" element={<PP />} />
                          <Route path="/profile" element={<Profile mode={profileMode} />} />
                          <Route path="/game" element={<Game />} />
                          <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                          <Route path="/" element={<Eloise prompt={eloisePrompt} />} />
                          <Route path="/collab" element={<Collab back={() => { }} />} />
                          <Route path="/admin" element={<Admin />} />
                          <Route path="/calendar" element={<Calander classEvent={setClassEvent} />} />
                          <Route path="/schedule" element={<Schedule />} />
                          <Route path="/class" element={<ClassMode class={classItem} event={classEvent} learnMode={setLearnMode} learnItem={setLearnItem} flashMode={setFlashMode} flashDeck={setFlashItem} />} />
                          <Route path="/event" element={<EventMode />} />
                          <Route path="/policy" element={<Policy />} />

                        </Routes>
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                </FrostBox>
              </Icycle>
  }          
  {toUse.icycle3 &&

              <Icycle
                xs={{ span: 12, order: 3 }}
                lg={{ span: toUse.icycle3Width, order: 3 }}
                style={!toUse.icycle3 ? { width: "0", display: "none" } : {}}

                md="12"
                sm="12"
              >
                {toUse.frost4 && (

                  <FrostBox
                    noTop
                    noRight
                    noLeft
                    noBottom
                    gridId={uuidv4()}
                    key={uuidv4()}
                    noControls
                    minHeight={toUse.frost4Height}
                    mode={themeC.mode}

                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="fade"
                        timeout={800}
                      >
                        <div
                          className={`${transitionStage}`}
                          onAnimationEnd={() => {
                            if (transitionStage === "fadeOut") {
                              setTransistionStage("fadeIn");
                              setDisplayLocation(location);
                            }
                          }}
                        >
                          <Routes>
                            <Route path="*" element={<></>} />

                            <Route path="/signup" element={<SignUpSmall1  />} />
                            <Route path="/write" element={sideWidget} />
                            <Route path="/flash" element={sideWidget} />
                            <Route path="/learn" element={sideWidget} />
                            <Route path="/present" element={sideWidget} />
                            <Route path="/profile" element={sideWidget} />
                            <Route path="/game" element={sideWidget} />
                            <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                            <Route path="/" element={<Weather handler={historyHandler} />} />
                            <Route path="/collab" element={sideWidget} />
                            <Route path="/admin" element={sideWidget} />
                            <Route path="/calendar" element={sideWidget} />
                            <Route path="/schedule" element={sideWidget} />
                            <Route path="/class" element={sideWidget} />
                            <Route path="/event" element={sideWidget} />
                            <Route path="/policy" element={sideWidget} />

                          </Routes>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </FrostBox>)}

                {toUse.frost5 && (

                  <FrostBox
                    noTop={!toUse.frost4}
                    noRight
                    noLeft
                    gridId={uuidv4()}
                    key={uuidv4()}
                    noControls
                    minHeight={toUse.frost5Height}
                    mode={themeC.mode}

                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="fade"
                        timeout={800}
                      >
                        <div
                          className={`${transitionStage}`}
                          onAnimationEnd={() => {
                            if (transitionStage === "fadeOut") {
                              setTransistionStage("fadeIn");
                              setDisplayLocation(location);
                            }
                          }}
                        >
                          <Routes>
                            <Route path="*" element={<></>} />

                            <Route path="/signup" element={<></>} />
                            <Route path="/write" element={<Write />} />
                            <Route path="/flash" element={<></>} />
                            <Route path="/learn" element={<></>} />
                            <Route path="/present" element={<PP />} />
                            <Route path="/profile" element={<></>} />
                            <Route path="/game" element={<Game />} />
                            <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                            <Route path="/" element={<Switch isOn={themeC.mode == "white"} handleToggle={modeToggle} />} />
                            <Route path="/collab" element={<></>} />
                            <Route path="/admin" element={<></>} />
                            <Route path="/calendar" element={<></>} />
                            <Route path="/schedule" element={<></>} />
                            <Route path="/class" element={<></>} />
                            <Route path="/event" element={<></>} />
                            <Route path="/policy" element={<></>} />
                          </Routes>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </FrostBox>)}
                {toUse.frost6 && (

                  <FrostBox
                    noTop
                    noRight
                    noLeft
                    noBottom
                    gridId={uuidv4()}
                    key={uuidv4()}
                    noControls
                    minHeight={toUse.frost6Height}
                    mode={themeC.mode}

                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="fade"
                        timeout={800}
                      >
                        <div
                          className={`${transitionStage}`}
                          onAnimationEnd={() => {
                            if (transitionStage === "fadeOut") {
                              setTransistionStage("fadeIn");
                              setDisplayLocation(location);
                            }
                          }}
                        >
                          <Routes>
                            <Route path="*" element={<></>} />

                            <Route path="/signup" element={<></>} />
                            <Route path="/write" element={<Write />} />
                            <Route path="/flash" element={<></>} />
                            <Route path="/learn" element={<></>} />
                            <Route path="/present" element={<PP />} />
                            <Route path="/profile" element={<></>} />
                            <Route path="/game" element={<Game />} />
                            <Route path="/tour" element={<NormalTour setEloise={setEloisePrompt} />} />
                            <Route path="/" element={<ClassSelect spacing="my-3 col-lg-9 mx-auto" nav next={() => { }} />} />
                            <Route path="/collab" element={<></>} />
                            <Route path="/admin" element={<></>} />
                            <Route path="/calendar" element={<></>} />
                            <Route path="/schedule" element={<></>} />
                            <Route path="/class" element={<></>} />
                            <Route path="/event" element={<></>} />
                            <Route path="/policy" element={<></>} />
                          </Routes>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </FrostBox>)}


              </Icycle>
  }
            </>
            }
        </div>

      </AppContext.Provider>
    );
  }
}

export { AppContent, AppContext };
