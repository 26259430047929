import styled, { keyframes } from "styled-components"


import { Col, Row , Dropdown, DropdownButton} from "react-bootstrap"
import { fadeIn } from "react-animations"
import { AppIcon, Heading } from "./components"
import { mdiArrowLeftThick, mdiHome, mdiChat } from '@mdi/js';

import theme from "./theme"
import { interaction } from "./functions/eloise";
const fadeInAni = keyframes`${fadeIn}`



const Icycle = styled(Col)`

`

const ClearDropDown = styled(DropdownButton)`

    &: < button{
            background-color:transparent !important;

    }


`

const BackArrow = styled.div`

position:fixed; 
top: 10px;
left: 10px;
opacity: .4;
width: 100px;
z-index:10;
&:hover {
    transition: all ease .8s
    transform: translateY(-3px);
    opacity: .95;

}




`
const HomeArrow = styled.div`

position:fixed; 
top: 10px;
right: -50px;
opacity: .4;
width: 100px;
z-index: 10;
&:hover {
    transition: all ease .8s
    transform: translateY(-3px);
    opacity: .95;

}
`

const ChatArrow = styled.div`

position:fixed; 
bottom: 10px;
right: -50px;
opacity: .4;
width: 100px;
z-index: 10;
&:hover {
    transition: all ease .8s
    transform: translateY(-3px);
    opacity: .95;

}


`
const Back = (props: { onClick: Function, icon?: any }) => {
    return (
        <BackArrow>
            <AppIcon
                title="back"
                icon={props.icon || mdiArrowLeftThick}
                onClick={() => {
                    props.onClick()
                }}
            ></AppIcon>
        </BackArrow>
    )
}

const Home = (props:{onClick:Function})=>{

    return(
        <HomeArrow>
            <AppIcon
                title="back"
                icon={mdiHome}
                onClick={() => {props.onClick()
                  
                }}
            
              ></AppIcon>
        
        </HomeArrow>
    )
}

const ChatOp = (props:{onClick:Function})=>{

    return(
        <ChatArrow>
            <AppIcon
                title="back"
                icon={mdiChat}
                onClick={() => {props.onClick()
                  
                }}
            
              ></AppIcon>
        
        </ChatArrow>
    )
}
const FadeInDiv = styled.div`

  animation: ${fadeInAni} 2.5s;`
interface FrostedProps{
    $height: number;
    $transparent: boolean;
    $notouch: boolean;
    $noright:boolean;
    $noleft:boolean;
    $notop:boolean;
    $nobottom:boolean;
    $main:boolean;
    $mode:string;
}    


interface OutlineI {
    $mode:string,
    $noright?:boolean,
    $noleft?:boolean,
    $notop?:boolean,
    $nobottom?:boolean
}


const OutlinedDiv = styled.div<OutlineI>`

border-bottom:${(props) => props.$nobottom? "none" : props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
border-right: ${(props) => props.$noright? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
border-top: ${(props) => props.$notop? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
border-left: ${(props) => props.$noleft? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
border-image:${(props) => props.$mode=="chalk"? `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20` : "none"} ;


@media (max-width: 768px) {

    border-left:none;
    border-right:none;
}
 
`
const Frosted = styled(Col)<FrostedProps>`
    position: relative;
    transition: opacity ease 2s;
    background-color: rgba(255, 255, 255, 0);  
    backdrop-filter: blur(5px);
    height: ${(props) => props.$height}vh;
    overflow-Y: auto;
    overflow-X: hidden;
    border-bottom:${(props) => props.$nobottom? "none" : props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
    border-right: ${(props) => props.$noright? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
    border-top: ${(props) => props.$notop? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
    border-left: ${(props) => props.$noleft? "none" :props.$mode==="chalk"? "15px solid rgba(255,255,255,.8)" : "8px solid black"};
    border-image:${(props) => props.$mode=="chalk"? `url("https://www.unicefusa.org/sites/default/files/answer-box.png") 20` : "none"} ;
    max-height: 100vh;

    &: before{
        content: '';

    }
    opacity:${(props) => props.$transparent? 0 :1};
    
    transition: height 0.5s ease;

    pointer-events:${(props) => props.$noTouch? "none" :"auto"};
    
    @media (max-width: 768px) {
        min-height: ${(props) => props.$height}vh;
        height: ${(props) => props.$main? "100vh" :"auto"};
        border-left:none;
        border-right:none;
        overflow-y:show;
        > div{
            padding: 1rem;
        }
    margin-bottom: 2vh;

      
        --bs-gutter-x : 0 !important;
      }

      @media (max-width: 1000px) {
       
    margin-bottom: 2vh;

      
      }

  
   
`
const Time = styled(Row)`
text-align: center;
font-size: 1.6rem;
font-weight: bold;
padding: 5px;
color: white;
max-width: 90%;
margin: auto;

border-radius: 5px;
height:70%;

`


const Controls = styled.div`
width: 50px;
height: 30px;
position: fixed;
top: 0;
right: 10px; 
padding:0;
text-align: center;

@media (max-width: 768px) {
    display:none !important;
}

`


interface ControlButtonProps{
    children: any | any[]
}
const ControlButton = styled.div<ControlButtonProps>`


color: white;
font-size: 2.3rem;
padding: 0 4px;
display: inline-block;
opacity: .4;
&:hover {
    transition: all ease .8s
    transform: translateY(-3px);
    opacity: .95;
}
`


const StudyOpDiv = styled.button`
border:none;
border-radius:10px;
background-color: rgba(255, 255, 255, 0);
&:hover {
    transition: transform ease .5s;
    transform: translateY(-3px);
    background-color: rgba(255, 255, 255, .15);

}

`

const HistoryItem = styled.div<{$zoom?: number | string}>`



&:hover{

    cursor:pointer;
    transition: transform ease .76s;

    
    transform: scale(1.${(props:any)=>{return props.$zoom? props.$zoom : "4"}});

}



`

const WriteHistoryItem = styled.div`

border-radius:10px;
min-height: 20vh;
min-width: 15vh;
max-width: 16vh;
&:hover{
    transition: transform ease .76s;
    transform: scale(1.1)
}



`
const MenuItem = styled.div`

&:hover{
    transition: transform ease .76s;
    transform: scale(1.1)
}
border-bottom: 2px solid white;
margin-bottom: 5px;



`
const GameBackground = styled.div`
padding-top: 10vh;
background-color: black;
font-family: terminal;
height: 100vh;
width: 100vw;


`

const FlashCardDiv = styled.div`

    min-height: 25vh;
    min-width: 300px;
    max-width: 300px;
    padding: 10px;
    margin: 10px;
    border-radius:5px;
    border: 3px solid ${theme.primary} ;



`

const LearnCardDiv = styled.div`

    border-color: rgba(194, 173, 224, .7);
    min-height: 40vh;
    min-width: 300px;
    padding: 40px;
    margin: 10px;
    border-radius:5px;
    border: 1px solid ${theme.primary} ;



`


const WriteMenu = styled.div`

position: fixed;
background-color: ${theme.grey};
border-radius: 5px;
outline: 3px solid white;
padding: 10px;
`


const DoodleDiv = styled.div`
width:100%;

&: < img{
    border:none;
}


`

const OverviewSlide = styled.div`

min-height: 50vh;
min-width: 300px;
padding: 40px;
margin: 10px;
border-radius:5px;
border: 4px solid ${theme.primary} ;



`


const SlideControl = styled.div`


width: 50px;
height: 30px;

padding:0;
text-align: center;

opacity: 1;
width: 100px;
&:hover {
    transition: all ease .8s
    transform: translateY(-3px);
    opacity: .95;

}


`


const ChatMessage = (props:{message:interaction})=>{


    return(
    <div className="container">

        {props.message.human && <div className="row"  >
                  <Heading border align="right" posClassName="col-9 py-1"  className="p-2" size={1} >{props.message.human}</Heading>

                    <Heading
                        // parentStyle={{ left: "0" }}
                        border
                        style={{padding:".1rem .4rem", borderRadius: "40px"}}
                        posClassName="col-2 pt-2"
                        size={1} white>
                        :)
                      </Heading>      


        </div>}
        {props.message.eloise &&
        <div className="row" >
                     <Heading
                        // parentStyle={{ left: "0" }}
                        border
                        style={{padding:".1rem .4rem", borderRadius: "40px"}}
                        posClassName="col-2 pt-2"
                        size={1} white>
                        (:
                      </Heading>
                <Heading border align="left" size={1} posClassName="col-9 py-1"  className="p-2" >{props.message.eloise.replace(/"/g, '')}</Heading>

        </div>}
    </div>
    )
}



const ChatDiv = styled.div`
overflow-x: hidden;
overflow-y:auto;
height: 70vh;
max-height: 70vh;
padding: 0 40px;

`

const CalCont = styled.div`
width:100%;
height: 30vh;
max-height:30vh;
overflow:auto;
padding-left:  40px;

`

const CalDay = styled.button`
min-width: 13%;
max-width: 13%;
min-height: 35px;
margin: 0 1px;
border-radius: 30px;
background: none;
border:none;

&:hover {
background-color:${theme.primary};
color:white;
}

&:focus {
    background-color:${theme.primary};
    border:none;
    color:white;

    }
    &:focus-visible {
        background-color:${theme.primary};
        border:none;
        color:white;

        }
&:active {
    background-color:${theme.primary};
    border:none;
    color:white;

}


`

const LifeDiv = styled.div`

background-image:    linear-gradient(#333 1px, transparent 1px),    linear-gradient(90deg, #333 1px, transparent 1px);

`


export {LifeDiv,ClearDropDown, OutlinedDiv, ChatOp, CalDay, CalCont, ChatDiv, ChatMessage, Home,WriteHistoryItem, SlideControl, OverviewSlide, MenuItem, DoodleDiv, Frosted, Icycle, Time, Controls, ControlButton, StudyOpDiv, HistoryItem, GameBackground, FadeInDiv, FlashCardDiv, LearnCardDiv, Back, WriteMenu} 