import React, {useState, useEffect, useContext} from "react";

import { v4 as uuidv4 } from "uuid";
import { Logic } from "../../functions";
import {AddEvent, AppIcon, Button, Cal, ClassSelect, ClearButton, Heading, Input, LoadingScreen} from ".."
import { Back, ChatMessage, HistoryItem, OutlinedDiv } from "../../styled";
import  {ThemeContext} from "../../App"
import { mdiCards, mdiFileQuestion, mdiSchool ,mdiPlus } from "@mdi/js";
import { Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { AppContext, EventInterface } from "../../AppContent";

const getOrdinalNum = (number: number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

const VocabItem = (props:{item:any , master:Function})=>{
    const [term, setTerm] = useState<string>(props.item.word)
    const [def, setDef] = useState<string>(props.item.def)
  
    const [edit, setEdit] = useState<Boolean>(false)

    const inputHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: Function
      ) => {
        const enteredName = event.target.value;
        setter(enteredName);
      };

      const save = ()=>{

        
        setEdit(false)
        props.master(term, def)
      }


  
  return(
  
  <div >
    {edit? 
    <>
      <div>
                                
                                <Input 
                                onChange={(e: any) => {
                                    inputHandler(e, setTerm);
                                }}
                                value={term}
                                label="Term"
                                posClassName="mx-auto col-lg-9 py-1"
                                className="text-center"
                                />

                                {term &&    <Input 
                                onChange={(e: any) => {
                                    inputHandler(e, setDef);
                                }}
                                value={def}

                                label="Def"
                                posClassName="mx-auto col-lg-9 py-1"
                                className="text-center"
                                />}
                        
                            
                        {def && <Button onClick={save} borderRadius posClassName="col-lg-3 mx-auto" >Save</Button>     }                   

                            </div>
    </> 
    :
    <HistoryItem  $zoom={1} onClick={()=>setEdit(true)}>
     <Heading  align="left" size={2}>{">" + props.item.word}</Heading>
    <Heading size={1} align="left" style={{paddingLeft: "20px"}}>{props.item.def}</Heading>
    </HistoryItem>
    
    }
   
    
  
    
    
 </div>
    )


}
const ClassMode = (props:{class:any, flashMode:Function, flashDeck:Function, learnMode:Function, learnItem:Function, event?: any , tour?:boolean})=>{


    const location = useLocation()
    const appContext = useContext(AppContext);
    const navigate = useNavigate()
    const themeC = useContext(ThemeContext).theme
    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
   
    const [type, setType] = useState<string>()
 
    const [add, setAdd] = useState<boolean>()
    const [notesChanged, setNotesChanged] = useState<boolean>(false)
    const [selected, setSelected] = useState<any>()
   
    const [notes, setNotes] = useState<string[]>()

    const [notesMode, setNotesMode] = useState<boolean>(false)
    const [masterNotes, setMasterNotes] = useState<any>()

    const [noteLength, setNoteLength] = useState<number>(0)
    
    const [vocab, setVocab] =useState<{word:string, def:string, id:string}[]>()
    const [newTerm, setNewTerm] = useState<boolean>(false)
    const [term, setTerm] = useState<string>()
    const [def, setDef] = useState<string>()
    const [defining, setDefining]= useState<boolean>(false)

    const [selectedNote, setSelectedNote] = useState<any>()

        const [noteText, setNoteText] = useState<string>("")

    const save = async ()=>{
            setAdd(false)
    

    }

    



    

    const clicker = (x:any)=>{
        setNoteText(x.text)
        setSelectedNote(x)
        setNoteLength(x.text.split(" ").length)
        if(x.terms){
            setVocab(x.terms)
        }
        setNotesMode(true)
    }

    const handleChange = (event: any) => {
        setNotesChanged(true)
        setNoteText(event.target.value);
    };

    
    const inputHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: Function
      ) => {
        const enteredName = event.target.value;
        setter(enteredName);
      };


   const  newHandler = ()=>{
    setAdd(true)


}

const gatherTerms = async()=>{
    let terms = masterNotes.filter((y:any)=> y.topic == selected.data.topic).map((x:any)=> x.terms)
    terms = terms.flat().map((x:any)=>{let ots:{[key:string]:string} = {}; ots[x.word]= x.def; return ots})


  
      let newId = uuidv4()

    let ots = {
        deck: terms,
        class:props.class.name,
        topic: selected.data.topic, 
        data:{
                    name:selected.data.topic,
                    id:newId
        },
        id:newId
    }

    let y = await logic.eloise.manualFlash(ots)
    await logic.generic.updateActivity({app:"flash", data:{name:selected.data.topic, id:newId}, loaded:terms}, true)


    props.flashDeck(ots)
    props.flashMode("load")
    navigate("/flash")
  
}


const saveNotes = async()=>{

    let old = masterNotes.filter((x:any)=> x.id !== selectedNote.id)
    setMasterNotes([...old, {...selectedNote, text: noteText, terms: vocab}])
    localStorage.setItem(`${props.class.name}-notes`,JSON.stringify([...old, {...selectedNote, text: noteText, terms: vocab}] ))
    let x = await logic.generic.updateNote({...selectedNote, text: noteText, terms: vocab})
    setNotesChanged(false)
}
const newNote = ()=>{
    setNoteText(" ")
    setVocab(undefined);

    let ots = {
        topic: selected.data.topic,
        class: props.class.name,
        title: selected.data.topic,
        text:" ",
        terms: [],
        id: uuidv4()
    }
    setMasterNotes([...masterNotes, ots])
    setSelectedNote(ots)
    setNotesMode(true)
}

const newTermHandler = async()=>{
    setNotesChanged(true)
    if(term){
        if(!def){
            let x = await define(term)
        }
        else{
            if(vocab){
            setVocab([...vocab, {word: term, def: def, id: uuidv4()}])
            }else{
                setVocab([{word: term, def: def, id: uuidv4()}])

            }
            setNewTerm(false)
            setTerm(undefined)
            setDef(undefined)
        }
    }
    else{
          setNewTerm(true)
  
    }


}

const define = async(x:string)=>{
    let def:any = await logic.eloise.getDef(x)

    let test:any = Object.values(def)[0]

    if(vocab){
        setVocab([...vocab, {word: x, def: test, id:uuidv4()}])
        }else{
            setVocab([{word:x, def: test, id: uuidv4()}])
         
        }
   setNewTerm(false)
    setTerm(undefined)
    setDef(undefined)
}

const handleVocabEdit = (x:string, y:string, id:string)=>{

    if(vocab){

    let others = vocab.filter((x:any)=> x.id !== id)
    
    setVocab([...others, {word: x, def: y, id: id}])


    }

}

const switchEvent = (x:any)=>{
   
        navigate("/event", { state: { event: x } });
      
    


}


const overview = ()=>{

    let ots = {
        topic: selected.data.topic,
        course: props.class.name,
        data:{
            name: selected.data.topic
        }
    }
    props.learnItem(ots)
    props.learnMode("load")
    navigate("/learn")

}

const quiz = ()=>{

    let ots = {
        topic: selected.data.topic,
        course: props.class.name,
        data:{
            topic: selected.data.topic,
            class: props.class.name,
            name: selected.data.topic
        }
    }
    props.learnItem(ots)
    props.learnMode("quiz")
    navigate("/learn")

}

if(appContext || location.pathname ==="/signup"){
    return(
        <>
         {add? 
                             <AddEvent class={location.pathname==="/signup"? props.class.name : location.state.class} done={save} type={type} cancel={() => setAdd(false)} />

        :
    <div className=" " 
    style={{
        pointerEvents: props.tour? "none" : "auto"
    }}>  
    {notesMode && <Back onClick={()=>setNotesMode(false)} />}

        {/* <OutlinedDiv $mode={themeC.mode} $notop $noleft $noright >
            <Heading size={5} handWritten >{location.state.class.name}</Heading>
         </OutlinedDiv> */}

        <div className="row   g-0  flex-lg-nowrap p-0 m-0">

            {!notesMode && 
        <OutlinedDiv $mode={themeC.mode} $notop $nobottom $noleft  className="col-lg-3 pt-5" style={{minHeight: "100vh"}}>

         
                <Heading size={3} style={{textDecoration: "underline"}}>Coming Up</Heading>  
                {appContext? 
                  <>  
                {appContext.events.filter((x:EventInterface)=> x.class.name == location.state.class.name).map((x:any)=>
                    <HistoryItem key={uuidv4()} className="py-2" onClick={()=>switchEvent(x)}>
                         <Heading  white style={{textDecoration: `underline solid 3px ${themeC.primary} ` }} size={1}>{`${new Date(x.date).toLocaleString('default', { month: 'short' })} ${new Date(x.date).getDate()} - ${x.name}(${x.type=="ass"? "Assignment": "Exam"}) `}</Heading>
                    </HistoryItem>
                )}    
                </> 
                :
                <>      
                 {[props.event].map((x:any)=>
                    <HistoryItem key={uuidv4()} className="py-2" onClick={()=>switchEvent(x)}>
                         <Heading  white style={{textDecoration: `underline solid 3px ${themeC.primary} ` }} size={1}>{`${new Date(x.date).toLocaleString('default', { month: 'short' })} ${new Date(x.date).getDate()} - ${x.name}(${x.type=="ass"? "Assignment": "Exam"}) `}</Heading>
                    </HistoryItem>
                )}         
                </>
                }
                <Button fontSize=".8rem" onClick={newHandler}  transparent borderRadius posClassName="pb-2">New Event</Button>
         </OutlinedDiv>

            }
            <div className="col-lg-9  row m-0 p-0 ">

            <OutlinedDiv $mode={themeC.mode} $notop $noright $noleft  className="col-lg-12" style={{maxHeight: "12vh"}}>
            <Heading size={5} handWritten >{location.pathname==="/signup"? props.class.name : location.state.class.name}</Heading>
            </OutlinedDiv>  
            <div className="col-lg-8">
                
                    
            </div>  
                


            </div>
            {notesMode? 
            <div className='col-lg-3'>
                <Heading size={3} style={{textDecoration: "underline"}}>Vocab</Heading>
                    <div className="mt-5" >

                        {vocab && vocab.map((x:any)=>
                          <VocabItem master={handleVocabEdit}item={x}/>
                            
                            )}
                        {newTerm && 
                            <div>
                                
                                <Input 
                                onChange={(e: any) => {
                                    inputHandler(e, setTerm);
                                }}
                                label="Term"
                                posClassName="mx-auto col-lg-9 py-1"
                                className="text-center"
                                />

                                {term &&    <Input 
                                onChange={(e: any) => {
                                    inputHandler(e, setDef);
                                }}
                                label="Def"
                                posClassName="mx-auto col-lg-9 py-1"
                                className="text-center"
                                />}
                        
                            
                            
                            </div>}
                        <div className="mx-auto text-center mt-4">
                                <AppIcon icon={mdiPlus} onClick={newTermHandler}title="new" />
                        </div>


                    </div>
            </div>
            :
            <>
            {/* <div className="col-lg-4">
                
                {selected &&
                <>
                 <Heading size={3} style={{textDecoration: "underline"}}>{selected.data.name}</Heading>
                
                <div className="col-lg-6 mx-auto" style={{borderBottom: themeC.mode=="chalk"? "3px solid white": "3px solid black"}}>
                <Heading size={6}>{selected.data.name.prep? selected.data.name.prep : 0}</Heading>
                <Heading size={2}>Preparedness Score</Heading>
                    </div>

                <div className="col-lg-6 mx-auto py-3 row" style={{borderBottom: themeC.mode=="chalk"? "3px solid white": "3px solid black"}}>
                        <Heading size={2}>New</Heading>
                 <AppIcon className="col-lg-4" onClick={gatherTerms} icon={mdiCards} title="Flash Cards"></AppIcon>
                 <AppIcon className="col-lg-4" onClick={overview} icon={mdiSchool} title="Learn More"></AppIcon>
                 <AppIcon className="col-lg-4" onClick={quiz} icon={mdiFileQuestion} title="Practice Quiz"></AppIcon>

                
                 </div>
                
                

                
                </>}
                
                

                         
            </div> */}
            </>}
        </div>
    </div>
    }
    </>
       )
    }
else{
return<LoadingScreen />
}

}

export {ClassMode}