import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { AppContext } from "../../AppContent";
import { Heading, Timeline, Login, Haiku, Life, Pong, Canvas} from "..";

import { ThemeContext } from "../../App";
import { OutlinedDiv } from "../../styled";

import { Logic } from "../../functions";
import { useNavigate, useLocation } from "react-router";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { FlashCard } from "./flash";
import { Doodle } from "../widgets/doodle";

const LoginMode = (props: {next:Function ,size:"desktop"| "mobile"}) => {
  const location = useLocation();
  const themeC = useContext(ThemeContext).theme;

  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [answer, setAnswer] = useState<string>(
    "Check your inbox for a link to verify your email address, Once you have verified click the button below"
  );
  const navigate = useNavigate();

  const errorHandler = async () => {
    console.log("errorHandler");
  };
  const permsHandler = async () => {
    navigate("/?sub");
  };

  const logic = new Logic(permsHandler, errorHandler, errorHandler);

  useEffect(()=>{


    const parseData = (toUse: any) => {
      let newData = toUse.doodles.concat(toUse.haikus,toUse.flash, [{game:true},{game:true},{game:true},{game:true},{game:true}])

              setData(newData.sort( () => Math.random() - 0.5))
      }



      const bootstrapAsync2 = async () => {

        let data = await logic.eloise.signUpFill()
  
        localStorage.setItem("signUpFillData", JSON.stringify(data))
        parseData(data)

      }

      let test = localStorage.getItem("signUpFillData")
      if (!test) {
            bootstrapAsync2()

      }
      else{
        let tempData = JSON.parse(test)
        parseData(tempData)
      }
   
    
  

  },[])

  if(props.size ==="mobile"){
    return (<div  style={{ height: "100vh", padding: "20vh 10px", backgroundColor: themeC.mode=="chalk"? "black": "white"}}>

        <Login className=" mx-auto pt-5 " next={props.next} />

    </div>)
  }


  else{
  return (
    <div style={{ minHeight: "100vh" }}>
      {/* Heading */}
      <OutlinedDiv
        $noright
        $noleft
        $notop
        $mode={themeC.mode}
        className=""
        style={{
          height: "10vh",
        }}
      >
        <Heading handWritten align="left" size={6}>
          eloise.life
        </Heading>
      </OutlinedDiv>

      {/* Left */}

      <div className="row g-0  flex-lg-nowrap">
        <div
          className="col-lg-8"
          style={{
            overflowY: "scroll",
            overflowX:"hidden",
            maxHeight: "90vh"
          }}
        >
          <>
          {/* <Canvas  width={500} height={500}/>
          <Pong width={500} height={500}/> */}
            <div className="row">
              {data &&
                data.map((x: any) => (
                  <div key={uuidv4()} className="col-lg-6">
                    {x.deck ? (
                      <div className="text-center mx-auto">
                        <FlashCard
                          key={uuidv4()}
                          card={
                            x.deck.map((x: any) => {
                              return [Object.keys(x)[0], Object.values(x)[0]];
                            })[0]
                          }
                        />
                      </div>
                    ) : (
                      <>
                        {x.game ? (
                          <Life key={uuidv4()} />
                        ) : (
                          <>
                            {x.text ? (
                              <Haiku key={uuidv4()} text={x.text} />
                            ) : (
                              <Doodle key={uuidv4()} doodle={x} />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </>
        </div>
     
      <div className="col-lg-4">
        {/* //Row 1 */}
        <div
          className="row g-0  flex-lg-nowrap"
          style={{
            height: "40vh",
          }}
        >
          <OutlinedDiv $notop $noright $mode={themeC.mode} className="p-5">
            <Login className=" mx-auto " next={props.next} />
          </OutlinedDiv>
        </div>

        {/* //Row 2 */}
  
          <OutlinedDiv
            $notop
            $noright
            $nobottom
            $mode={themeC.mode}
            style={{
            height: "50vh",
          }}
          >
          </OutlinedDiv>
   

        {/* //Row 3 */}
        {/* <div
          className="row g-0  flex-lg-nowrap"
          style={{
            height: "20vh",

          }}
        >
          <OutlinedDiv
            $notop
            $nobottom
            $noright
            $mode={themeC.mode}
          ></OutlinedDiv>
        </div> */}
      </div>
    </div>
  </div>
  );
      }
};

export { LoginMode };
