import React, {useState, useEffect, useContext} from 'react';
import { Logic } from '../../functions';
import { AppIcon, Button, Heading } from '..';

import { ThemeContext } from "../../App";

import { useNavigate , useLocation} from "react-router";
import { mdiSchool, mdiTimerCancel } from "@mdi/js";
import { ChatMessage } from '../../styled';


const  Sub = ()=>{

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
    const themeC = useContext(ThemeContext).theme

    const [sub , setSub] = useState<string>()
    const [userData, setUserData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    

    useEffect(() => {

        //console.log(location)
    
    
        let test = localStorage.getItem("userData");
        if (test) {
          let parsed = JSON.parse(test);
          setUserData(parsed);
    
          const bootstrapAsync = async()=>{
            let x = await logic.generic.getSub()
            setSub(x)
    
          }
          bootstrapAsync()
          setLoading(false);
    
        } else {
          setUserData({});
        }
      }, []);

      const subscribe = async()=>{
        let x = await logic.generic.subscribe()
        window.location.href=x
    
      }

      const manage = async()=>{
        window.location.href="https://billing.stripe.com/p/login/fZe8AjcR6fMc4PCaEE"
     
       }
    return (
    
    <div className="mt-4 row mx-auto">
      


                      <ChatMessage message={{human:undefined, eloise:"Looks like your free trial has expired. Please subscribe to continue"}}/>

  <div  className=" col-lg-9 p-3 mx-auto" style={{border: `3px solid ${themeC.mode== "chalk"? "white": "black"}`, borderRadius: "5px"}}>
       
        <>
        <Heading  white size={3} style={{textDecoration:"underline"}}>
       Trial Expired
        </Heading>
        <div className="text-center my-4">
        <AppIcon  title="student" onClick={()=>{}}icon ={sub !== "expired"? mdiSchool : mdiTimerCancel} size={2.5} />
        </div>
        </>
        

<Button onClick={sub == "active"? manage: subscribe}>{sub == "active"? "Manage Subscription": "Subscribe"}</Button>
</div>


    </div>)

}


export {Sub}