import React, { useContext } from "react"
import loadingWhite from "../../assets/loadingWhite.svg"
import loadingBlack from "../../assets/loadingBlack.svg"

import { ThemeContext } from "../../App";

const Loading  = ()=>{

const themeC = useContext(ThemeContext).theme

    return(
    
    <div className="text-center">
    <img src={themeC.mode=="chalk"? loadingWhite:loadingBlack}></img>
    </div>)
}



export default Loading