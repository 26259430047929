import React, { useState, useEffect, useRef, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
//Component Imports
import {
 
    ClearButton, Heading, LoadingScreen,
} from "../";
import { Logic } from "../../functions";
import { AppContext } from "../../AppContent";


const FlashOps = (props: {
    mode: Function
    foL: "flash" | "learn"
}) => {


    const appContext = useContext(AppContext)

    const [length, setLength] = useState<string>();
    const [level, setLevel] = useState<string>();
    const [topic, setTopic] = useState<string>();

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

    
    if(appContext){
    if(props.foL === "flash"){
    return (
        <div className="pt-4">
        <Heading size={4} handWritten style={{textDecoration:"underline"}}>Options</Heading>

            <ClearButton 
                            border
                posClassName="py-1 col-lg-9 mx-auto"
                borderRadius
                onClick={() => { appContext.setMode("learn") }}

            >
                Learn
            </ClearButton>

            <ClearButton
                            border

                posClassName="py-1 col-lg-9 mx-auto"
                borderRadius
                onClick={() => { props.mode("normal") }}

            >
                Grid
                </ClearButton>
            <ClearButton
                            primary borderColor
                            border

                posClassName="py-1 col-lg-9 mx-auto"
                borderRadius
                onClick={() => { props.mode("quiz") }}
            >
                Quiz
                </ClearButton>
            <ClearButton
                            primary borderColor
                border
                posClassName="py-1 col-lg-9 mx-auto"
                borderRadius
                onClick={() => { props.mode("edit") }}

            >
                Edit
                </ClearButton>


                <ClearButton
                                border

                posClassName="py-1 col-lg-9 mx-auto"
                borderRadius
                onClick={() => { props.mode("new") }}

            >
                New Deck
                </ClearButton>
        </div>
    );}
    else{
        return (
            <div className="pt-4">
    
               
    <Heading size={4} handWritten style={{textDecoration:"underline"}}>Options</Heading>

        
    
                <ClearButton
                                primary borderColor
                                border
                    posClassName="py-1 col-lg-9 mx-auto"
                    borderRadius
                    onClick={() => { props.mode("newTopic") }}
    
                >
                     New Topic
                    </ClearButton>
                <ClearButton
                                primary borderColor
                                border
                    posClassName="py-1 col-lg-9 mx-auto"
                    borderRadius
                    onClick={() => { props.mode("quiz") }}
                >
                    Quiz
                    </ClearButton>
                <ClearButton
                                primary borderColor
                    border
                    posClassName="py-1 col-lg-9 mx-auto"
                    borderRadius
                    onClick={() => { props.mode("newClass") }}
    
                >
                    Switch Class
                    </ClearButton>
    
    
                    {/* <ClearButton
                                    primary borderColor
                                    border
                    posClassName="py-1 col-lg-9 mx-auto"
                    borderRadius
                    onClick={() => { props.mode("new") }}
    
                >
                    Random
                    </ClearButton> */}
            </div>
        )
    }
}else{
    return<LoadingScreen />
}
};

export { FlashOps };
