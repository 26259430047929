import React, {useState, useEffect, useContext} from 'react';

import {Heading, Life} from ".."
import { HistoryItem } from '../../styled';
import { useNavigate } from 'react-router';
import { AppContext } from '../../AppContent';


const Todo = (props:{})=>{

    
    const appContext = useContext(AppContext)

    const [todos, setTodos] = useState<any>()
    const navigate = useNavigate()

    useEffect(()=>{

        let test = localStorage.getItem("todos")
        if(test){
            setTodos(JSON.parse(test))
        }

    },[])
    return(
        <>
           <Heading
        style={{ textDecoration: "underline" }}
        handWritten
        size={5}
        white
        posClassName="pb-4"
      >
       
        Todo
      </Heading>


        {todos && todos.length > 0? 
        
        <>
     
            <HistoryItem onClick={()=>navigate(`/${todos[0].app}`)}>
                <Heading size={2}>{todos[0].title}</Heading>
                </HistoryItem>
            </>
    

    
    :
   <div className="">


   </div>
    }

    </>
    )
}


export {Todo}