import React, { useState, useEffect, useContext} from "react";
import { AppIcon, ClearButton, LoadingScreen } from "../";

import { v4 as uuidv4 } from "uuid";
import { AppContext, ClassInterface } from "../../AppContent";
import { useNavigate } from "react-router";

import { mdiDeleteOutline } from '@mdi/js';
import { useIdToken } from "react-firebase-hooks/auth";
import { Logic } from "../../functions";


const ClassSelect = (props:{next:Function, nav?:boolean,posClassName?:string, del?: boolean, onDelete?: (c: ClassInterface) => void, spacing?: string})=>{
    
  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const navigate = useNavigate()
  const appContext = useContext(AppContext)
  const [classes, setClasses] = useState<string[]>()

  const [user, loading, error] = useIdToken(logic.fb.auth)


  if(appContext){
  return (
    <div className={props.posClassName?? " "}>
      {appContext.classes
        .sort((a: ClassInterface, b: ClassInterface) => a.name > b.name ? 1 : -1)
        .map((c: ClassInterface) => {
          return (
            <div className={props.spacing ?? "col mx-2"} key={uuidv4()}>

            <div className="d-flex align-items-center " >
              <ClearButton
                secondary
                borderColor
                borderRadius
                border
                onClick={() => {
                  if(props.nav){
                    appContext.setClass(c); 
                    navigate("/class", { state: { class: c } })
                  }
                  else{
                     props.next(c);
                  }
                }}
                posClassName={"col mx-2"}
              >
                {c.name}
              </ClearButton>
              {props.del && (

                <>
                {c.id === "Personal"? 
                  <AppIcon
                  title='Delete'
                  onClick={() => {}}
                  icon={mdiDeleteOutline}
                  size={1.4}
                />
                :
                <AppIcon
                  title='Delete'
                  onClick={() => props.onDelete && props.onDelete(c)}
                  icon={mdiDeleteOutline}
                  size={1.4}
                />
              }
                </>
                  
               
              )}
              </div>
            </div>
          );
        })}
    </div>
  )
      }
    else{
      return<LoadingScreen />
    }
}


export {ClassSelect}