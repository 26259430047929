import React, { useState, useEffect, useRef , useContext} from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
//Component Imports
import {
    Loading,
    Heading,
    Input,
    Button,
    ClearButton,
    ClassSelect,
    ClassPage

} from "..";
import { Logic } from "../../functions";
import {FlashCardDiv, LearnCardDiv, Back } from "../../styled";
import ReactCardFlip from 'react-card-flip';
import { Table } from "react-bootstrap";
import theme from "../../theme";
import { AppContext } from "../../AppContent";


const FlashCard = (props: { card: any, customClick?: Function }) => {

    const [isFlipped, setIsFlipped] = useState<boolean>(false)
    return <ReactCardFlip isFlipped={isFlipped} containerClassName="flashCard col" flipDirection="vertical">
        <FlashCardDiv className="mx-auto w-100" onClick={() => { props.customClick ? props.customClick(props.card.word) : setIsFlipped(true) }}>
            <Heading size={2} posClassName="pt-5" white>{props.card.word}</Heading>
        </FlashCardDiv>

        <FlashCardDiv className="mx-auto w-100" onClick={() => { props.customClick ? props.customClick(props.card.def) : setIsFlipped(false) }}>
            <Heading size={1} white>{props.card.def}</Heading>
        </FlashCardDiv>
    </ReactCardFlip>
}

const Quiz = (props: { cards: { word: string, def: string, id: string }[], back: Function }) => {

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

    const [cardIndex, setCardIndex] = useState<number>(0)
    const [cards, setCards] = useState(props.cards.map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: { sort: number; }, b: { sort: number; }) => a.sort - b.sort)
        .map((value: any) => value.value))


    const [options, setOptions] = useState<any[]>()
    const [text, setText] = useState<string>(cards[cardIndex][1])
    useEffect(() => {
        let temp = [...cards].filter((x: any) => x[0] !== cards[cardIndex][0])
        temp = temp.slice(0, 3)
        temp = [...temp, cards[cardIndex]].map((value: any) => ({ value, sort: Math.random() }))
            .sort((a: { sort: number; }, b: { sort: number; }) => a.sort - b.sort)
            .map((value: any) => value.value)

        setOptions(temp)

    }, [])


    const select = (word: string) => {

        if (word === cards[cardIndex][0]) {
            setText("Correct!")

        }
        else {
            setText(`Not quite... I was looking for ${cards[cardIndex][0]}`)

        }

        const timer = setTimeout(() => {
            next()
            setCardIndex(cardIndex + 1)

        }, 2000);
        const next = () => {
            let temp = [...cards].filter((x: any) => x[0] !== cards[cardIndex + 1][0])
            setText(cards[cardIndex + 1][1])
            temp = temp.slice(0, 3)
            temp = [...temp, cards[cardIndex + 1]].map((value: any) => ({ value, sort: Math.random() }))
                .sort((a: { sort: number; }, b: { sort: number; }) => a.sort - b.sort)
                .map((value: any) => value.value)

            setOptions(temp)
            setCardIndex(cardIndex + 1)
        }
        return () => clearTimeout(timer);

    }
    return (<>
        <div className="container mx-auto pt-5">
            <div className="row">

                <Heading size={2} posClassName="pt-3" white>{text}</Heading>
            </div>


            <div className="row">


                {options && options.map((card: any) => {

                    return <FlashCard customClick={select} key={uuidv4()} card={card} />
                })}

            </div>

        </div>

    </>)
}
const Learn = (props: { cards: { word: string, def: string, id: string }[], back: Function }) => {

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

    const [isFlipped, setIsFlipped] = useState<boolean>(false)
    const [cardIndex, setCardIndex] = useState<number>(0)
    const [cards, setCards] = useState(props.cards.map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: { sort: number; }, b: { sort: number; }) => a.sort - b.sort)
        .map((value: any) => value.value))


    const next = () => {
        if (cardIndex < cards.length - 1) {
            setCardIndex(cardIndex + 1)
        }
        else {
            setCardIndex(0)
            setCards(props.cards.map((value: any) => ({ value, sort: Math.random() }))
                .sort((a: { sort: number; }, b: { sort: number; }) => a.sort - b.sort)
                .map((value: any) => value.value))
        }
    }


    return (
        <div className="container mx-auto pt-5">
            <div className="row mx-auto">


                <ReactCardFlip isFlipped={isFlipped} containerClassName="learnCard mx-auto" flipDirection="vertical">
                    <LearnCardDiv onClick={() => { setIsFlipped(true) }}>
                        <Heading size={2} posClassName="pt-5" white>{cards[cardIndex].word}</Heading>
                    </LearnCardDiv>

                    <LearnCardDiv onClick={() => { setIsFlipped(false) }}>
                        <Heading size={1} white>{cards[cardIndex].def}</Heading>
                    </LearnCardDiv>
                </ReactCardFlip>

            </div>

            <div className="row mx-auto">
                <ClearButton border posClassName="col-lg-3 mx-auto" borderRadius onClick={next}>Next</ClearButton>
            </div>

        </div>
    )
}
const Edit = (props: { cards:{ word: string, def: string, id: string }[], deck: any, back: Function , save:Function}) => {

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

    const { domRef1 } = logic.generic.useFocus();
    const { domRef2 } = logic.generic.useFocus();

    const [cards, setCards] = useState(props.cards)

    const [def, setDef] = useState<string>()
    const [term, setTerm] = useState<string>()
    const [text, setText] = useState<string>("Edit Deck")

    const [loading, setLoading] = useState<boolean>(false)
    const [deleted, setDeleted] = useState<boolean>(false)

    const remove = (id:string) => {
        setCards([...cards].filter((x: any) => x.id !== id))
    }

    const add = () => {
        setCards([...cards, {word:term ?? " ", def:def?? " ", id:uuidv4()}])
    }


    const save = () => {
       props.save(cards)

    }

    return (
        <div className="container mx-auto pt-5">

            <div className="row mx-auto">
                {!loading &&
                    <Heading size={3} white>{text}</Heading>
                }

            </div>
            {loading ? <Loading /> :
                <>
                    {!deleted &&
                        <>
                            <div className="row mx-auto">


                                <Table>
                                    <thead>
                                        <tr>
                                            <td><Heading align="left" size={2} white>Term</Heading></td>
                                            <td><Heading align="left" size={2} white>Definition</Heading></td>
                                            <td><Button onClick={save} borderRadius primary>Save</Button></td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cards.map((card: any) => {
                                            return <tr>
                                                <td><Heading align="left" size={1} white>{card.word}</Heading></td>
                                                <td><Heading align="left" size={1} white>{card.def}</Heading></td>
                                                <td><ClearButton onClick={() => { remove(card.id) }} transparent>Delete</ClearButton></td>

                                            </tr>
                                        })
                                        }
                                        <tr>
                                            <td> <Input
                                                backgroundColor="transparent"
                                                primary
                                                className="text-center"
                                                posClassName="col-lg-10"
                                                ref={domRef2}

                                                onChange={(e: any) => {
                                                    logic.generic.inputHandler(e, setTerm);
                                                }}

                                                label={"Term"}
                                            /></td>
                                            <td> <Input
                                                backgroundColor="transparent"
                                                primary
                                                className="text-center"
                                                posClassName="col-lg-11 "
                                                ref={domRef1}
                                                onChange={(e: any) => {
                                                    logic.generic.inputHandler(e, setDef);
                                                }}

                                                label={"Definition"}
                                            /></td>
                                            <td><ClearButton onClick={() => { add() }}  border borderRadius>Add</ClearButton></td>

                                        </tr>
                                    </tbody>



                                </Table>
                            </div>

                            </>}</>}
        </div>
    )
}




const Flash = (props: { deck?: any, save:Function, mode: string, back: Function , load?:string}) => {

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
    const appContext = useContext(AppContext)
    const  domRef  = useRef();
    const [cards, setCards] = useState<any[]>(props.deck?? []);

        switch (props.mode) {

            case "edit":
                return (<Edit save={props.save} cards={cards} deck={props.deck} back={()=>{props.back("grid")}} />)
            case "quiz":
                return (<Quiz cards={cards} back={()=>{props.back("grid")}} />)
            case "learn":
                return (<Learn cards={cards} back={()=>{props.back("grid")}} />)

            default:
                return (<>
                    <div className="container mx-auto pt-5">
                        <div className="row">



                            {cards && cards.map((card:any) => {

                                return <FlashCard key={uuidv4()} card={card} />
                            })}

                        </div></div>

                </>)
    
 }
}

export { Flash, FlashCard };
