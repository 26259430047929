
import React, {useState, useEffect, useContext} from "react";
import { Button, Cal, ClassSelect, ClearButton, Heading, Input } from ".."

import { v4 as uuidv4 } from "uuid";
import { Logic } from "../../functions";
import  {ThemeContext} from "../../App"

import { useNavigate, useLocation } from "react-router";
import { AppContext, EventInterface, ClassInterface  } from "../../AppContent";
import { Back, HistoryItem} from "../../styled";


const getOrdinalNum = (number: number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

const AddEvent = (props:{done:Function , cancel:Function,
    date?:any,
    type?: string,
    class?: ClassInterface,
    topic?: string,
    description?: string})=>{

    const [addDate, setAddDate] = useState<any>(props.date ?? undefined)
    const [type, setType] = useState(props.type ?? undefined)
    const [course, setCourse] = useState(props.class ?? undefined)
    const [topic, setTopic] = useState(props.topic ?? undefined)
    const [description,setDescription] = useState(props.description ?? undefined)
    const appContext = useContext(AppContext);

    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
    
    
    const inputHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: Function
      ) => {
        const enteredName = event.target.value;
        setter(enteredName);
      };


      const save = () => {

        const bootstrapAsync = async () => {
            let noteId = uuidv4()
            let newId = uuidv4()

            let x = {
                date:addDate,
                type: type,
                class: course,
                topic: topic,
                name: topic,
                noteId: noteId,
                id: newId,

                description: description
            }

            if (appContext) {
                let y = await logic.generic.updateUpcoming(x, appContext.events)
                console.log(y)
                appContext.setEvents(y)

                props.done()
            }

        }
        bootstrapAsync()
    }

    
    const done = ()=>{
        let ots = {
            date:addDate,
            type: type,
            class: course,
            topic: topic,
            name: topic,

            description: description
        }

        props.done(ots)
    }
    return(

        <div>       
        <Back onClick={props.cancel} />

       <Heading size={5} handWritten>{`New ${type == "ass"? "Assignment": "Test"}`}</Heading>

       <div className="row mx-auto pt-5">

               <div className="col-lg-12 mx-auto d-inline  py-2 pb-4">
                       <Heading size={2}>Date</Heading>
                        {addDate?
                       <Heading size={1}>{`${addDate.toLocaleString('en-us', { weekday: 'long' })} ${addDate.toLocaleString("en-us", { month: "long" })} ${getOrdinalNum(addDate.getDate())}`}</Heading>
                        :
                        <Cal next={setAddDate}/>
                                                 
                        }
                       </div>

               <div className="col-lg-12 mx-auto  py-3">
                       <Heading size={2}>Class</Heading>

                   {course? 
                       <ClearButton posClassName="col-lg-2 mx-auto" onClick={()=>{}} >{course.name}</ClearButton>
                   :
                   <div className="col-lg-9  row mx-auto pb-4">
                    <ClassSelect posClassName="row" next={(x:ClassInterface)=>(setCourse(x))} spacing=" col-lg-4" />
                   </div>
                   }
                </div>

                <div className="col-lg-12 mx-auto  py-3">
                        <Heading size={2}>Exam or Assignment?</Heading>
                        <div className="row mx-auto col-lg-4 py-3">
                        {type!=="exam" && <ClearButton posClassName="col-lg-5 mx-auto" onClick={()=>{setType("ass")}}>Assignment</ClearButton>}
                         {type!=="ass" &&<ClearButton posClassName="col-lg-5 mx-auto" onClick={()=>{setType("exam")}} >Exam</ClearButton>}
                        </div>

                 </div>
                   <div className="col-lg-12 mx-auto d-inline  py-3">
                       <Heading size={2}>Topic</Heading>

                      
                       <Input onChange={(e: any) => {
                           inputHandler(e, setTopic);
                         }}
                         label="Topic"
                         posClassName="mx-auto col-lg-2 py-3"
                         className="text-center"
                         />
                       
                   </div>
                   <div className="col-lg-12 mx-auto d-inline  py-3">
                       <Heading size={2}>Description</Heading>

                      
                       <Input onChange={(e: any) => {
                           inputHandler(e, setDescription);
                         }}
                         label="Description"
                         posClassName="mx-auto col-lg-2 py-3"
                         className="text-center"
                         />
                       
                   </div>
                   <div className="col-lg-12 mx-auto d-inline  py-3">
                       {description&& 
                       <Button onClick={save} borderRadius posClassName="col-lg-3 mx-auto" >Save</Button>                        
                       }
                   </div>
             

       </div>
       </div>
    )
}


export {AddEvent} 