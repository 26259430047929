// Normal Display Mode
// Dependency Imports
import React, { useState, useEffect } from "react";

//Component Imports
import { Heading, Input, Loading } from "../";

import { useNavigate } from "react-router";
import {GameBackground, FadeInDiv } from "../../styled";
import { Logic } from "../../functions";
import axios from "axios";
import { Row } from "react-bootstrap";


const Game = () => {


  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const { domRef } = logic.generic.useFocus();

  const navigate = useNavigate();
  const [text, setText] = useState("You are in the Forest");

  interface interaction {
    human: string;
    eloise: string;
  }
  const [convo, setConvo] = useState<interaction[]>([
    { eloise: "you are in the forrest", human: "where am i" },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>();
  const [prompt, setPrompt] = useState<string>(" ");
  const [login, setLogin] = useState<boolean>(false);

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const buzzwordsMap: { words: string[]; next: Function }[] = [
    { words: ["exit"], next: () => navigate("/") },
  ];

  const buzzwords = buzzwordsMap
    .map((x) => {
      return x.words;
    })
    .flat();

  const queryHandler = async (query: string) => {
    setLoading(true);

    let queryAsList = query.split(" ");
    var buzzTest = false;
    if (queryAsList.length > 1) {
      queryAsList.map((el) => {
        if (buzzwords.includes(el)) {
          let buzz = buzzwordsMap.find((x) => x.words.includes(el));

          if (buzz) {
            buzzTest = true;
            buzz.next();
          }
        }
      });
    } else {
      if (buzzwords.includes(query)) {
        let buzz = buzzwordsMap.find((x) => x.words.includes(query));
        if (buzz) {
          buzzTest = true;
          buzz.next();
        }
      }
    }
    if (!buzzTest) {
      console.log(localStorage.getItem("AuthToken"));
      let test =
        convo.length > 0
          ? await logic.eloise.game(query, convo)
          : await logic.eloise.game(query);
      console.log(test);
      setConvo([...convo, { human: query, eloise: test }]);
      if (test == "auth") {
        //     setLogin(true)
        //     setLoading(false)
        //     setPrompt("email: ")
        // setText("please login to continue")
        domRef?.current?.focus();
        navigate("/");
      } else {
        setText(test);
        setLoading(false);
        domRef?.current?.focus();
      }
    }
  };

  const submit = () => {
    setLoading(true);

    const bootstrapAsync = async () => {
      const userData: { [key: string]: string } = {
        email: email ? email : "email",
        password: password ? password : "password",
      };

      axios
        .post(
          `${logic.api}/login`,
          //  "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/api/login",

          userData
        )
        .then((response: any) => {
          localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
          localStorage.setItem("email", userData.email);

          domRef?.current?.focus();
        })
        .catch((error: any) => {
          setText(error.message);
          setEmail(undefined);
          setPassword(undefined);
          setLoading(false);
          domRef?.current?.focus();
        });
    };
    bootstrapAsync();
  };

  useEffect(() => {
    domRef?.current?.focus();
  }, [loading]);

  const onEnterHandler = (x: string) => {
    if (login) {
      if (password) {
        submit();
        domRef?.current?.focus();
      } else if (email) {
        setPassword(x);
        domRef?.current?.focus();
      } else {
        setEmail(x);
        setPrompt("password: ");
        domRef?.current?.focus();
      }
    } else {
      queryHandler(x);
    }
  };


  return (
    <GameBackground className="container-fluid">
      <Row>
        <FadeInDiv>
          {loading ? (
            <Loading />
          ) : (
            <Heading posClassName="col-lg-9 mx-auto" white>
              {text.replace(/['"]+/g, "")}
            </Heading>
          )}
        </FadeInDiv>
      </Row>

      <Row>
        {!loading && (
          <Input
            backgroundColor="transparent"
            primary
            className="text-center"
            posClassName="col-lg-9 mx-auto py-5 text-center"
            onEnter={onEnterHandler}
            label={prompt}
            borderless
            ref={domRef}
          />
        )}
      </Row>
    </GameBackground>
  );
};

export { Game };
