// Study Display Mode
// Dependency Imports
import React, { useState, useEffect, useContext } from "react";

//Component Imports
import { Heading, Loading, Button } from "../";
import { Logic } from "../../functions";
import { Table } from "react-bootstrap";

import { signOut } from "firebase/auth";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";

import { useNavigate } from "react-router";

const AdminSmall1 = () => {
  return (
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
      Chat GPT
    </Heading>
  );
};

const AdminSmall2 = () => {
  return (
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
      Dalle
    </Heading>
  );
};
const AdminSmall3 = () => {
  return (
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
      Stalk
    </Heading>
  );
};

const Admin = () => {

  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>();
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [resetSent, setResetSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(
    logic.fb.auth
  );

  useEffect(() => {
    let test = localStorage.getItem("userData");
    if (test) {
      let parsed = JSON.parse(test);
      setUserData(parsed);
      setLoading(false);
    } else {
      setUserData({});
    }
  }, []);

  const resetPassword = () => {
    const bootstrapAsync = async () => {
      sendPasswordResetEmail(userData.email);
    };
    bootstrapAsync();
  };

  const logout = () => {
    signOut(logic.fb.auth);
    window.localStorage.clear();
    navigate("/", { state: { logout: true } });
  };

  return (
    <div>
      <div>
        <div className="container mx-auto pt-5">
          <Heading size={2} align="left" white>
            Your Account:
          </Heading>

          <div style={{ paddingLeft: "5vw" }}>
            {loading ? (
              <Loading />
            ) : (
              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <Heading size={1} align="left" white>
                        User:
                      </Heading>
                    </td>
                    <td>
                      <Heading
                        size={1}
                        align="left"
                        white
                      >{`${userData.firstName} ${userData.lastName}`}</Heading>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Heading size={1} align="left" white>
                        Email:
                      </Heading>
                    </td>
                    <td>
                      <Heading size={1} align="left" white>
                        {userData.email}
                      </Heading>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Heading size={1} align="left" white>
                        Username:
                      </Heading>
                    </td>
                    <td>
                      <Heading size={1} align="left" white>
                        {userData.username}
                      </Heading>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Heading size={1} align="left" white>
                        Password:
                      </Heading>
                    </td>
                    <td>
                      {resetLoading ? (
                        <Loading />
                      ) : resetSent ? (
                        <Heading
                          size={1}
                          align="left"
                          posClassName="col-lg-2"
                          white
                        >
                          Email Sent
                        </Heading>
                      ) : (
                        <Button posClassName="col-lg-6" onClick={resetPassword}>
                          Reset Password
                        </Button>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Heading size={1} align="left" white>
                        Action:
                      </Heading>
                    </td>

                    <td>
                      <Button posClassName="col-lg-6" onClick={logout}>
                        Log Out
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Admin, AdminSmall1, AdminSmall2, AdminSmall3 };
