// Study Display Mode
// Dependency Imports
import React, { useState, useEffect, useContext } from "react";


//Component Imports
import { Heading, Loading, Button, AppIcon } from "../";
import { Logic } from "../../functions";
import { Table } from "react-bootstrap";

import { ThemeContext } from "../../App";

import { signOut } from "firebase/auth";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useNavigate , useLocation} from "react-router";
import { mdiSchool, mdiTimerCancel } from "@mdi/js";



const ProfileSmall1 = (props:{setMode:Function}) => {
  return (
    <div>
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
    Account
    </Heading>
     <Button onClick={()=>props.setMode("pref")}borderRadius posClassName="col-lg-9 mx-auto py-2">Manage Account</Button>
     <Button onClick={()=>props.setMode("pref")}borderRadius posClassName="col-lg-9 mx-auto py-2">Log out</Button>
 
     </div>
  );
};

const ProfileSmall2 = (props:{setMode:Function}) => {
  return (
    <div>
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
       Preferences 
    </Heading>
    <Button onClick={()=>props.setMode("pref")} borderRadius posClassName="col-lg-9 mx-auto py-2">Manage Account</Button>
    <Button onClick={()=>props.setMode("pref")}borderRadius posClassName="col-lg-9 mx-auto py-2">Log out</Button>

    </div>
  );
};
const ProfileSmall3 = (props:{setMode:Function}) => {
  return (
    <Heading size={2} className="pt-2" white style={{ fontStyle: "italic" }}>
      Subscription
    </Heading>
  );
};

const Profile = (props:{mode:string}) => {


  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const navigate = useNavigate();
  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };
  const [userData, setUserData] = useState<any>();
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [resetSent, setResetSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation()
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(
    logic.fb.auth
  );
  const themeC = useContext(ThemeContext).theme
    const [sub , setSub] = useState<string>()

  useEffect(() => {

    //console.log(location)


    let test = localStorage.getItem("userData");
    if (test) {
      let parsed = JSON.parse(test);
      setUserData(parsed);

      const bootstrapAsync = async()=>{
        let x = await logic.generic.getSub()
        setSub(x)

      }
      bootstrapAsync()
      setLoading(false);

    } else {
      setUserData({});
    }
  }, []);

  const resetPassword = () => {
    const bootstrapAsync = async () => {
      sendPasswordResetEmail(userData.email);
    };
    bootstrapAsync();
  };

  const logout = () => {
    signOut(logic.fb.auth);
    window.localStorage.clear();
    navigate("/", { state: { logout: true } });
  };
 
  const subscribe = async()=>{
    let x = await logic.generic.subscribe()
    window.location.href=x

  }
  
  const manage = async()=>{
   window.location.href="https://billing.stripe.com/p/login/fZe8AjcR6fMc4PCaEE"

  }

  return (
    <div>
      <div>
        <div className="container mx-auto pt-5">
          <Heading size={3  } align="left" white>
            Your Account:
          </Heading>

          <div style={{ paddingLeft: "5vw" }}>
            {loading ? (
              <Loading />
            ) : (
              <>
              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        User:
                      </Heading>
                    </td>
                    <td>
                      <Heading
                        size={2}
                        align="left"
                        white
                      >{`${userData.firstName} ${userData.lastName}`}</Heading>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        Email:
                      </Heading>
                    </td>
                    <td>
                      <Heading size={2} align="left" white>
                        {userData.email}
                      </Heading>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        Username:
                      </Heading>
                    </td>
                    <td>
                      <Heading size={2} align="left" white>
                        {userData.username}
                      </Heading>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        Password:
                      </Heading>
                    </td>
                    <td>
                      {resetLoading ? (
                        <Loading />
                      ) : resetSent ? (
                        <Heading
                          size={2}
                          align="left"
                          posClassName="col-lg-2"
                          white
                        >
                          Email Sent
                        </Heading>
                      ) : (
                        <Button posClassName="col-lg-4" onClick={resetPassword}>
                          Reset Password
                        </Button>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        Action:
                      </Heading>
                    </td>

                    <td>
                      <Button posClassName="col-lg-4" onClick={logout}>
                        Log Out
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Heading size={2} align="left" white>
                        Classes:
                      </Heading>
                    </td>

                    <td>
                      <Button posClassName="col-lg-4" onClick={()=>{navigate("/schedule")}}>
                        Manage Classes
                      </Button>
                    </td>
                  </tr>
               
             <tr>

                          <td>
                          <Heading align="left" size={2}>Subscription</Heading>
                          </td>
                        <td>
                          
                            <div  className=" col-lg-6 p-3" style={{border: `3px solid ${themeC.mode== "chalk"? "white": "black"}`, borderRadius: "5px"}}>
                          {sub == "active"?

                          <>
                             <Heading  white size={3} style={{textDecoration:"underline"}}>
                              Eloise Student
                            </Heading>
                            <div className="text-center my-4">
                            <AppIcon  title="student" onClick={()=>{}}icon ={mdiSchool} size={2.5} />
                            </div>
                          </>

                          :
                          <>
                          <Heading  white size={3} style={{textDecoration:"underline"}}>
                           {sub !== "expired"? "Free Trial" : "Trial Expired"}
                         </Heading>
                         <div className="text-center my-4">
                         <AppIcon  title="student" onClick={()=>{}}icon ={sub !== "expired"? mdiSchool : mdiTimerCancel} size={2.5} />
                         </div>
                       </>
                      }

                          <Button onClick={sub == "active"? manage: subscribe}>{sub == "active"? "Manage Subscription": "Subscribe"}</Button>
                          </div>
                        </td>

             </tr> 
             
             </tbody>
              </Table>
              {sub == "expired" && <Heading size={3} >Your free trial has come to an end... Please subscribe to continue using Eloise Student only 6.99$ a month</Heading>}
             </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Profile, ProfileSmall1, ProfileSmall2, ProfileSmall3 };
