import React, { useEffect, useState } from "react";

import { Logic } from "../../functions";
import { useNavigate, useLocation } from "react-router";

import { OverviewSlide, SlideControl } from "../../styled";
import { AppIcon, Heading, Loading } from "../index";
import { mdiArrowLeftThick, mdiArrowRightThick } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";

// const Next = (props: { onClick: Function }) => {
//   return (
//     <SlideControl className="w3-display-right">
//       <AppIcon
//         title="back"
//         icon={mdiArrowRightThick}
//         onClick={() => {
//           props.onClick();
//         }}
//       ></AppIcon>
//     </SlideControl>
//   );
// };

// const Back = (props: { onClick: Function }) => {
//   return (
//     <SlideControl className="w3-display-left">
//       <AppIcon
//         title="back"
//         icon={mdiArrowLeftThick}
//         onClick={() => {
//           props.onClick();
//         }}
//       ></AppIcon>
//     </SlideControl>
//   );
// };

interface Slide {
  content?: any;
  title?:string;
  id: string
}

const Overview = (props: {
  course: string;
  topic: string;
  item?: any;
}) => {
  const location = useLocation();

  const errorHandler = async () => {
    console.log("errorHandler");
  };
  const permsHandler = async () => {
    navigate("/learn?sub");
  };

  const logic = new Logic(permsHandler, errorHandler, errorHandler);

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [sI, setSI] = useState<number>(0);
  const [slideData, setSlideData] = useState<any>([]);

  const [slides, setSlides] =  useState<Slide[]>(props.item.data.slides ?? [{id: "init"}])

  const handleSlideEdit = (slide: Slide, id: string) => {

   

    let newSlides = slides.map((x: any) =>{
        if( x.id !== id){
            return x
        }
        else{
            return slide
        }
    })

    setSlides(newSlides)




}

  const buttonHandler = (x: number) => {
    if (x === 0) {
      if (sI === 0) {
        setSlideData(data[data.length - 1]);
        setSI(data.length - 1);
      } else {
        setSlideData(data[sI - 1]);
        setSI(sI - 1);
      }
    } else {
      if (sI < data.length - 1) {
        console.log("next");
        setSlideData(data[sI + 1]);

        setSI(sI + 1);
      } else {
        setSlideData(data[0]);

        setSI(0);
      }
    }
  };


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">


           <div 
          className="col-lg-1"
          style={{
            paddingTop:"23vh"

        }}>

            <AppIcon
        title="back"
        icon={mdiArrowLeftThick}
        onClick={() => {
          buttonHandler(0);
        }}
        />
        </div>

        <OverviewSlide className="col-lg-9 ">
        
          <Heading size={3} white posClassName="pb-3">
            {slideData.title}
          </Heading>

          <Heading size={1} white>
            {slideData.text}
          </Heading>
        </OverviewSlide>
        <div   
              className="col-lg-1"
      style={{
          paddingTop:"23vh"
        }}>
        <AppIcon
        title="back"
        
        icon={mdiArrowRightThick}
        onClick={() => {
         buttonHandler(1);
        }}
        />
        </div>
        </div>
      )}{" "}
    </>
  );
};

export { Overview };
