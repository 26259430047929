import { Generic } from "./components";

import { lists } from "./lists";

import { fb } from "./firebase";
import { Eloise } from "./eloise";
import { Hooks } from "./hooks";

export class Logic {
  fb = fb;
  generic: Generic
  lists: { [key: string]: any } = lists;
  perms: Function
  other: Function
  auth: Function
  eloise: Eloise
  hooks = Hooks
  api = "https://us-central1-eloiselife-c5cf6.cloudfunctions.net/api"
  // api = "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/api";

  constructor(perms: Function, auth: Function, other: Function) {
    this.perms = perms
    this.other = other
    this.auth = auth

    this.generic = new Generic(this.perms, this.auth, this.other, this.api)
    this.eloise = new Eloise(this.perms, this.auth, this.other, this.api)
  }



}
