//Eloise Component
// Dependency Imports
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
//Component Imports
import {
  Chat,
  Input,
  FrostContext,
  Loading,
  Heading,
  ClassSelect,
  Button,
  Cal,
  Sub,
  Canvas,
} from "../index";
import { ThemeContext } from "../../App";
import { v4 as uuidv4 } from "uuid";
//Function Imports
import { Logic } from "../../functions";
import posTagger from "wink-pos-tagger";
import { interaction } from "../../functions/eloise";

const Eloise = (props: {
  done?: string;
  prompt?: string;
  special?: "testDate";
}) => {
  const navigate = useNavigate();

  const errorHandler = async () => {

    console.log("errorHandler")
  };
  const permsHandler = async () => {
    navigate("/?sub")
  }

  const logic = new Logic(permsHandler, errorHandler, errorHandler)

  const [mode, setMode] = useState<string>("normal");
  const [loading, setLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<string>("user")
  const [answer, setAnswer] = useState<string>(
    props.prompt ? props.prompt : ""
  );
  var tagger = posTagger();

  const location = useLocation();

  useEffect(() => {

    
    let userTest = localStorage.getItem("userData");
    if (userTest) {
      // Last Seen Stuff
      let parsed = JSON.parse(userTest);
      setFirst(parsed.firstName)
    }

    if (props.prompt) {
      setConvo([{ eloise: props.prompt }]);
    }

    let test = localStorage.getItem("currentConvo");

    if (test) {
      let parsed = JSON.parse(test);
      setConvo(parsed);
    }

    if (props.special == "testDate") {
      setUiMode("date");
    }
  }, []);



  const citeTest = (x: string) => { };



    const rememberHandler = async (x: string) => {
      let newAnswer = await logic.eloise.remember(query);
      setAnswer(newAnswer);
      setLoading(false);
    };

  const buzzwordsMap: { words: string[]; next: Function }[] = [
    {
      words: ["freestyle"],
      next: (query: string) => {
        setLoading(true);
        setQuery("");
        setTimeout(() => {
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          setMode("freestyle");
          setAnswer("alright you start us off");
          setConvo([
            ...convo,
            { human: query, eloise: "alright you start us off" },
          ]);
          localStorage.setItem(
            "currentConvo",
            JSON.stringify([
              ...convo,
              { human: query, eloise: "alright you start us off" },
            ])
          );
          setLoading(false);
        }, 2000);
      },
    },
    {
      words: ["learn", "explain", "teach", "show"],
      next: () => navigate("/learn"),
    },
    {
      words: ["write", "paragraph", "essay", "page", "pages", "paragraphs"],
      next: () => {
        console.log("wrute")
        navigate("/write")},
    },
    {
      words: ["profile", "settings", "account"],
      next: () => navigate("/profile"),
    },
    { words: ["remember", "recall"], next: () => { } },
    { words: ["game"], next: () => navigate("/game") },
    { words: ["cite"], next: citeTest },
    {words: ["remember", "recall"], next: ()=>{rememberHandler(query)}}
  ];

  const buzzwords = buzzwordsMap
    .map((x) => {
      return x.words;
    })
    .flat();


const checkBuzz = (query:string) => {

      let queryAsList = query.split(" ");
  
      if (queryAsList.length > 1) {
        let x = queryAsList.map((el) => {
        if (buzzwords.includes(el)) {

            let buzz = buzzwordsMap.find((x) => x.words.includes(el));

            if (buzz ) {  
              return buzz
            }
            else{
              return false
            }
      
          }
         
        });
        
         return x.filter((y:any)=> y!== undefined)[0]
      }
      else {
        if (buzzwords.includes(query)) {
          let buzz = buzzwordsMap.find((x) => x.words.includes(query));
          if (buzz) {
            return buzz
          }else{
            return false
          }
        }
      }


    }

const normalHandler = async (query: string) =>{

      let data: { [key: string]: any } = {}

      let posTest = tagger.tagSentence(query);


      console.log(posTest);
      posTest = posTest.map((x: any) => {
        return { word: x.value, tag: x.pos };
      });

      let personTest = posTest.filter((x: any) => ["NNP", "NN"].includes(x.tag))
        .map((x: any) => x.word)

      if (personTest.length > 0) {
        console.log(personTest);
        data["nouns"] = personTest;
      }



      let test;
      if (mode === "freestyle") {
        test =
          freeConvo.length > 0
            ? await logic.eloise.freeStyle(query, freeConvo)
            : await logic.eloise.freeStyle(query);

        setFreeConvo([...freeConvo, query, test]);
      }

      else {
        test =
          convo.length > 0
            ? await logic.eloise.coi(query, data, first, convo)
            : await logic.eloise.coi(query, data, first);
      }

      setConvo([...convo, { human: query, eloise: test }]);
      localStorage.setItem(
        "currentConvo",
        JSON.stringify([...convo, { human: query, eloise: test }])
      );

      setAnswer(test);
      setLoading(false);
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });

    }

  const queryHandler = async (query: string) => {
    //Handle Ui
    setLoading(true);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });



    switch (mode) { 

      case "normal":
        let buzz = checkBuzz(query)
              console.log(buzz)

        if(buzz){

          buzz.next(query)
        }
        else{
          await normalHandler(query)
        }


    }






  

  
    
  };
  const themeC = useContext(ThemeContext).theme;
  const bottomRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState<string>("Ask Me Anything");
  const [startDate, setStartDate] = useState<Date>();

  const [convo, setConvo] = useState<interaction[]>([]);
  const [freeConvo, setFreeConvo] = useState<interaction[]>([]);

  const onEnterHandler = (x: string, date?: boolean) => {
    if (date) {
      setStartDate(new Date(x));

      const bootstrapAsync = async () => {
        await logic.eloise.addTest(
          x,
          location.state.course,
          location.state.topic
        );
      };
      bootstrapAsync();
      if (props.done) navigate(props.done);
    } else {
      queryHandler(x);
    }
  };

  const [uiMode, setUiMode] = useState<"text" | "choice" | "date" | "class">(
    "text"
  );

   if (location.search === "?sub") {
    return <Sub />

   } 
  
  else {
    return (
      <>

        <div style={{pointerEvents:"none"}}>
        {convo.length > 0 ? (
          <Chat convo={convo} loading={loading} />
        ) : (
          <>        

            <div
              className={`col-lg-4 mx-auto  heart`}
              style={{
                color: themeC.mode == "chalk" ? "white" : "black",
                height: "30vh",
                fontSize: "8rem",
                textAlign: "center",
                width: "100%",
                fontFamily: "'Caveat', cursive",
                paddingTop: "30vh",
              }}
            >{`[ el ]`}</div>
          </>

        

        )}
        </div>
        {uiMode === "date" && (
          <div className="row ">
            <Cal next={onEnterHandler} />

            <div
              style={{
                position: "absolute",
                top: "70vh",
                width: "100%",
              }}
            >
              {" "}
              <Button
                posClassName="col-lg-6 mx-auto"
                borderRadius
                onClick={onEnterHandler}
              >
                Skip
              </Button>
            </div>
          </div>
        )}
        {uiMode === "text" && (
          <div
            style={{
              position: "absolute",
              top: "90vh",
              width: "100%",
            }}
          >
            <Input
              value={loading ? "" : undefined}
              primary
              className="text-center"
              posClassName="mx-auto col-9 "
              onEnter={onEnterHandler}
              label={query}
            />
          </div>
        )}
        {uiMode === "class" && <ClassSelect next={onEnterHandler} />}

      </>
    );
  }

}


export default Eloise;
