import React, {useState, useEffect} from "react";
import { Heading } from "..";





const Policy = ()=>{

const priv = [
`We at data26 , L.L.C. (together with our affiliates, “data26 ”, “we”, “our” or “us”) respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. This Privacy Policy describes our practices with respect to Personal Information we collect from or about you when you access Data 26 and its affiliates’ websites (collectively, the “Site”), or use Data 26 and its affiliates’ products and services, including application programming interfaces, associated software, tools, developer services, data and documentation (collectively, the “Services”).`
    ,`
1. PERSONAL INFORMATION WE COLLECT

We collect information that alone or in combination with other information in our possession could be used to identify you (“Personal Information”) as follows:

Personal Information You Provide: We may collect Personal Information if you create an account to use our Services or communicate with us as follows.

Communication Information: If you communicate with us, we may collect your name, contact information, and the contents of any messages you send (“Communication Information”).

Personal Information We Collect Through Our Social Media Pages: We have pages on social media sites like Instagram, Facebook, Medium, Twitter, YouTube and LinkedIn (“Social Media Pages”). When you interact with our Social Media Pages, we will collect Personal Information that you elect to provide to us, such as your contact details (“Social Information”). In addition, the companies that host our Social Media Pages may provide us with aggregate information and analytics regarding the use of our Social Media Pages.

Personal Information We Receive Automatically From Your Use of the Services: When you visit, use, and interact with the Services, we may receive certain information about your visit, use, or interactions (“Technical Information”), including the following:

Log data: Information that your browser automatically sends whenever you visit the Site (“log data”). Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interacted with the Site.

Usage data: We may automatically collect information about your use of the Services, such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, computer connection, IP address, and the like.

Device information: Includes name of the device, operating system, and browser you are using. Information collected may depend on the type of device you use and its settings.

Cookies: We use cookies to operate and administer our Site and Services, and improve your experience on it. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website. For more details on cookies please visit All About Cookies.

Analytics: We may use a variety of online analytics products that use cookies to help us analyze how users use the Site and Services and enhance your experience when you use the Site and Services.

Online Tracking and Do Not Track Signals: We and our third party service providers may use cookies or other tracking technologies to collect information about your browsing activities over time and across different websites following your use of the Site. Our Site currently does not respond to “Do Not Track” (“DNT”) signals and operates as described in this Privacy Policy whether or not a DNT signal is received.

`,`2. HOW WE USE PERSONAL INFORMATION

We may use Personal Information for the following purposes:

To provide, administer, maintain, improve and/or analyze the Services;
To conduct research, which may remain internal or may be shared with third parties, published or made generally available;
To communicate with you;
To develop new programs and services;
To prevent fraud, criminal activity, or misuses of our Services, and to ensure the security of our IT systems, architecture, and networks; and
To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.
Aggregated Information. We may aggregate Personal Information and use the aggregated information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research (which may remain internal or may be shared with third parties, published or made generally available) and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy.

`,`3. SHARING AND DISCLOSURE OF PERSONAL INFORMATION

In certain circumstances we may share your Personal Information with third parties without further notice to you, unless required by the law, including without limitation in the situations below:

Vendors and Service Providers: To assist us in meeting business operations needs and to perform certain services and functions, we may share Personal Information with vendors and service providers, including providers of hosting services, cloud services, and other information technology services providers, event management services, email communication software and email newsletter services, advertising and marketing services, and web analytics services. Pursuant to our instructions, these parties will access, process, or store Personal Information in the course of performing their duties to us.
Business Transfers: If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively a “Transaction”), your Personal Information and other information may be shared in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.
Legal Requirements: If required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, including to meet national security or law enforcement requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in urgent circumstances to protect the personal safety of users of the Services, or the public, or (v) protect against legal liability.
Affiliates: We may share Personal Information with our affiliates, meaning an entity that controls, is controlled by, or is under common control with Data 26. Our affiliates may use the Personal Information we share in a manner consistent with this Privacy Policy.
Other Users: certain actions you take may be visible to other users of the Service.
`,`4. HOW TO CONTACT US; UPDATING YOUR INFORMATION

Please contact us at support@eloise.life if you need to change or correct your Personal Information, or if you have any questions or concerns not already addressed in this Privacy Policy.

`,`5. CALIFORNIA PRIVACY RIGHTS

The following disclosures are intended to provide additional information about (1) the categories of Personal Information we collect (as defined above), (2) the source of the Personal Information, (3) how we use each category of Personal Information, and (4) how we disclose Personal Information. These disclosures do not limit our ability to use or disclose information as described above.

Category of Personal Information	Sources of Personal Information	Use of Personal Information	Disclosure of Personal Information
Social Information	We may collect Social Information from you when you interact with our Social Media Pages.	We may use Social Information to perform analytics and to communicate with you.	We may disclose Social Information to our affiliates.
Communication Information	We collect Communication Information directly from you.	We use Communication Information for providing our Services and responding to you.	We disclose Communication Information to our affiliates and communication services providers.
Technical Information	We collect Technical Information from you.	We use Technical Information for analytics and in some cases, for moderation and prevention of fraud and malicious activity by users of our Services.	We disclose Technical Information to our affiliates and analytics provider(s).
California Privacy Rights

To the extent provided for by law and subject to applicable exceptions, California residents have the following privacy rights in relation to the Personal Information we collect:

The right to know what Personal Information we have collected and how we have used and disclosed that Personal Information;

The right to request deletion of your Personal Information; and

The right to be free from discrimination relating to the exercise of any of your privacy rights.

We do not and will not sell your Personal Information.

Exercising Your Rights: California residents can exercise the above privacy rights by emailing us at: support@eloise.life.

Verification: in order to protect your Personal Information from unauthorized access or deletion, we may require you to verify your credentials before you can submit a request to know or delete Personal Information. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional Personal Information and proof of residency for verification. If we cannot verify your identity, we will not provide or delete your Personal Information.

Authorized Agents: you may submit a request to know or a request to delete your Personal Information through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf and you may also be required to independently verify your identity and submit proof of your residency with us.

`,`6. CHILDREN

Our Service is not directed to children who are under the age of 13. Data 26 does not knowingly collect Personal Information from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to Data 26 through the Service please email us at support@eloise.life and we will endeavor to delete that information from our databases.

`,`7. LINKS TO OTHER WEBSITES

The Service may contain links to other websites not operated or controlled by Data 26, including social media services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.

`,`8. SECURITY

You use the Service at your own risk. We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or e-mail. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third party websites.

`,`9. INTERNATIONAL USERS

By using our Service, you understand and acknowledge that your Personal Information will be transferred from your location to our facilities and servers in the United States.

`,`10. YOUR CHOICES

If you choose not to provide Personal Information that is needed to use some features of our Service, you may be unable to use those features.

`,`11. CHANGES TO THE PRIVACY POLICY

We may change this Privacy Policy at any time. When we do we will post an updated version on this page, unless another type of notice is required by applicable law. By continuing to use our Service or providing us with Personal Information after we have posted an updated Privacy Policy, or notified you by other means, you consent to the revised Privacy Policy.


`]

const terms =[ `
TERMS OF USE FOR DATA 26 LLC ONLINE LEARNING TOOL FOR STUDENTS

`,`1. Acceptance of Terms:

By using the Data 26 LLC Online Learning Tool for Students (the “Service”), you signify your agreement to these terms and conditions (the “Terms of Use”). Data 26 LLC reserves the right, in its sole discretion, to modify, alter or otherwise update these Terms of Use at any time. Such modifications shall become effective immediately upon the posting thereof. By continuing to use the Service after the posting of such modifications, alterations or updates, you agree to be bound by such modifications, alterations or updates.

`,`2. Description of Service:

Data 26 LLC provides an online learning platform for students to improve their knowledge and skills. The Service includes access to online courses, tutorials, tests, and other resources.

`,`3. User Conduct:

You agree to use the Service only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the Service by any third party.

`,`4. User Generated Data:

Data 26 LLC reserves the right to use the data generated by users of the Service for any purpose, including, but not limited to, research and marketing.

`,`5. Intellectual Property:

The Service and its content are protected by copyright, trademark, patent, trade secret and other laws, and Data 26 LLC and its licensors own and retain all rights in the Service and its content.

`,`6. Disclaimer of Warranties:

Data 26 LLC makes no representations or warranties regarding the quality, accuracy, reliability, completeness, or timeliness of the Service. The Service is provided on an “as-is” and “as available” basis.

`,`7. Limitation of Liability:

Data 26`]



    return(
    <div className="mt-5 row">
        <Heading size={3}>Privacy Policy</Heading>
        {priv.map((x:string)=>  <Heading size={1} posClassName="col-lg-9 mx-auto " align="left" >{x}</Heading>)}

        <Heading size={3}>Terms of Use</Heading>
        {terms.map((x:string)=>  <Heading size={1} posClassName="col-lg-9 mx-auto " align="left" >{x}</Heading>)}
     
    </div>)
}

export {Policy}