import React, { useState, useEffect, useContext } from "react";
import { mdiLockOutline, mdiBrain, mdiAllInclusive, mdiSchool,  mdiProfessionalHexagon ,mdiCellphone, mdiAlert, mdiGoogleSpreadsheet } from "@mdi/js";
import Icon from "@mdi/react";
import { v4 as uuidv4 } from "uuid";
import { Logic } from "../../functions";
import { useNavigate, useLocation } from "react-router";
import { Heading,  AppIcon, ClearButton, Haiku, Life, Input, Button } from "../blocks";
import { ClassPage, Loading, SignUp } from "../widgets";
import { Doodle } from "../widgets/doodle";
import { ClassMode } from "./class";
import { Row } from "react-bootstrap";
import theme from "../../theme";
import axios from "axios";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { FlashCard } from "./flash";
import { ThemeContext } from "../../App";


const SignUpSmall1 = (props:{}) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [ data, setData] = useState<any>()
  const [answer, setAnswer]= useState<string>("Check your inbox for a link to verify your email address, Once you have verified click the button below")
 const navigate = useNavigate();

  const errorHandler = async () => {

    console.log("errorHandler")
  };
  const permsHandler = async () => {
    navigate("/?sub")
  }

  const logic = new Logic(permsHandler, errorHandler, errorHandler)



  useEffect(()=>{


    const parseData = (toUse: any) => {
      let newData = toUse.doodles.concat(toUse.haikus,toUse.flash, [{game:true},{game:true},{game:true},{game:true},{game:true}])

              setData(newData.sort( () => Math.random() - 0.5))
      }



      const bootstrapAsync2 = async () => {

        let data = await logic.eloise.signUpFill()
  
        localStorage.setItem("signUpFillData", JSON.stringify(data))
        parseData(data)

      }

      let test = localStorage.getItem("signUpFillData")
      if (!test) {
            bootstrapAsync2()

      }
      else{
        let tempData = JSON.parse(test)
        parseData(tempData)
      }
   
    
  

  },[])



  return (
    <div className="mx-auto">

    {data && data.map((x:any)=>

      <div key={uuidv4()} className="row my-5">
    
    
    {x.deck? 
          <div className="text-center mx-auto">
          <FlashCard key={uuidv4()} card={x.deck.map((x:any)=>{
            return [Object.keys(x)[0], Object.values(x)[0]]
        })[0]} />
        </div>
      :
      <>
      {x.game? <Life  key={uuidv4()}/> :
      <>
      
      {x.text? 
         <Haiku key={uuidv4()} text={x.text}/>
      
      :
           <Doodle  key={uuidv4()} doodle={x} />
      }
      </>
       }
     </>
      }
  
      </div>
    )}
    
           
         
     
    </div>
  );
    
};


const SignUpSmall2 =  (props:{mode:string, data?:any}) => {

  const navigate = useNavigate();
 
   const errorHandler = async () => {
 
     console.log("errorHandler")
   };
   const permsHandler = async () => {
     navigate("/?sub")
   }
 
   const logic = new Logic(permsHandler, errorHandler, errorHandler)
 
 
   
   return (
     <>
 
            
          
      
     </>
   );
};




const SignUpSmall3 = (props:{  done:Function}) => {

  const location = useLocation();
  const navigate = useNavigate()
  
  const errorHandler = async () => {

    console.log("errorHandler")
  };
  const permsHandler = async () => {
    navigate("/?sub")
  }

  const logic = new Logic(permsHandler, errorHandler, errorHandler)
  const themeC = useContext(ThemeContext).theme
  
  let oldData: any = localStorage.getItem("signUpData");
  if (oldData) {
    oldData = JSON.parse(oldData);
  }

  const [email, setEmail] = useState<string>(
    oldData ? oldData.email : undefined
  );
  const [emailError, setEmailError] = useState<string>();

  const [password, setPassword] = useState<string>(
    oldData ? oldData.password : undefined
  );
  const [passwordError, setPasswordError] = useState<string>();
  const [passConfError, setPassConfError] = useState<string>();

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<string>(
    oldData ? oldData.firstName : undefined
  );
  const [last, setLast] = useState<string>(
    oldData ? oldData.lastName : undefined
  );
  const [country, setCountry] = useState<string>(
    oldData ? oldData.country : undefined
  );
  const [passConf, setPassConf] = useState<string>(
    oldData ? oldData.confirmPassword : undefined
  );
  const [phone, setPhone] = useState<string>(
    oldData ? oldData.phone : undefined
  );
  const [username, setUsername] = useState<string>(
    oldData ? oldData.username : undefined
  );
  const [grade, setGrade] = useState<string>(
    oldData ? oldData.grade : undefined
  );

  const [school, setSchool] = useState<string>(
    oldData ? oldData.school : undefined
  );


  const [verified, setVerfied] = useState<boolean>()
  const verify = () => {
    let tests = [
      email ? true : false,
      password ? true : false,
      passConf ? true : false,
      first ? true : false,
      last ? true : false,
      country ? true : false,
      phone ? true : false,
      username ? true : false,
      school ? true : false,
      grade ? true : false
    ];
    return !tests.includes(false);
  };

  useEffect(() => {

    setVerfied(verify())

  },[passConf, username, phone, last, first, email, country])

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: Function
  ) => {
    const enteredName = event.target.value;
    setter(enteredName);
  };  
  const [usernameError, setUsernameError] = useState<string>();

  const login = async(email: string, password: string) => {
    let x = await signInWithEmailAndPassword(logic.fb.auth, email, password);


  };

  const signUpSubmit = () => {
    setLoading(true);
    const bootstrapAsync = async () => {



        let parsed = {
          name: location.state.data.preName,
          educationalLevel: location.state.data.eduLevel,
          event:{
            date:  location.state.data.date,
            course:  location.state.data.course,
            topic:  location.state.data.topic
        }
        }

      const userData = {
        ...parsed,
        email: email,
        password: password,
        firstName: first,
        lastName: last,
        country: country,
        phoneNumber: phone,
        confirmPassword: passConf,
        username: username,
        grade: grade,
        school: school
      };


      localStorage.setItem("signUpData", JSON.stringify(userData));

      axios
        .post(`${logic.api}/signup`, userData)
        .then(async(response: any) => {
          setLoading(false);
          localStorage.setItem("signUpData", JSON.stringify(userData))
          let x = await signInWithEmailAndPassword(logic.fb.auth, email, password);
          navigate("/signup", {state: {signUpMode: "watch"}})

        })
        .catch((e: any) => {
          setLoading(false);

          let error: { [key: string]: string } = e.response.data;

          let type = Object.keys(error)[0];
          let message: string = Object.values(error)[0];
          console.log(type)
          switch (type) {
            case "passwordConfirm":
              setEmailError(undefined);
              setUsernameError(undefined);
              setPassConfError(undefined);

              setPasswordError(message);
              break;
            case "email":
              setPasswordError(undefined);
              setUsernameError(undefined);
              setPassConfError(undefined);

              setEmailError(message);
              break;
            case "username":
              setPasswordError(undefined);
              setPassConfError(undefined);

              setEmailError(undefined);
              setUsernameError(message);
              break;
            case "confirmPassword":
              setPassConfError(message);
              setUsernameError(undefined);
              setPasswordError(undefined);

          }
        });
      
    };
    bootstrapAsync();
  };

  return (
    <div className="mt-5">
      {loading? <Loading /> :
      <>
      <Heading size={5} handWritten style={{textDecoraction:"underline"}}>Account Set Up</Heading>

     <Row className="pt-5">
              <Input
                className="text-center"
                primary
                label="First Name"
                value={first}
                posClassName="col-lg-5 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setFirst);
                }}
              />
              <Input
                className="text-center"
                primary
                value={last}
                label="Last Name"
                posClassName="col-lg-5 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setLast);
                }}
              />
            </Row>
            <Row>
              <Input
                className="text-center"
                primary
                value={phone}
                label="Phone Number"
                posClassName="col-lg-5 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setPhone);
                }}
              />
              <Input
                className="text-center"
                primary
                value={country}
                label="Country"
                posClassName="col-lg-5 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setCountry);
                }}
              />
            </Row>
            {emailError && (
              <Row>
                <div className="mx-auto text-center">
                  <p
                    style={{
                      fontSize: theme.relFontSize,
                      fontWeight: "700",
                      fontStyle: "italic",
                      marginTop: "10px",
                      color:themeC.mode=="chalk"? "white": "black"

                    }}
                  >
                    {" "}
                    <Icon
                      path={mdiAlert}
                      className="col-lg-4 mx-4 "
                      title="User Profile"
                      size={1}
                      color={themeC.mode=="chalk"? "white": "black"}
                    />
                    {emailError}
                  </p>
                </div>
              </Row>
            )}
            <Row>
              <Input
                value={email}
                className="text-center"
                primary
                label="Email"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setEmail);
                }}
              />
            </Row>
            {usernameError && (
              <Row>
                <div className="mx-auto text-center">
                  <p
                    style={{
                      fontSize: theme.relFontSize,
                      fontWeight: "700",
                      fontStyle: "italic",
                      marginTop: "10px",
                      color:themeC.mode=="chalk"? "white": "black"

                    }}
                  >
                    {" "}
                    <Icon
                      path={mdiAlert}
                      className="col-lg-4 mx-4 "
                      title="User Profile"
                      size={1}
                      color={themeC.mode=="chalk"? "white": "black"}
                    />
                    {usernameError}
                  </p>
                </div>
              </Row>
            )}
            <Row>
              <Input
                value={username}
                className="text-center"
                primary
                label="Username"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setUsername);
                }}
              />
            </Row>
            <Row>
              <Input
                className="text-center"
                primary
                value={password}
                type="password"
                label="Password"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setPassword);
                }}
              />
            </Row>

            {passwordError && (
              <Row>
                <div className="mx-auto text-center">
                  <p
                    style={{
                      fontSize: theme.relFontSize,
                      fontWeight: "700",
                      fontStyle: "italic",
                      marginTop: "10px",
                      color:themeC.mode=="chalk"? "white": "black"

                    }}
                  >
                    {" "}
                    <Icon
                      path={mdiAlert}
                      className="col-lg-4 mx-4 "
                      title="User Profile"
                      size={1}
                      color={themeC.mode=="chalk"? "white": "black"}
                    />
                    {passwordError}
                  </p>
                </div>
              </Row>
            )}
            <Row>
              <Input
                className="text-center"
                primary
                value={passConf}
                type="password"
                label="Confirm Password"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setPassConf);
                }}
              />
            </Row>
            {passConfError && (
              <Row>
                <div className="mx-auto text-center">
                  <p
                    style={{
                      fontSize: theme.relFontSize,
                      fontWeight: "700",
                      fontStyle: "italic",
                      marginTop: "10px",
                      color:themeC.mode=="chalk"? "white": "black"

                    }}
                  >
                    {" "}
                    <Icon
                      path={mdiAlert}
                      className="col-lg-4 mx-4 "
                      title="User Profile"
                      size={1}
                      color={themeC.mode=="chalk"? "white": "black"}
                    />
                    {passConfError}
                  </p>
                </div>
              </Row>
            )}

            <Row>
              <Input
                className="text-center"
                primary
                value={school}
              
                label="School"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setSchool);
                }}
              />
            </Row>
            <Row>
              <Input
                className="text-center"
                primary
                value={grade}
              
                label="Grade/Year"
                posClassName="col-lg-11 mx-auto my-3"
                onChange={(e: any) => {
                  inputHandler(e, setGrade);
                }}
              />
            </Row>
            <Row>
              {verified && <Button onClick={signUpSubmit} posClassName="col-lg-6  mt-4 mx-auto" borderRadius >Create Account</Button>}
            </Row>
           </> }
    </div>
  );
};
export { SignUpSmall1, SignUpSmall2, SignUpSmall3 };
