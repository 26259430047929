// Study Display Mode
// Dependency Imports
import React, { useState, useEffect, useRef } from "react";

//Component Imports
import { Heading,Chat,  Input, Loading, ClearButton, ClassSelect } from "../";
import { Logic } from "../../functions";
import { useNavigate } from "react-router";
import { ChatMessage } from "../../styled";
import { interaction } from "../../functions/eloise";


const Write = (props: {item?:any, mode?: string}) => {


  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const { domRef } = logic.generic.useFocus();
  const navigate = useNavigate();

  const [query, setQuery] = useState<string>();
  const [answer, setAnswer] = useState<string>("New Doc?");

  const [loading, setLoading] = useState<boolean>(false);

  const [classBool, setClassBool] = useState<boolean>(true);
  const [course, setCourse] = useState<string>();

  const [type, setType] = useState<string>();
  const [length, setLength] = useState<string>();
  const [topic, setTopic] = useState<string>();

  const [ui, setUi] = useState<boolean>(false);

  const [ops, setOps] = useState<boolean>(false);

  const [progression, setProgression] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [specific, setSpecific] = useState<string>()

  const [convo, setConvo ]= useState<interaction[]>([{eloise:"What class are we working on?"}]);


  const onEnterHandler = (x: string, overRide?: number) => {
    setLoading(true);
    const bootstrapAsync = async () => {
    
        setLoading(true);

        switch (overRide ? overRide : progression) {
          case 0:
            setCourse(x);
            setClassBool(false);
            setUi(true);
            setAnswer("What do you need to write?");
            setProgression(1);
            setTimeout(() => {
              setLoading(false);
            }, 750);
            domRef?.current?.focus();

            break;
          case 1:
            setType(x);
            setAnswer(
              "How long should it be? (paragraphs, words, sentences, etc...)"
            );
            setProgression(2);
            setTimeout(() => {
              setLoading(false);
            }, 750);
            domRef?.current?.focus();

            break;
          case 2:
            let test = await logic.eloise.adderall(
              x,
              `an amount of something you can write`,
              ["3 sentences", "10 lines", "1 page", "3 stanzas", "2 paragraphs"]
            );

            if (test !== "n") {
              setLength(x);
              domRef?.current?.focus();
              setProgression(3);

              setAnswer("What should it be about?");
              setLoading(false);
            } else {
              setTimeout(() => {
                setLoading(false);
              }, 750);
              domRef?.current?.focus();

              setAnswer(
                "can you try that again? I am looking for something like 3 paragraphs or 15 sentences... "
              );
            }
            break;
            case 3:
              setTopic(x)
              setAnswer("Finally give this a name to find it later")
              setProgression(4)
              setTimeout(()=>{setLoading(false)}, 750)

              break;
          case 4:
            setSpecific(x);
            setOps(true);
            setProgression(5);
            setLoading(false);
  
            localStorage.setItem(
              "writeItem",
              JSON.stringify({
                class: course,
                test:"terst",
                data:{
                display: { type: type, topic: topic, length: length },
                name: x
                }
              })
            );
            navigate("/collab");
            break;
        
      }
    };
    bootstrapAsync();
  };


  useEffect(() => {
    let test = localStorage.getItem("newDoc");
    if (test !== null) {
      let parsed = JSON.parse(test);
      setProgression(4);

      setTopic(parsed.topic);
      setCourse(parsed.course);
      setLength(parsed.length);
      setTopic(parsed.topic);
      onEnterHandler(parsed.writeMode, 4);
    } else {
      setProgression(0);
      setClassBool(true);
      setUi(false);
      setAnswer("What is next?");
    }
  }, [refresh]);
  return (
    <div>
      <div>
        <>
                
            
          <Chat convo={convo} loading={loading}/>

          {loading ? (
            <div>
              <Loading></Loading>
            </div>
          ) : (
            <div className="container mx-auto pt-5">
              <div className="row pt-3 pb-5">
                <>
                  {query && (
                    <Heading
                      align="left"
                      size={2}
                      white
                      posClassName="mb-3"
                    >{`Previous Question:  ${query}`}</Heading>
                  )}
                    
                </>
              </div>
              <div className="row mx-auto text-center mt-5">
                <>{classBool && <ClassSelect next={onEnterHandler} />}</>

                <>
                  {ui && (
                    <Input
                      backgroundColor="transparent"
                      primary
                      className="text-center"
                      posClassName="col-lg-6 mx-auto py-5"
                      ref={domRef}
                      // onChange={(e: any) => {
                      //    inputHandler(e, setQuery);
                      // }}
                      onEnter={onEnterHandler}
                      label={"The world at your fingertips..."}
                    />
                  )}
                </>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export { Write };
