// Study Display Mode
// Dependency Imports
import React, { useState, useEffect } from "react";

//Component Imports
import {
  Heading,
  Input,
  Loading, 
  ClassPage, ClassSelect,
  Quiz,
  Overview
} from "../";
import { Logic } from "../../functions";
import { useNavigate, useLocation } from "react-router";
import { ChatMessage } from "../../styled";


const Learn = (props:{ mode:string, item?: any, setMode: Function, toggleEl:Function, history:Function}) => {
    const navigate = useNavigate()


    const [oItem, setOItem]  = useState<any>()
    

  const errorHandler = async()=>{

    console.log("errorHandler")
  };
  const permsHandler = async()=>{
      console.log("permshandler")
  }

  const logic = new Logic(permsHandler, errorHandler, errorHandler)

  const location = useLocation()
  useEffect(() => {
    
    let testCourse = localStorage.getItem("learnCourse");
    if(testCourse){
      setCourse(testCourse);
    }

    let testItem = location.state

    if(testItem){

      let parsed:any = testItem.data
      setAnswer("Here you are")
      setMode("overview");
      setCourse(parsed.class)
      setTopic(parsed.topic)
      setUi(true)
      setUiLabel("any questions")
      setClassBool(false)
    }
      if(props.mode =="history"){
          setOItem(props.item)
      if(props.item.type==="overview"){

        setAnswer("Here you are")
        setMode("overview");
        if(props.item.loaded){
        setCourse(props.item.class)
        setTopic(props.item.topic)
        }
        setUi(true)
        setUiLabel("any questions")
        
        setClassBool(false)


      }else{
      setQuery(props.item.query)
      setAnswer(props.item.answer)
      setUi(true)
      setClassBool(false)
      }
     
     }
    
    else{
    if(props.mode == "newTopic" ){
      setClassBool(false)
      setUi(true)
      setUiLabel("something else...")
      setTopic(undefined)
      setMode("class")
      setAnswer("what's next'?")

    }
    if(props.mode == "newClass" ){
      setClassBool(true)
      setUi(false)
      setCourse(undefined)
      setUiLabel("something else...")
      setTopic(undefined)
      setMode("learn")
      setAnswer("what's next'?")

    }
    if(props.mode === "quiz"){
      setClassBool(false)
            setUi(false)
          
      setAnswer(undefined)
      setMode("quiz");
      
    }
    if(props.mode === "load"){
      setAnswer("Here you are")
      setMode("overview");
      if(props.item){
      setCourse(props.item.class)
      setTopic(props.item.topic)
      }
      setUi(true)
      setUiLabel("any questions")
      
      setClassBool(false)
    }
  }
  
  }, []);

  const [query, setQuery] = useState<string>();
  const [answer, setAnswer] = useState<string | undefined>("Which class are we studying for?");
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<string>();

  interface interaction {
    query: string;
    answer: string;
  }
  const [convo, setConvo] = useState<interaction[]>([]);
  const [ui, setUi] = useState<boolean>(false);
  const [uiLabel, setUiLabel] = useState<string>("Ask me a follow up question to get more info");
  const [classBool, setClassBool] = useState<boolean>(true);
  const [course, setCourse] = useState<string>()
  const [topic, setTopic] = useState<string>()


  const getOverview = (topic:string)=>{
      setQuery(undefined);
      setAnswer("Heres a few slides I threw together, Let me know if you have any questions")
      setTopic(topic);
      setMode("overview")
      setUi(true)
      setUiLabel("what else would you like to know")

  }
  



const onEnterHandler = (x:string)=>{

  if(mode=="overview"){

    const bootstrapAsync = async () =>{
        //! Really should write this
      // if(![course, topic, answer, x].includes(undefined)){
      //   let y= await logic.eloise.overviewFollowUp(course, topic, answer, x)

      //   if(y){
      //     setQuery(x)
      //     setAnswer(y)

      //   }
      // }

    }
    bootstrapAsync()

  }else{

  
  setCourse(x);
  localStorage.setItem("learnCourse", x)
  setClassBool(false)
  setAnswer("Here's a few topics I could think of, click one or type in your own")
  setMode("class")

  setUi(true)
  setUiLabel("something else...")
  }
}

 
  return (
    <div>
      <div>

        <>

          <div className="container mx-auto pt-5">
            <div className="row pt-3 pb-5">
              {loading ? (
                <div>
                  <Loading></Loading>
                </div>
              ) : (
                      <div className="col-lg-6 mx-auto" >
                      <ChatMessage message={{human:query, eloise:answer}}/>
                      </div>
              )}
            </div>
            <div className="row mx-auto text-center mt-1">

              {mode === "class" && <ClassPage next={getOverview} label="Study" back={() => { setCourse(undefined); props.setMode("learn") }} class={course ? course : "test"} />}
              {mode === "overview" && <Overview course={course ? course : ""}  topic={topic ? topic : ""} item={oItem? oItem :undefined} />}
              {mode === "quiz" && <Quiz course={course ? course : ""} history={props.history} topic={topic ? topic : ""} item={props.item? props.item :undefined} />}

              {ui &&
                <Input
                  backgroundColor="transparent"
                  primary
                  className="text-center"
                  posClassName="col-lg-6 mx-auto py-5"
                  // onChange={(e: any) => {
                  //     logic.generic.inputHandler(e, setQuery);
                  // }}
                  onEnter={onEnterHandler}
                  label={uiLabel}
                />}

              {classBool &&
                <ClassSelect next={onEnterHandler} />
              }


            </div>
          </div>

        </>

      </div>
    </div>
  );
};

export { Learn };
