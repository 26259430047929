import React, { useState, useEffect, useRef, useContext } from "react";
import { v4 as uuidv4} from "uuid";

import Icon from "@mdi/react";
import {
    mdiFileDocumentEditOutline,
    mdiBrain,
    mdiAccountMultipleOutline,
} from "@mdi/js";
import { mdiPresentationPlay as ppIcon } from "@mdi/js";
import { mdiFileDocumentEditOutline as writeIcon } from "@mdi/js";
import { mdiChairSchool as learnIcon } from "@mdi/js";
import { ClearButton, Heading, Input, Loading } from "..";
import { Back, MenuItem, StudyOpDiv, WriteMenu } from "../../styled";
import { useNavigate , useLocation} from "react-router";
import { Logic } from "../../functions";
import Form from 'react-bootstrap/Form'
import { ThemeContext } from "../../App";

const Collab = (props: { back: Function }) => {
    

    const errorHandler = async()=>{

        console.log("errorHandler")
      };
      const permsHandler = async()=>{

          navigate("/collab?sub")
      }
      const location = useLocation()

      const logic = new Logic(permsHandler, errorHandler, errorHandler)

    const navigate = useNavigate();
    const themeC = useContext(ThemeContext).theme



    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(true)
    const [answer, setAnswer] = useState<string>("Alrighty let's get started, I put together a potential outline you can use as a starting point")
    const [smallText, setSmallText] = useState<string>("Outline")
    const [outline, setOutline] = useState<any>(" ")
    const domRef = useRef(null)
    const [ui, setUi] = useState<boolean>(false)
    const [uiText, setUiText] = useState<string>("")
    const [smallLoading, setSmallLoading] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<boolean>(false)
    const [writing, setWriting] = useState<string>("")
    const [taskNumber, setTaskNumber] = useState<number>(0)

    const backHandler = () => {

        localStorage.removeItem("newDoc")
        localStorage.removeItem("currentCollabWriting")
        localStorage.removeItem("currentOutline")
        localStorage.removeItem("writeItem")
        navigate("/write")

    }



    const onEnterHandler = (x: string) => {
        const bootstrapAsync = async () => {


            switch (taskNumber) {
                case 0:


                    setSmallLoading(true)
                    setAnswer("Ok let me think...")
                    let ots = {
                        type: data.type,
                        length: data.length,
                        topic: data.topic,
                        course: data.course,
                        existing: writing,
                        instruction: x
                    }
                    let y = await logic.eloise.writeNext(ots)

                    if (y) {
                        let z = `${writing} \n ${y}`
                        setWriting(z)
                        setSmallLoading(false)
                        setUi(false)
                        setAnswer("How about this?")

                    }
                    break;
                case 1:
                    setSmallLoading(true)
                    setAnswer("Ok let me think...")
                    let ots2 = {
                        type: data.type,
                        length: data.length,
                        topic: data.topic,
                        course: data.course,
                        existing: writing,
                        instruction: x
                    }
                    let y2 = await logic.eloise.rewrite(ots2)

                    if (y2) {

                        setWriting(y2)
                        setSmallLoading(false)
                        setUi(false)
                        setAnswer("Ok just a sec")

                    }

                    break;
            }


        }
        bootstrapAsync()
    }


    useEffect(() => {
        if (writing) {
            localStorage.setItem("currentCollabWriting", JSON.stringify(writing))
        }

    }, [writing])


    const buttonHandler = (x: number) => {

        const bootstrapAsync = async () => {

            switch (x) {
                case 0://take the wheel
                    setUi(true)
                    setFeedback(false)
                    setTaskNumber(0)
                    setUiText("a paragraph... 2 sentences")
                    setAnswer("How much should I write?")
                    break;
                case 1://feedback
                    setSmallLoading(true)
                    let ots = {
                        type: data.type,
                        length: data.length,
                        topic: data.topic,
                        course: data.course,
                        existing: writing,

                    }
                    let feedback = await logic.eloise.writeFeedback(ots)
                    if (feedback) {
                        setFeedback(true)

                        setAnswer(feedback)
                        setSmallLoading(false)
                    }
                    break;
                case 2://Rewrite
                    setFeedback(false)
                    setUi(true)
                    setTaskNumber(1)
                    setUiText("make it smarter... use better vocab")
                    setAnswer("how would you like to change it?")


                    break;
                case 3://Spelling and Grammer
                    setFeedback(false)
                    setSmallLoading(true)
                    let ots2 = {
                        type: data.type,
                        length: data.length,
                        topic: data.topic,
                        course: data.course,
                        existing: writing,
                        instruction: "correct spelling and grammer"

                    }
                    let y3 = await logic.eloise.editWrite(ots2)
                    if (y3) {
                        setWriting(y3)
                        setAnswer("Done")
                        setSmallLoading(false)
                    }

                    break;
                case 4://Save
                    setFeedback(false)
                    setSmallLoading(true)
                    let ots3 = {
                        ...data,
                       
                        data: {...data.data, id: data.data.id? data.data.id : uuidv4(),
                             answer: writing,

                        outline:outline},
                        app:"write"
                    }
                    setData(ots3)
                    let test = await logic.eloise.addWriting(ots3)

                    if (test) {
                        await logic.generic.updateActivity(ots3)
                        setAnswer("Saved")
                        setSmallLoading(false)

                    }

                    break;


            }
        }

        bootstrapAsync()
    }

    useEffect(() => {
        console.log(props)
        const bootstrapAsync = async () => {

            setLoading(true)

            let writeItem = localStorage.getItem("writeItem")

            if (writeItem) {


                let parsed = JSON.parse(writeItem)
                setData(parsed)
                if (parsed.data.answer) {
                    setWriting(parsed.data.answer)
                    setAnswer("Lets get to it")
                }

                if (parsed.data.outline) {
                    setOutline(parsed.data.outline)
                    setLoading(false)
                }
                else {
                    let outline = await logic.eloise.writeOutline({ course: parsed.data.class, ...parsed.data.display })
                    setOutline(outline)
                    localStorage.setItem("writeItem", JSON.stringify({...parsed , data:{...parsed.data, outline: outline}}))
                    setLoading(false)
                }





            }
        }

        if(location.search !== "?sub"){
            bootstrapAsync();
              }
      

    }, [])

    const handleChange = (event: any) => {
        setWriting(event.target.value);
    };


    return (<div

    >

        <Back onClick={backHandler} />

        {loading ? <Loading /> :
            <div

                className="container-fluid mx-auto">


                <div className="row pt-3 pb-3">
                    {feedback ? <Heading size={1} white posClassName="pb-4">{answer}</Heading> :
                        <Heading size={2} white posClassName="pb-4">
                            {answer}
                        </Heading>}
                </div>
                <div className="row">
                   


                    <div className={ui ? "col-lg-6" : "col-lg-9"}>
                    <Heading size={2} white>{data.data.name}</Heading>

                        <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control className="writeBox" as="textarea" style={{ height: "71vh", border: "4px solid black"}} name="address" value={writing} onChange={handleChange} />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className={ui ? "col-lg-6" : "col-lg-3"}>



                        {smallLoading ? <Loading /> :
                            <>

                                {ui ? (
                                    <Input
                                        backgroundColor="transparent"
                                        primary
                                        className="text-center"
                                        posClassName="col-lg-6 mx-auto py-5"
                                        ref={domRef}
                                        // onChange={(e: any) => {
                                        //    inputHandler(e, setQuery);
                                        // }}
                                        onEnter={onEnterHandler}
                                        label={uiText}
                                    />
                                ) : <>

                                    <div className="row">
                                        <Heading size={2} white>{smallText}</Heading>
                                        {/* <pre style={{
                                            color: themeC.mode == "chalk"? "white": "black",
                                            
                                        }}>{`${outline.split(".").join("\n")}`}</pre> */}

                            <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control className="writeBox" as="textarea" style={{ height: "40vh",  border: "4px solid black"}} name="address" value={outline} onChange={handleChange} />
                            </Form.Group>
                        </Form>
                                    </div>

                                    <div
                                            style={{
                                          
                                            }}
                                            className="pt-2">
                                            <Heading size={2} white>Tools</Heading>

                                            <ClearButton
                                                border
                                                posClassName="py-1 col-lg-12 mx-auto"
                                                borderRadius
                                                onClick={() => { buttonHandler(0) }}
                                                padding="0"

                                            >
                                                Auto Pilot
                                            </ClearButton>

                                            <ClearButton
                                                border
                                                padding="0"

                                                posClassName="py-1 col-lg-12 mx-auto"
                                                borderRadius
                                                onClick={() => { buttonHandler(1) }}

                                            >
                                                Feedback
                                            </ClearButton>

                                            <ClearButton
                                                border
                                                padding="0"

                                                posClassName="py-1 col-lg-12 mx-auto"
                                                borderRadius
                                                onClick={() => { buttonHandler(3) }}

                                            >
                                                Spelling/Grammar
                                            </ClearButton>
                                            <ClearButton
                                                border
                                                padding="0"

                                                posClassName="py-1 col-lg-12 mx-auto"
                                                borderRadius
                                                onClick={() => { buttonHandler(2) }}
                                            >
                                                Rephrase
                                            </ClearButton>

                                            <ClearButton
                                                border
                                                padding="0"
                                                posClassName="py-1 col-lg-12 mx-auto"
                                                borderRadius
                                                onClick={() => { buttonHandler(4) }}

                                            >
                                                Save
                                            </ClearButton>
                                        </div>
                                </>}

                            </>}
                    </div>

                </div>
            </div>}
    </div>)
}



export { Collab }