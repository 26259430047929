import { initializeApp } from 'firebase/app';
import{ getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

import {config} from '../config';


const app = initializeApp(config);
const db = getFirestore(app);
const auth = getAuth(app)

const fb = {
    app:app,
    db:db,
    auth:auth
}

export {fb}


