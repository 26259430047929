import React, { useState, useEffect, useContext, useRef, useCallback } from "react";

import { Logic } from "../../functions";
import { throttle } from 'lodash';

import { Overview, Timeline } from "../widgets";
import { HistoryItem, StudyOpDiv, OverviewSlide } from "../../styled";
import { AppIcon, Button, ConfirmationModal, DateSelect, Flash, Heading, Input } from "..";
import { ThemeContext } from "../../App";
import Icon from "@mdi/react";
import {
    mdiFileQuestion,
    mdiReorderHorizontal,
    mdiPresentationPlay,
    mdiCards,
    mdiCalendarRange,
    mdiFileDocumentEditOutline,
    mdiArrowLeftThick,
    mdiArrowRightThick,
    mdiBrain,
    mdiFormatListBulleted,
    mdiPlus,
    mdiSort,
    mdiArrowExpand,
    mdiArrowCollapse,
    mdiClose,
    mdiRecordCircleOutline,
    mdiPencil
} from "@mdi/js";
import { Form, InputGroup } from "react-bootstrap";
import { Note, noteItem } from "../../AppContent";
import { v4 as uuidv4 } from "uuid"
import { useLocation } from "react-router-dom";
import { NoteWidgetRenderSwitch } from "../modes/event";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FlashCard } from "../modes/flash";


const errorHandler = async () => { };
const logic = new Logic(errorHandler, errorHandler, errorHandler);



const Op = (props: {
    icon: string;
    title: string;
    className?: string;
    onClick: any;
    size?: number;
    inverse?: boolean;
    titleHide?: boolean;
}) => {
    const themeC = useContext(ThemeContext).theme;

    return (
        <StudyOpDiv
            className={props.className ? props.className : ""}
            style={{ textAlign: "center" }}
            onClick={props.onClick}
        >
            {!props.titleHide && (
                <Heading
                    size={2}
                    style={{
                        color: props.inverse
                            ? themeC.mode === "chalk"
                                ? "black"
                                : "white"
                            : themeC.mode === "chalk"
                                ? "white"
                                : "black",
                    }}
                >
                    {props.title}
                </Heading>
            )}
            <Icon
                path={props.icon}
                title={props.title}
                size={props.size ?? 3}
                color={
                    props.inverse
                        ? themeC.mode === "chalk"
                            ? "black"
                            : "white"
                        : themeC.mode === "chalk"
                            ? "white"
                            : "black"
                }
            />
        </StudyOpDiv>
    );
};

const StudyOps = (props: {
    changeMode: Function;
    handleDelete: Function
    flash?: boolean;
    list?: boolean;
    quiz?: boolean;
    date?: boolean;
    text?: boolean;
    slides?: boolean;
    enlarge?: boolean;
    auto?: boolean;
    size?: number;
    plus?: boolean;
    editSmall?: boolean;
    titleHide?: boolean;
    inverse?: boolean;
    kidClass?: string;
    edit?: boolean;
}) => {
    const [conf, setConf] = useState<boolean>(false)


    return (
        <div className="container mx-auto">
            <ConfirmationModal open={conf} question={"Are you sure you want to clear the note? This can not be undone"} onConfirm={props.handleDelete} onCancel={() => { setConf(false) }} />


            {props.edit ?
                <div className="row  text-center ">

                    <Op
                        size={props.size ?? undefined}
                        titleHide={props.titleHide ?? false}
                        inverse={props.inverse}
                        className={props.kidClass ?? "col"}
                        onClick={props.changeMode}
                        icon={mdiReorderHorizontal}
                        title="Manage Items"
                    ></Op>
                    <Op
                        size={props.size ?? undefined}
                        titleHide={props.titleHide ?? false}
                        inverse={props.inverse}
                        className={props.kidClass ?? "col"}
                        onClick={() => setConf(true)}
                        icon={mdiClose}
                        title="Clear Note"
                    ></Op>
                    {/*
                <Op
                    size={props.size ?? undefined}
                    titleHide={props.titleHide ?? false}
                    inverse={props.inverse}
                    className={props.kidClass ?? "col"}
                    onClick={() => props.changeMode("quiz")}
                    icon={mdiFileQuestion}
                    title="Quiz"
                ></Op> */}
                </div>
                :

                <div className="row mx-auto text-center ">
                       {props.editSmall &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("edit")}
                            icon={mdiPencil}
                            title="edit"
                        ></Op>}
                           {props.plus &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("new")}
                            icon={mdiPlus}
                            title="new"
                        ></Op>}
                    {props.date && <Op
                        className={props.kidClass ?? "col"}
                        inverse={props.inverse}
                        titleHide={props.titleHide ?? false}
                        size={props.size ?? undefined}
                        onClick={() => props.changeMode("date")}
                        icon={mdiCalendarRange}
                        title="Dates"
                    ></Op>}
                    {props.list &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("list")}
                            icon={mdiFormatListBulleted}
                            title="List"
                        ></Op>}
                    {props.text &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("text")}
                            icon={mdiFileDocumentEditOutline}
                            title="Text"
                        ></Op>}
                    {props.flash &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("flash")}
                            icon={mdiCards}
                            title="Terms"
                        ></Op>}
                    {props.slides &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("slides")}
                            icon={mdiPresentationPlay}
                            title="Slides"
                        ></Op>}
                    {props.quiz &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("quiz")}
                            icon={mdiFileQuestion}
                            title="Quiz"
                        ></Op>}
                    {props.auto &&
                    <Op
                        size={props.size ?? undefined}
                        titleHide={props.titleHide ?? false}
                        inverse={props.inverse}
                        className={props.kidClass ?? "col"}
                        onClick={() => props.changeMode("auto")}
                        icon={mdiBrain}
                        title="Auto"
                    ></Op>}
                    {props.enlarge &&
                        <Op
                            size={props.size ?? undefined}
                            titleHide={props.titleHide ?? false}
                            inverse={props.inverse}
                            className={props.kidClass ?? "col"}
                            onClick={() => props.changeMode("enlarge")}
                            icon={mdiArrowExpand}
                            title="Enlarge"
                        ></Op>}
                   
                </div>

            }
        </div>
    );
};

const TextWidget = (props: {
    update: Function;
    parentId: string;
    item: noteItem;
    height?: number;
    posClassName?: string;
    small?: boolean;


}) => {
    const errorHandler = async () => { };
    const logic = new Logic(errorHandler, errorHandler, errorHandler);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [title, setTitle] = useState<string>(props.item.data.title)
    const menuRef = useRef<HTMLInputElement>(null);
    const bottomRef = useRef<HTMLInputElement>(null);

    const [text, setText] = useState<string>(props.item.data.text)

    const handleChange = (e: any) => { };
    useEffect(() => {
        const throttled = throttle(async () => {
            props.update({ ...props.item, data: { title: title, text: text } })
        }, 1500);

        if (text !== props.item.data.text) {
            throttled()
        }

    }, [text]);

    const opHandler = (x: string) => {

        switch (x) {
            case "text":
                break;
                case "enlarge":
                    props.update({ ...props.item, data: { title, text }, large: !props.item.large });
                    props.item.large ?
                        menuRef.current?.scrollIntoView({ behavior: "smooth" })
                        :
                        bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                    break;
            default:
                break;
        }

    }


    return (
        <div className={props.posClassName +" p-4"} style={{minHeight:"71vh"}}>
            <div className="row " >

                <Input
                    borderless={true}
                    ref={menuRef}
                    value={title}
                    style={{
                        fontSize: "1.5rem"
                    }}
                    posClassName="col-lg-6 mx-auto text-center"
                    label="Title"
                    onChange={(e: any) => logic.generic.inputHandler(e, setTitle)}
                />
                {title &&
                    <div className="col-lg-6 mt-2">
                        <StudyOps enlarge handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={opHandler} />


                    </div>
                }

            </div>

            <div className="row mt-3">
                <Form>
                    <Form.Group controlId="exampleForm.ControlTextarea1 ">
                        <Form.Control
                            className="writeBox"
                            as="textarea"
                            ref={textAreaRef}
                            style={{
                                height: props.height ?? "71vh",
                                border: "4px solid black",
                                resize: "none",
                            }}
                            name="address"
                            value={text ?? ""}
                            onChange={(e: any) => { logic.generic.inputHandler(e, setText) }}
                        />
                    </Form.Group>
                </Form>
            </div>
            <div ref={bottomRef}></div>


        </div>
    );
};

interface Event {
    text: string;
    date: Date;
    title: string;
}


const DateWidget = (props: {
    update: Function;
    parentId: string;
    item: noteItem;
    posClassName?: string;
    small?: boolean;

}) => {

    const [title, setTitle] = useState<string>(props.item.data.title ?? "")

    const [events, setEvents] = useState<Event[]>(props.item.data.events ?? [])
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const [edit, setEdit] = useState<boolean>(false)

    const bottomRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLInputElement>(null);

    const handleEventClick = (event: Event) => {
        setSelectedEvent(event);
    };

    const themeC = useContext(ThemeContext).theme

    const errorHandler = async () => { };
    const logic = new Logic(errorHandler, errorHandler, errorHandler);


    const [detailItem, setDetailItem] = useState<noteItem>({ type: "text", id: "initNote", data: { text: "" } })
    
    
    const opHandler = (x: string) => {

        switch (x) {
            case "text":
                break;
            case "enlarge":
                props.update({ ...props.item, data: { title, events }, large: !props.item.large });
                props.item.large ?
                    menuRef.current?.scrollIntoView({ behavior: "smooth" })
                    :
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                break;
            default:
                break;
        }

    }

    const updateNote = () => {

    }
    return (
        <div className={props.posClassName +" p-4"} style={{minHeight:"71vh"}}>
            <div className="row " >

                <Input
                    borderless={true}
                    ref={menuRef}
                    value={title}
                    style={{
                        fontSize: "1.5rem"
                    }}
                    posClassName="col-lg-8 mx-auto text-center"
                    label="Title"
                    onChange={(e: any) => logic.generic.inputHandler(e, setTitle)}
                />
                {title &&
                    <div className="col-lg-4 mt-2 row">
                        <StudyOps slides text list enlarge handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={opHandler} />


                    </div>
                }

            </div>

            <div className="container">

                {edit ?


                    <div className="col-lg-9 mx-auto">
                        <Heading size={4} handWritten posClassName='col-lg-12' style={{ textDecoration: "underline" }}>New Date</Heading>

                        <div className="row my-3">
                            <DateSelect done={() => { }} />
                        </div>
                        <div className="row my-3" >
                            <Input label="Title" posClassName='col-lg-9 ' />
                        </div>
                        <div className="row my-3" >
                            <Heading size={2} align="left" posClassName='col-lg-3 '>Details</Heading>

                            <div className="col-lg-8" >
                                <StudyOps slides text list handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={() => { }} />
                            </div>

                        </div>
                        <div className="row my-3" >
                            <NoteWidgetRenderSwitch small item={detailItem} updateNote={updateNote} />

                        </div>



                    </div>
                    :
                    <>

                        {selectedEvent && (
                            <div className="col-lg-9 mx-auto text-center">
                                <FlashCard card={["test", "test"]} />

                            </div>
                        )}
                        <div className="row">
                            <div className="col">
                                <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                                    {events.map((event, index) => {
                                        const dotPosition = (index / (events.length - 1)) * 100; //calculate the position of the dot based on the number of events
                                        return (
                                            <div className="timeline-step">
                                                <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                    <AppIcon icon={mdiRecordCircleOutline} onClick={() => { handleEventClick(event) }} title="point of interest" />
                                                    <Heading className="h6 mt-3 mb-1">2003</Heading>
                                                    <Heading className="h6 text-muted mb-0 mb-lg-0">Favland Founded</Heading>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="row my-3" >
                            <AppIcon onClick={() => { setEdit(true) }} title="New" className='col-lg-9 mx-auto text-center ' icon={mdiPlus} />
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

const ListItem = (props: {
    item: string;
    remove: Function;
    index: number;
    change: Function;
    pref?: any;
    reorder?: boolean;

}) => {
    const [value, setValue] = useState<string | undefined>(props.item);
    const themeC = useContext(ThemeContext).theme;

    const [updating, setUpdating] = useState<boolean>(false)


    const throttleApiCall = () => {
        props.change(value, props.index);
    };

    const debouncedApiCall = React.useCallback(
        throttle(throttleApiCall, 1500),
        []
    );




    const removeItem = () => {
        props.remove("", props.index);
    };



    return (
        <>
            {props.reorder ?

                <Heading>{value ?? " "}</Heading>
                :
                <li
                    className="my-3"
                    style={{
                        color: themeC.mode === "chalk" ? "white" : "black",
                    }}
                >

                    <Input
                        ref={props.pref}
                        borderless
                        onEmpty={removeItem}
                        onChange={(e: any) => logic.generic.inputHandler(e, setValue)}
                        value={value}
                        label={" "}
                        onEnter={(e: any) => {
                            props.change(e, props.index);
                        }}
                    />

                </li>
            }
        </>
    );
};
const ListWidget = (props: {
    update: Function;
    item: noteItem;
    parentId: string;
    posClassName?: string;
    small?: boolean;

}) => {

    const themeC = useContext(ThemeContext).theme;

    const [item, setItem] = useState<string[]>(props.item.data.items);
    const [title, setTitle] = useState<string>(props.item.data.title)

    const [reorder, setReorder] = useState<boolean>(false)
    const { domRef } = logic.generic.useFocus();
    const bottomRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLInputElement>(null);

    const opHandler = async(x: string) => {

        switch (x) {
            case "text":
                break;
            case "enlarge":
                props.update({ ...props.item, data: { title, items:item }, large: !props.item.large });
                props.item.large ?
                    menuRef.current?.scrollIntoView({ behavior: "smooth" })
                    :
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case "auto":
                let y = await logic.eloise.contList(title, item.join(","))
                console.log(y)

                break;
            default:
                break;
        }

    }


    const onDrop = (result: any) => {
        if (!result.destination) {
            return;
        }

        const newItems = [...item];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItem(newItems);

    };
    const done = () => {
        props.update({ ...props.item, data: { items: item, title: title } })

    }

    useEffect(() => {
        if (item.length === 0) {
            setItem(["New Item"]);
        }
        domRef?.current?.focus();

    }, [item]);

    const updateItem = (x: string, y: number) => {
        let newItem: string[] = [...item]
        newItem.splice(y, 1, x)
        console.log(newItem)
        setItem(newItem);
        props.update({ ...props.item, data: { items: newItem, title: title } })

        addItem(y);
        domRef?.current?.focus();

    };

    const removeItem = (x: string, y: number) => {
        let newItem: string[] = [...item]
        newItem.splice(y, 1);

        setItem(newItem);
        props.update({ ...props.item, data: { items: newItem, title: title } })

    };

    const addItem = async (i?: number) => {
        setItem([...item, ""]);
        domRef?.current?.focus();

    };

    //   const handleFlip = ()=>{
    //     if(!reorder){
    //         setReorder(true)
    //     }
    //   }

    //   const onStop = (e:any, data:any) => {
    //     const { y, node } = data;
    //     const draggingIndex = Number(node.dataset.index);
    //     const draggingValue = item[draggingIndex];
    //     const overValue = y > 0
    //       ? item[draggingIndex - 1]
    //       : item[draggingIndex + 1];
    //     const overIndex = y > 0 ? draggingIndex - 1 : draggingIndex + 1;
    //     setItem(
    //       item.map((value, index) =>
    //         index === overIndex ? draggingValue : index === draggingIndex ? overValue : value
    //       )
    //     );
    //   };

    return (
        <div className={props.posClassName +" p-4"} style={{minHeight:"71vh"}}>
            <div className="row " >

                <Input
                    borderless={true}
                    ref={menuRef}
                    value={title}
                    style={{
                        fontSize: "1.5rem"
                    }}
                    posClassName="col-lg-6 mx-auto text-center"
                    label="Title"
                    onChange={(e: any) => logic.generic.inputHandler(e, setTitle)}
                />
                {title &&
                    <div className="col-lg-6 mt-2">
                        <StudyOps  enlarge handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={opHandler} />


                    </div>
                }

            </div>



            <div className="row">
                <div className="col-lg-9 mx-auto">
                    {/* {reorder? 
                          <div >
                          <DragDropContext onDragEnd={onDrop}>
                              <Droppable droppableId="droppable">
                                  {(provided, snapshot) => (
                                      <div ref={provided.innerRef}>
                                          {item.map((x:any, index:number) => (
                                              <Draggable key={x.id} draggableId={`listItem${index}`} index={index}>
                                                  {(provided, snapshot) => (
                                                      <div
                                                          className="row"
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                      >
                                                        <ListItem
                                                        reorder
                                                        remove={removeItem}
                                                        change={updateItem}
                                                        index={index}
                                                        item={x}
                                                    />
                                                      </div>
                                                  )}
                                              </Draggable>
                                          ))}
                                          {provided.placeholder}
                                      </div>
                                  )}
                              </Droppable>
                          </DragDropContext>
                             <div className="mx-auto row mt-5">
                             <Button secondary borderRadius posClassName="col-lg-3 mx-auto" onClick={()=>{done()}}>Done</Button>
                         </div>
                      </div>
                    :  */}

                    <ul>
                        {item.map((x: string, i: number) =>

                            <ListItem
                                remove={removeItem}
                                change={updateItem}
                                key={`${x}.listItem.${i}`}
                                index={i}
                                item={x}
                                pref={i + 1 == item.length ? domRef : null}
                            />
                        )}
                    </ul>
                    {/* } */}

                </div>
            </div>
        </div>
    );
};

const VocabItem = (props: { item: any, master: Function }) => {
    const [term, setTerm] = useState<string>(props.item.word)
    const [def, setDef] = useState<string>(props.item.def)

    const [edit, setEdit] = useState<Boolean>(false)

    const save = () => {


        setEdit(false)
        props.master(term, def)
    }



    return (

        <div >
            {edit ?
                <>
                    <div>

                        <Input
                            onChange={(e: any) => {
                                logic.generic.inputHandler(e, setTerm);
                            }}
                            value={term}
                            label="Term"
                            posClassName="mx-auto col-lg-9 py-1"
                            className="text-center"
                        />

                        {term && <Input
                            onChange={(e: any) => {
                                logic.generic.inputHandler(e, setDef);
                            }}
                            value={def}

                            label="Def"
                            posClassName="mx-auto col-lg-9 py-1"
                            className="text-center"
                        />}


                        {def && <Button onClick={save} borderRadius posClassName="col-lg-3 mx-auto" >Save</Button>}

                    </div>
                </>
                :
                <HistoryItem $zoom={"01"} onClick={() => setEdit(true)}>
                    <Heading align="left" size={2}>{">" + props.item.word}</Heading>
                    <Heading size={1} align="left" style={{ paddingLeft: "20px" }}>{props.item.def}</Heading>
                </HistoryItem>

            }





        </div>
    )


}
const FlashWidget = (props: {
    update: Function;
    item: noteItem;
    parentId: string;
    posClassName?: string;
    small?: boolean;

}) => {


    const location = useLocation();

    const [title, setTitle] = useState<string>(props.item.data.title)
    const [mode, setMode] = useState<string>("vocab")
    const [vocab, setVocab] = useState<{ word: string, def: string, id: string }[]>(props.item.data.vocab ?? [])
    const [newTerm, setNewTerm] = useState<boolean>(false)
    const [term, setTerm] = useState<string>()
    const [def, setDef] = useState<string>()
    const [defining, setDefining] = useState<boolean>(false)
    const [notesChanged, setNotesChanged] = useState<boolean>(false)
    const bottomRef = useRef<HTMLInputElement>(null);
    const [opBar, setOpBar] = useState<boolean>(false)
    const menuRef = useRef<HTMLInputElement>(null);


    const opHandler = async(x: string) => {

        switch (x) {
            case "text":
                break;
            case "enlarge":
                props.update({ ...props.item, data: { title, vocab }, large: !props.item.large });
                props.item.large ?
                    menuRef.current?.scrollIntoView({ behavior: "smooth" })
                    :
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case "auto":
                console.log(location.state)
                let ots = {
                    name: title,
                    topic: title,
                    course: location.state.event.class.name,
                    specific: vocab
                }
        
                let newVocab = await logic.eloise.flash(ots).then((newVocab: any) => {
        
                    setVocab(newVocab.loaded)
                    props.update({ ...props.item, data: { title: title, vocab: newVocab.loaded } })
        
                })
                break;
            case "flash":
                setMode("grid")
                props.update({ ...props.item, data: { title: title, vocab: vocab }, large: true })
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case "list":
                setMode("vocab")
                props.update({ ...props.item, data: { title: title, vocab: vocab }, large: false })
                menuRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
                break;
            case "edit":
                setMode("edit")
                props.update({ ...props.item, data: { title: title, vocab: vocab }, large: true })
                bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                break;
        }

    }


    const newTermHandler = async () => {
        setNotesChanged(true)
        if (term) {
            if (!def) {
                let x = await define(term)
            }
            else {
                if (vocab) {
                    setVocab([...vocab, { word: term, def: def, id: uuidv4() }])
                    props.update({ ...props.item, data: { title: title, vocab: [...vocab, { word: term, def: def, id: uuidv4() }] } })

                } else {
                    setVocab([{ word: term, def: def, id: uuidv4() }])
                    props.update({ ...props.item, data: { title: title, vocab: [{ word: term, def: def, id: uuidv4() }] } })

                }
                setNewTerm(false)
                setTerm(undefined)
                setDef(undefined)
            }
        }
        else {
            setNewTerm(true)

        }


    }



    const define = async (x: string) => {



        let def: any = await logic.eloise.getDef(x)

        let test: any = Object.values(def)[0]

        if (vocab) {
            setVocab([...vocab, { word: x, def: test, id: uuidv4() }])
        } else {
            setVocab([{ word: x, def: test, id: uuidv4() }])

        }
        setNewTerm(false)
        setTerm(undefined)
        setDef(undefined)
    }

    const handleVocabEdit = (x: string, y: string, id: string) => {

        if (vocab) {

            let others = vocab.filter((x: any) => x.id !== id)

            setVocab([...others, { word: x, def: y, id: id }])


        }

    }

    const handleSave = (x:{ word: string, def: string, id: string }[])=>{
        props.update({ ...props.item, data: { title: title, vocab: x }, large: false })
        setMode("grid")

    }

    return (

        <div className={props.posClassName +" p-4"} style={{minHeight:"71vh"}}>
            <div className="row " >

                <Input
                    borderless={true}
                    ref={menuRef}
                    value={title}
                    style={{
                        fontSize: "1.5rem"
                    }}
                    posClassName="col-lg-6 mx-auto text-center"
                    label="Title"
                    onChange={(e: any) => logic.generic.inputHandler(e, setTitle)}
                />
                {title &&
                    <div className="col-lg-6 mt-2">
                        <StudyOps editSmall list flash auto enlarge handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={opHandler} />


                    </div>
                }

            </div>

            <div className="row mt-1 mx-auto">


            </div>
            {mode === "vocab" ?

                <>

                    <div className="mt-5 row" >

                        {vocab && vocab.map((x: any, i: number) =>
                            <VocabItem key={`vocabItem.${i}`} master={handleVocabEdit} item={x} />

                        )}
                        {newTerm &&
                            <div >

                                <Input
                                    onChange={(e: any) => {
                                        logic.generic.inputHandler(e, setTerm);
                                    }}
                                    label="Term"
                                    posClassName="mx-auto col-lg-9 py-1"
                                    className="text-center"
                                />

                                {term && <Input
                                    onChange={(e: any) => {
                                        logic.generic.inputHandler(e, setDef);
                                    }}
                                    label="Def"
                                    posClassName="mx-auto col-lg-9 py-1"
                                    className="text-center"
                                />}

                            </div>}
                        <div className="mx-auto text-center mt-4">
                            <AppIcon icon={mdiPlus} onClick={newTermHandler} title="new" />
                        </div>
                    </div>
                </>
                :

                <div>
                    <Flash save={handleSave} mode={mode} deck={vocab} back={() => { }} />

                </div>
            }
            <div style={{ height: "10px" }} ref={bottomRef}></div>
        </div>

    )

}

interface Slide {
    content?:  string;
    title?: string;
    id: string
}

const SlideWidget = (props: {
    update: Function;
    item: noteItem;
    parentId: string;
    posClassName?: string;
    small?: boolean;

}) => {

    const [title, setTitle] = useState<string>(props.item.data.title)
    const location = useLocation();

    const bottomRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLInputElement>(null);

    const [slides, setSlides] = useState<Slide[]>(props.item.data.slides ?? [{ id: "init" }])
    const [sI, setSI] = useState<number>(0);
    const [slideData, setSlideData] = useState<any>(props.item.data.slides[0] ?? { id: "init" });

    const [text, setText] = useState<string>(props.item.data.slides[0].content ?? "")

    const opHandler = async(x: string) => {

        switch (x) {
            case "text":
                break;
            case "enlarge":
                props.update({ ...props.item, data: { title, slides }, large: !props.item.large });
                props.item.large ?
                    menuRef.current?.scrollIntoView({ behavior: "smooth" })
                    :
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case "auto":
                let y = await logic.eloise.overview(location.state.class.name, title);
                let newSlides = y.map((x:any)=>{return{title:x.title, content:x.text, id:uuidv4()}})
                setSlides(newSlides)
                props.update({ ...props.item, data: { title: title, slides: newSlides } })
                break;
            case "plus":

                break;
            default:
                break;
        }

    }

    const buttonHandler = (x: number) => {
        if (x === 0) {
            if (sI === 0) {
                setSlideData(slides[slides.length - 1]);
                setSI(slides.length - 1);
                setText(slides[sI - 1].content?? "")
            } else {
                setSlideData(slides[sI - 1]);
                setSI(sI - 1);
                setText(slides[sI - 1].content?? "")

            }
        } else {
            if (sI < slides.length - 1) {
                setSlideData(slides[sI + 1]);
                setText(slides[sI + 1].content?? "")

                setSI(sI + 1);
            } else {
                setSlideData(slides[0]);
                setText(slides[0].content?? "")

                setSI(0);
            }
        }
    };

   




    const handleSlideEdit = (slide: Slide, id: string) => {



        let newSlides = slides.map((x: any) => {
            if (x.id !== id) {
                return x
            }
            else {
                return slide
            }
        })

        setSlides(newSlides)
        props.update({ ...props.item, data: { title: title, slides: newSlides } })




    }
    return (

        <div className={props.posClassName +" p-4"} style={{minHeight:"71vh"}}>
            <div className="row " >

                <Input
                    borderless={true}
                    ref={menuRef}
                    value={title}
                    style={{
                        fontSize: "1.5rem"
                    }}
                    posClassName="col-lg-6 mx-auto text-center"
                    label="Title"
                    onChange={(e: any) => logic.generic.inputHandler(e, setTitle)}
                />
                {title &&
                    <div className="col-lg-6 mt-2">
                        <StudyOps  auto plus enlarge handleDelete={() => { }}kidClass="col" size={1.2} titleHide changeMode={opHandler} />


                    </div>
                }

            </div>
            <div className="row">


                <div
                    className="col-lg-1"
                    style={{
                        paddingTop: "23vh"

                    }}>

                    <AppIcon
                        title="back"
                        icon={mdiArrowLeftThick}
                        onClick={() => {
                            buttonHandler(0);
                        }}
                    />
                </div>

                <OverviewSlide className="col-lg-9 ">
                    <Heading posClassName="mb-5" size={3}>{slideData.title}</Heading>
                    {slideData.content ?
                        <>

                            {slideData.content.type ?
                                <>
                                    <NoteWidgetRenderSwitch updateNote={handleSlideEdit} item={slideData.content} />
                                </>
                                :
                                <div className="row mt-3">
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlTextarea1 ">
                                        <Form.Control
                                            className="writeBox"
                                            as="textarea"
                                            style={{
                                                height: "20vh",
                                                border: "4px solid black",
                                                resize: "none",
                                            }}
                                            name="address"
                                            value={text ?? ""}
                                            onChange={(e: any) => { logic.generic.inputHandler(e, setText) }}
                                        />
                                    </Form.Group>
                                </Form>
                            </div>
                            }
                        </> :

                        <>
                        </>
                       
                    }

                </OverviewSlide>
                <div
                    className="col-lg-1"
                    style={{
                        paddingTop: "23vh"
                    }}>
                    <AppIcon
                        title="back"

                        icon={mdiArrowRightThick}
                        onClick={() => {
                            buttonHandler(1);
                        }}
                    />
                </div>
            </div>        </div>

    )

}

export { StudyOps, SlideWidget, FlashWidget, ListWidget, DateWidget, TextWidget };
