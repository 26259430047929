import React, {useState, useEffect} from 'react';

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import { ClearButton, Heading, Input, Loading } from '../';
import { Logic } from '../../functions';
import { useNavigate } from 'react-router';

const EventCatcher = (props:{course: string, topic?: string, next:string, type:"exam"| "ass"})=>{
    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState<Date>();
    const [dateB, setDateB] = useState<boolean>(true)
    const [answer, setAnswer] = useState<string>(props.type=="exam"? "When is your exam? ": "When is your assignment due?")
    const [couse, setCourse] = useState<string>(props.course)
    const [ui, setUi] = useState<boolean>(false)
    const [nextB, setNextB] = useState<boolean>(false)
    const [loading, setLoading] =useState<boolean>(false)
    const [progression, setProgression] = useState<number>(0)

    
    const next = (x:string)=>{

        const bootstrapAsync = async()=>{
        switch(progression) {
            case 0:
                if(props.topic){
                    setLoading(true)
                    let y = await logic.generic.addTest(props.topic, props.course, startDate, props.type)
                    if(y){
                        setAnswer("Got it!")
                        setLoading(false)
                        setTimeout(()=>{navigate(props.next)}, 2000)

                    }

                }
                else{
               
                setAnswer(props.type=="exam"? "And what is it on?" : "Give it a name. I will get more info in a bit")
                setNextB(false)
                setDateB(false)
                setUi(true)
                setProgression(1)
                }
                break;
            case 1:
                setLoading(true)
                let y = await logic.generic.addTest(x, props.course, startDate, props.type)
                if(y){
                    setAnswer("Got it!")
                    setUi(false)
                    setNextB(false)
                    setLoading(false)
                    setTimeout(()=>{navigate(props.next)}, 2000)
                }
                break;
            
    }

        }
    bootstrapAsync();

    }

    useEffect(()=>{
        if(startDate){
            setNextB(true)
        }
        
    },[startDate])

  return (
    <>
    {loading? <Loading /> : 
    <div className="container">

        <Heading size={2} white>{answer}</Heading>

        {dateB &&    <div className="row mx-auto"><DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} /></div>}
        {nextB && <ClearButton primary borderColor posClassName="col-lg-6 mx-auto my-3" onClick={next}>Next</ClearButton>}
        {ui && 
                        <Input
                            backgroundColor="transparent"
                            primary
                            className="text-center"
                            posClassName="col-lg-6 mx-auto py-5"
                            // onChange={(e: any) => {
                            //     logic.generic.inputHandler(e, setQuery);
                            // }}
                            onEnter={next}
                            label={"topic"}
                        />}

        
    </div>
        }</>
  );
}

export {EventCatcher}