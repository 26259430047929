import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {   mdiBookClock, mdiSchool, mdiCogOutline, mdiPencil, mdiCards,mdiShieldCrown, mdiCalendar,mdiPresentation} from "@mdi/js";
import { Loading,AppIcon } from "..";

import { useNavigate,  Navigate } from "react-router";
import { useAuthState, useIdToken } from "react-firebase-hooks/auth";

import { Logic } from "../../functions";

import { useNavigateNoUpdates } from "../../StableNavigateContext";
const Apps = () => {
  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const [apps, setApps] = useState<any[]>([]);
  const [path, setPath] = useState<string>();
  const [user, loading2, error] = useAuthState(logic.fb.auth);

  const navigate = useNavigate()

     const permGroups = [
          {
            name: "admin",
            apps: ["admin", "learn", "write", "schedule", "profile", "flash", "calendar"],
          },
          {
            name: "student",
            apps: ["learn", "write",  "profile", "flash" ,"schedule", 'calendar'],
          },
          {
            name: "demo",
            apps: ["learn", "write",  "profile", "flash", "schedule", 'calendar'],
          },
        ];
        const appStore = [
          {
            name: "learn",
            link: "/learn",
            logo: mdiSchool,
          },
          {
            name: "write",
            link: "/write",
            logo: mdiPencil,
          },
       
        
          
          {
            name:"calendar",
            link:"/calendar",
            logo: mdiCalendar,
          },
          {
            name:"schedule",
            link:"/schedule",
            logo:  mdiBookClock,
          },
            {
            name: "profile",
            link: "/profile",
            logo: mdiCogOutline,
          }
          
          ,{
            name:"admin",
            link:"/admin",
            logo: mdiShieldCrown,
          },
        ];


  useEffect(() => {


    window.addEventListener('auth', () => {
     
      let existingPerms = localStorage.getItem("userData");

      if (existingPerms !== null) {
        let parsed = JSON.parse(existingPerms);
        let group = permGroups.find((g) => g.name === parsed.perms);

        const apps = appStore.filter((app: any) =>
          group?.apps.includes(app.name)
        );

        setApps(apps);
      } 
    })
}, []);

  useEffect(() => {
      let test = localStorage.getItem("userData")



      if (test) {
        let existingPerms = test
        if (existingPerms !== null) {
          let parsed = JSON.parse(existingPerms);
          let group = permGroups.find((g) => g.name === parsed.perms);

          const apps = appStore.filter((app: any) =>
            group?.apps.includes(app.name)
          );
          setApps(apps);
        } 
      }

  }, [user]);

  

  return (
      <div className=" d-flex mx-auto mt-2" style={{overflowX:"scroll", overflowY:"hidden", maxHeight:"12vh"}}
  
      >

        {apps.length>0 ? (
          apps.map((app: any) => {
            return (
         

              <AppIcon
                key={uuidv4()}
                icon={app.logo}
                title={app.name}
                onClick={() => {localStorage.removeItem("currentConvo");navigate(app.link)}}
                className={`col-sm-3 pb-3 text-center`}
              ></AppIcon> 
         
            );
          })
        ) : (
          <div className="mx-auto">
          <Loading />
          </div>
        )}
      </div>
  );
};

export default Apps;
