import React, { useState, useEffect, useContext, useRef } from "react";

import { Logic } from "../../functions";
import { AppContext, EventInterface, noteItem, Note } from "../../AppContent";
import { useLocation } from "react-router";
import { SlideWidget, Timeline } from "../widgets";
import { Back, OutlinedDiv, StudyOpDiv } from "../../styled";
import { ChildrenModal, Heading, StudyOps, ListWidget, TextWidget, DateWidget, FlashWidget, Button, AppIcon } from "..";
import { ThemeContext } from "../../App";
import { mdiCards, mdiFileDocumentEdit, mdiFileQuestionOutline, mdiFormatListBulleted, mdiPencil, mdiPlus, mdiPresentationPlay, mdiReorderHorizontal } from "@mdi/js";
import { uuidv4 } from "@firebase/util";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



interface Props {
    noteItems: noteItem[];
    done: Function
}

const ReorderableNoteList: React.FC<Props> = ({ noteItems, done }) => {
    const [items, setItems] = useState(noteItems);

    const onDrop = (result: any) => {
        if (!result.destination) {
            return;
        }

        const newItems = [...items];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItems(newItems);
    };

    const getIcon = (x: string) => {
        let ots
        switch (x) {
            case "flash":
                ots = mdiCards
                break;
            case "text":
                ots = mdiFileDocumentEdit
                break;
            case "slides":
                ots = mdiPresentationPlay
                break;
            case "quiz":
                ots = mdiFileQuestionOutline
                break;
            case "list":
                ots = mdiFormatListBulleted
                break;
            default:
                ots = mdiPresentationPlay
        }

        return ots
    }


    const deleteItem = (x: noteItem) => {
        let newList = [...items].filter((item: noteItem) => item.id !== x.id)
        setItems(newList);
    }

    return (
        <div >
        <DragDropContext onDragEnd={onDrop}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        className="row"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <AppIcon inverse icon={mdiReorderHorizontal} className="col-lg-1 mt-2" title="drag to move" onClick={() => { }} size={1.2} />
                                        <Heading posClassName="col" inverse size={3}>{item.data.title}</Heading>
                                        <AppIcon inverse icon={getIcon(item.type)} className="col-lg-1 mt-2" title={item.type} onClick={() => { }} size={1.2} />
                                        <Button posClassName="col-lg-2 " borderRadius padding="2px 0" onClick={() => { deleteItem(item) }}>Delete</Button>

                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
           <div className="mx-auto row mt-5">
           <Button secondary borderRadius posClassName="col-lg-3 mx-auto" onClick={()=>{done(items)}}>Done</Button>
       </div>
    </div>
    );
};

interface ModalProps {
    open: boolean;
    onClose: () => void;
    choice: Function;
    clear: Function,
    note: Note,
    changeNote: Function
}



const EventModal = (props: ModalProps) => {

    const [manage, setManage] = useState<boolean>(false)


    const done = (x:noteItem[])=>{
        setManage(false)
        props.changeNote({...props.note, items:x})
    }

    return (
        <ChildrenModal style={
            manage ? { width: "75vw", height: "50vh", transition: "width 1.5s ease-in-out, height 1.5s ease-in-out" } : {}
        }
            open={props.open} onClose={props.onClose}>

            {manage ?
                <>
                    <div className="mx-auto row">
                        <div className="col-lg-9 mx-auto">
                            <ReorderableNoteList noteItems={props.note.items} done={done} />
                        </div>
                    </div>

                
                </>
                :
                <div className="">

                    <Heading posClassName="row my-2" align="left" inverse size={3}>New Item</Heading>
                    <div className="row pl-2">

                        <StudyOps  text flash list slides  handleDelete={props.clear} inverse changeMode={props.choice} />

                    </div>

                    <Heading posClassName="row my-2" align="left" inverse size={3}>Edit</Heading>
                    <div className="row pl-2">

                        <StudyOps  handleDelete={props.clear} edit inverse changeMode={() => { setManage(true) }} />

                    </div>


                </div>
            }
        </ChildrenModal>
    )
}

interface NoteSwitchProps {
   item: noteItem;
    updateNote:Function;
    small?: boolean;
    
}


const NoteWidgetRenderSwitch: React.FC<NoteSwitchProps> = ({ item, updateNote, small })=>{

    const location = useLocation()


    switch (item.type) {
        case "text":
            return <TextWidget small={small} posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={location.state.event.id} key={item.id} />
        case "list":
            return <ListWidget small={small} posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} parentId={location.state.event.id} item={item} key={item.id} />;
        case "date":
            return <DateWidget small={small} posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={location.state.event.id} key={item.id} />;
        case "flash":
            return <FlashWidget small={small} posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={location.state.event.id} key={item.id} />;
        case "slides":
            return <SlideWidget small={small} posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={location.state.event.id} key={item.id} />;
         default:
            return <p key={item.id}>Invalid note type</p>;
    }
}

const EventMode = () => {

    const errorHandler = async () => { };
    const logic = new Logic(errorHandler, errorHandler, errorHandler);

    const location = useLocation();
    const appContext = useContext(AppContext);
    const passedEvent: EventInterface = location.state.event;
    const themeC = useContext(ThemeContext).theme
    const [id, setId] = useState<string>(location.state.event.noteId)
    const [event, setEvent] = useState<EventInterface>(passedEvent)


    const [note, setNote] = logic.hooks.useNoteSave(3000, logic.generic.updateNote, { id: "place", items: [] }, id)

    const [modalB, setModalB] = useState<boolean>(false)

    const clearNote = () => {
        setModalB(false)
        setNote({ id, items: [] })
    }


    useEffect(() => {

        const bootstrapAsync = async () => {
            let exi = localStorage.getItem(`note.${id}`)
            if (exi) {
                let parsed = JSON.parse(exi)
                setNote(parsed)
            }
            else {
                if (id) {
                    let x = await logic.generic.loadNote(id)
                    localStorage.setItem(`note.${id}`, JSON.stringify(x))
                    setNote(x)
                }
            }

        }

        bootstrapAsync()

    }, [])



    const updateNote = async (x: noteItem) => {
        let newNote = [...note.items].map((y: noteItem) => {
            if (x.id === y.id) {
                return x
            }
            else {
                return y
            }

        })


        setNote({ ...note, items: newNote })

    }

    const addItem = (x: string) => {

        const ots =
            x === "list" ?
                { items: [] }
                :
            x ==="slides"?
                {slides:[{id: uuidv4()}]}
                :

                { new: true };



        let newItem: noteItem = {
            type: x,
            id: uuidv4(),
            data: ots
        }

        setNote({ ...note, items: [...note.items, newItem] })
        setModalB(false)

    }

    return (
        <div className="">
            <EventModal note={note} changeNote={setNote} clear={clearNote} open={modalB} choice={addItem} onClose={() => { setModalB(false) }} />


            <Back onClick={() => { setModalB(true) }} icon={mdiPencil} />

            <OutlinedDiv $mode={themeC.mode} $notop $noright $noleft className="col-lg-12" style={{ height: "9vh" }}>
                <Heading size={5} handWritten >{event.class.name + " - " + event.name}</Heading>
            </OutlinedDiv>

            <div
                className="row g-0 mx-auto"
                style={{ overflowY: "scroll", height: "91vh", width: "100%", position: "relative", zIndex: 2 }}>



                {note.items.length > 0 && note.items.map((item: noteItem) => {
                    
                    switch (item.type) {
                        case "text":
                            return <TextWidget posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={event.id} key={item.id} />
                        case "list":
                            return <ListWidget posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} parentId={event.id} item={item} key={item.id} />;
                        case "date":
                            return <DateWidget posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={event.id} key={item.id} />;
                        case "flash":
                            return <FlashWidget posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={event.id} key={item.id} />;
                        case "slides":
                            return <SlideWidget posClassName={item.large ? "col-lg-12" : "col-lg-6"} update={updateNote} item={item} parentId={event.id} key={item.id} />;
                         default:
                            return <p key={item.id}>Invalid note type</p>;
                    }
                })}
            </div>
        </div>
    );
};


export { EventMode, NoteWidgetRenderSwitch}