import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import {  Loading,  Heading } from "..";
import { HistoryItem } from "../../styled";
import { useNavigate} from "react-router";

import { Logic } from "../../functions";
import theme from "../../theme";
import { AppContext, EventInterface } from "../../AppContent";

const Upcoming = () => {
  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const navigate = useNavigate();

  const [upcoming, setUpcoming] = useState<EventInterface[]>();

  const appContext = useContext(AppContext);



const handleClick = (x:any)=>{
  if(appContext){
  let classes = appContext.classes
  if (classes) {    
      navigate("/event" , {state:{
        class: x.class,
        event: x
      }})
      
  }
}

}
if(appContext){
  return(
  
    <div className="container mx-auto">
      <div className="row mx-auto w-100" >
      <Heading
        style={{ textDecoration: "underline" }}
        handWritten
        size={4}
        white
        posClassName="pb-4"
      >
        Coming Up
      </Heading>
       
            {appContext.events.length > 0 && appContext.events.filter((x:EventInterface)=> x.id !== "place").map((thing:EventInterface) => {return(
                  <HistoryItem key={uuidv4()} onClick={()=>{handleClick(thing)}}>
                <Heading             white style={{textDecoration: `underline solid 3px ${theme.primary} ` }} size={1}>{`${new Date(thing.date).toLocaleString('default', { month: 'short' })} ${new Date(thing.date).getDate()} - ${thing.class.name} (${thing.name})  `}</Heading>
                </HistoryItem>
            )})}
         
       
      </div>
    </div>
  );
            }else{
              return<Loading />
            }
};

export default Upcoming;
