




const layOutSwitch = (loca: any, size: "mobile" | "tablet" | "desktop") => {

  let ots = {
    icycle1: true,
    icycle2: true,
    icycle3: true,
    icycle1Width: 3,
    icycle2Width: 6,
    icycle3Width: 3,

    frost1: true,
    frost2: true,
    frost3: true,
    frost1Height: 45,
    frost2Height: 15,
    frost3Height: 40,
    frost4: true,
    frost5: true,
    frost4Height: 30,
    frost5Height: 10,
    frost6: true,
    frost6Height: 60,
  }

  let search = loca.search
  let path = loca.pathname

  switch (path) {
    case "/tour":
    case "/":
      break;
    case "/signup":
    console.log(size)
      ots = {
        ...ots,
        frost2: false,
        frost3: false,
        frost1Height: 100,
        frost2Height: 0,
        frost3Height: 0,
        frost5:false,
        frost4Height: 100,
        frost5Height:  0,
        frost6: false,
        frost6Height: 0,
      }

      if(size === "mobile"){
        ots.icycle1 = false
        ots.icycle3 = false
      }
    
      break;
    case "/flash":
    case "/learn":
      ots = {
        ...ots,
        icycle3: false,
        icycle2Width: 9,
        icycle3Width: 0,
        frost3: false,
        frost1Height: 50,
        frost2Height: 50,
        frost3Height: 0,
        frost5: false,
        frost6: false,
        frost6Height: 0,
        frost4Height: 100,
        frost5Height: 0,
      }
      break;
    case "/profile":
      ots = {
        ...ots,
        icycle1: false,
        icycle3: false,
        icycle1Width: 0,
        icycle3Width: 0,
        icycle2Width: 12,
      }
      break;
    case "/write":
      ots = {
        ...ots,
        icycle3: false,
        icycle1Width: 6,
        icycle2Width: 6,
        icycle3Width: 0,
        frost2: false,
        frost3: false,
        frost1Height: 100,
        frost2Height: 0,
        frost3Height: 0,
        frost4: false,
        frost5: false,
        frost4Height: 100,
        frost5Height: 0,
        frost6: false,
        frost6Height: 0,
      }
      break;
    case "/collab":
    case "/class":
    case "/event":
    case "/policy":
    case "/schedule":
    case "/calendar":
    case "/404":
    case "/game":
      ots = {
        ...ots,
        icycle1: false,
        icycle3: false,
        icycle1Width: 0,
        icycle2Width: 12,
        icycle3Width: 0,
        frost2: false,
        frost3: false,
        frost1Height: 100,
        frost2Height: 0,
        frost3Height: 0,
        frost4: false,
        frost5: false,
        frost4Height: 100,
        frost5Height: 0,
        frost6: false,
        frost6Height: 0,
      }
      break;
    case "/admin":
      ots = {
        ...ots,
        icycle1: true,
        icycle2: true,
        icycle3: false,
        icycle1Width: 2,
        icycle2Width: 10,
        icycle3Width: 0,

        frost1: true,
        frost2: false,
        frost3: false,
        frost1Height: 100,
        frost2Height: 34,
        frost3Height: 33,
        frost4: true,
        frost5: false,
        frost4Height: 100,
        frost5Height: 0,
        frost6: false,
        frost6Height: 0,
      }
      break;
  }
 

  if (search) {
    switch (path) {
      case "/collab":
      case "/event":
      case "/policy":
      case "/schedule":
      case "/calendar":
        ots = {
          ...ots,
          icycle3Width: 3,
          icycle2Width: 9,
          icycle3: true, 
          frost4: true
        }
        break;
      case "/write":
        ots = {
          ...ots,
          icycle1Width: 3,
          icycle3Width: 3, 
          icycle2Width: 6, 
          icycle3: true, 
          frost4: true
         }
         break;
      default:
        ots = { ...ots,
          icycle3Width: 3, 
          icycle2Width: 6, 
          icycle3: true, 
          frost4: true }
        break;
    }
  
}



return ots
}




const lists: { [key: string]: any } = {

  errorMessages: {
    write: {
      type: ["Not sure if I know how to write that... try something like an essay or a paragraph."],
      length: ["That won't work.. Try something like '4 paragraphs' "],
      level: ["Not sure if I understand... try something like 'high school' or 'college'"]
    }
  }

}

const pathMap: { [key: string]: any } = {


  "/learn": {

    icycle1: true,
    icycle2: true,
    icycle3: false,
    icycle1Width: 3,
    icycle2Width: 9,
    icycle3Width: 0,
    frost1: true,
    frost2: true,
    frost3: false,
    frost1Height: 50,
    frost2Height: 50,
    frost3Height: 0,
    frost4: true,
    frost5: false,
    frost6: false,
    frost6Height: 0,
    frost4Height: 100,
    frost5Height: 0,
  },
  "/": {
    icycle1: true,
    icycle2: true,
    icycle3: true,
    icycle1Width: 3,
    icycle2Width: 6,
    icycle3Width: 3,

    frost1: true,
    frost2: true,
    frost3: true,
    frost1Height: 45,
    frost2Height: 15,
    frost3Height: 40,
    frost4: true,
    frost5: true,
    frost4Height: 30,
    frost5Height: 10,
    frost6: true,
    frost6Height: 60,
  },
  "/profile": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle3Width: 0,

    icycle2Width: 12,
    frost1: true,
    frost2: true,
    frost3: true,
    frost1Height: 33,
    frost2Height: 34,
    frost3Height: 33,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/write": {
    icycle1: true,
    icycle2: true,
    icycle3: false,
    icycle1Width: 6,
    icycle2Width: 6,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/collab": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/event": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/class": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/policy": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/calendar": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/schedule": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/404": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 0,
    icycle2Width: 12,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/game": {
    icycle1: false,
    icycle2: true,
    icycle3: false,
    icycle1Width: 3,
    icycle3Width: 0,

    icycle2Width: 12,
    frost1: true,
    frost2: true,
    frost3: false,
    frost1Height: 50,
    frost2Height: 50,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/flash": {
    icycle1: true,
    icycle2: true,
    icycle3: false,
    icycle1Width: 3,
    icycle3Width: 0,

    icycle2Width: 9,
    frost1: true,
    frost2: true,
    frost3: false,
    frost1Height: 50,
    frost2Height: 50,
    frost3Height: 0,
    frost4: false,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,

    frost6: false,
    frost6Height: 0,
  },
  "/signup": {
    icycle1: true,
    icycle2: true,
    icycle3: true,
    icycle1Width: 3,
    icycle3Width: 3,

    icycle2Width: 6,
    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 0,
    frost3Height: 0,
    frost4: true,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
  "/admin": {
    icycle1: true,
    icycle2: true,
    icycle3: false,
    icycle1Width: 2,
    icycle2Width: 10,
    icycle3Width: 0,

    frost1: true,
    frost2: false,
    frost3: false,
    frost1Height: 100,
    frost2Height: 34,
    frost3Height: 33,
    frost4: true,
    frost5: false,
    frost4Height: 100,
    frost5Height: 0,
    frost6: false,
    frost6Height: 0,
  },
};


export { lists, pathMap, layOutSwitch }