import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";

import { mdiAlert, mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { Row } from "react-bootstrap";

import { Input, Button, Heading, Loading, ClearButton, Google, Eloise, Chat, Cal } from "../";
import theme from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { Logic } from "../../functions";

import { signInWithEmailAndPassword, signOut , sendEmailVerification} from "firebase/auth";
import { useIdToken } from "react-firebase-hooks/auth";
import { interaction } from "../../functions/eloise";
import { SignUpSmall3 } from "../modes/signUpMode";

const SignUp = (props:{login:Function}) => {


  const location = useLocation()
  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const navigate = useNavigate();
  const [user, loading2, error] = useIdToken(logic.fb.auth);

  let oldData: any = localStorage.getItem("signUpData");
  if (oldData) {
    oldData = JSON.parse(oldData);
  }
  const [welcome, setWelcome] = useState<boolean>(false);
  const [welcomeText, setWelcomeText] = useState<string>(
    "Awesome! It is so great to meet you, I sent you a verification email, Click on the link in the email and then press the button below once you are done"
  );

  const [email, setEmail] = useState<string>(
    oldData ? oldData.email : undefined
  );
  const [emailError, setEmailError] = useState<string>();

  const [password, setPassword] = useState<string>(
    oldData ? oldData.password : undefined
  );
  const [passwordError, setPasswordError] = useState<string>();

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [first, setFirst] = useState<string>(
    oldData ? oldData.firstName : undefined
  );
  const [last, setLast] = useState<string>(
    oldData ? oldData.lastName : undefined
  );
  const [country, setCountry] = useState<string>(
    oldData ? oldData.country : undefined
  );
  const [passConf, setPassConf] = useState<string>(
    oldData ? oldData.confirmPassword : undefined
  );
  const [phone, setPhone] = useState<string>(
    oldData ? oldData.phone : undefined
  );
  const [username, setUsername] = useState<string>(
    oldData ? oldData.username : undefined
  );
  const [preName, setPreName] = useState<string>(
    oldData ? oldData.preName : undefined
  );

  const [testDate, setTestDate] = useState<any>()
  const [course, setCourse] = useState<string>()
  const [topic, setTopic] = useState<string>()
  const [usernameError, setUsernameError] = useState<string>();


  const answers:string[][] = [
    ["Hey there! I'm Eloise, it is so great to meet you. What's your name?"],
    ["It is simply a pleasure. Let me tell you a little bit about me ",
    "My job is to help you make the most of your years as a student. I am capable of completing a variety of tasks that make learning a breeze.",
    "Are you currently a student?"],
    ["Perfect, we will be great match",
    "Let me show you some of the things I can do to help you out.",
    "School is hard. But having a little bit of help can go a long way. One of the most difficult parts of life as a student is managing a full schedule of different classes",
    "Let me show you one way I can help, when is your next test/exam?"],
    ["Ok and what class is that for"],
    ["That sounds like fun... What is it on?"],
     ["Great, I will work on getting everything ready for you", "One more thing, What point of your educational career are you in? High School? college? ect..."],
     ["Sick, While I get your custom enviorment ready Go ahead and fill out the following info so I can finalize your account"],
    ["Thanks, All you need to do now if head to your inbox and verify your email with the link I just sent you"]

  ]
  const [convo, setConvo] = useState<interaction[]>([{eloise:answers[0][0]}]);
  const [uiLabel, setUiLabel] = useState<string>("...")    

  const [progression, setProgression] = useState<number>(0)
  const [mode, setMode] = useState<"chat" | "info"| "welcome" | "watch" >(
    location.state? location.state.signUpMode : "chat"
  );

  const [uiMode, setUiMode] = useState<"text" | "choice" | "date" | "class">(
    "text"
  );
  const [educationLevel, setEducationLevel] = useState<string>()
  const [year, setYear] = useState<string>()
  const [classes, setClasses] = useState<string[]>()



  const continueHandle = () => {
    setLoading(true)
    const bootstrapAsync = async () => {
      await props.login(email, password)

      let x = await logic.eloise.isVerified();
      console.log(x)

      if (x == true) {


        navigate("/tour");
      } else {
        setWelcomeText("Hmm I am not seeing that.. Maybe try again");
        setLoading(false)
      }
    };
    bootstrapAsync();
  };


  const watchEmail = async()=>{


        
        
    logic.fb.auth.currentUser?.reload()

    let test  = logic.fb.auth.currentUser?.emailVerified

    if(test){
        navigate("/")
    }
    else{
      setLoading(true)
      setTimeout(()=>{

        logic.fb.auth.currentUser?.reload()

        let test2  = logic.fb.auth.currentUser?.emailVerified

        if(test2){
          navigate("/")
    
        }
        else{
          setLoading(false)
        }
        

      },10000)

    }







}

  const onEnterHandler = async (x:string | any)=>{
    setLoading(true)
    let newPro = progression  + 1
      switch(newPro){
        case 1:
          setPreName(x)
          break;
        case 2:
          setUiMode("date")
          break;
        case 3: 
          setTestDate(x)
          setUiMode("text")
          break;
        case 4:
          setCourse(x)
          break;
        case 5: 
          setTopic(x)
          break;
        case 6:
          setEducationLevel(x)
      
          setTimeout(()=>{
            navigate("/signup", {state: {signUpMode: "info", data: {course:course, date:testDate, preName: preName, topic: topic, eduLevel: x}}})
          },2000) 
         break;
        
      }
      setTimeout(()=>{
              let elRes = answers[newPro].map((y:string)=>{return{eloise: y}})
              let newConvo = [{ human: newPro === 3? x.toString(): x}, ...elRes]
              setConvo(newConvo)
              setLoading(false)
              setProgression(newPro)
      },1000)

    




  }
  
    
    
  if (mode==="welcome") {
    return (
      <>
        <Heading posClassName="mt-5" size={3} white>
          {welcomeText}
        </Heading>
        <div className="text-center " style={{ marginTop: "25vh" }}>
          <Icon
            path={mdiCheck}
            className="col-lg-4 mx-4 "
            title="User Profile"
            size={3}
            color="white"
          />
        </div>

        <div className="row mt-5 mx-3">
          <ClearButton
            onClick={continueHandle}
            borderRadius
            posClassName="col-lg-6 mx-auto mt-5"
          >
            Continue
          </ClearButton>
        </div>
      </>
    );
  } 
  
  else   if (mode==="chat") {
    
    
    return (
     

      <div className="mt-5">
  
         <Chat convo={convo} loading={loading}/>

         
        
     
         
      
      
          {uiMode === "date" && (
          <div className="row  mt-5">
            <Cal next={onEnterHandler} />

            
          </div>
        )}
        {uiMode === "text" && (
          <div
            style={{
              position: "absolute",
              top: "90vh",
              width: "100%",
            }}
          >
            
            <Input
              value={loading ? "" : undefined}

              primary
              className="text-center"
              posClassName="mx-auto col-9 "
              onEnter={onEnterHandler}
              label={uiLabel}
            />
          </div>
        )}
        
          </div>
        )
      
    
              }
    else if(mode === "watch"){

      return(
      <div className="row mt-5">
  
        {loading? <Loading /> :
        <>
          <Heading size={3} className="my-3">I Sent a verification link to your inbox, Click the button when you have completed  it.</Heading>
  
          <Button  posClassName="col-lg-3 mx-auto mt-3 " borderRadius onClick={watchEmail} >Verify Email</Button>
          </>
      }
      </div>
      )
    }       
  else  
    {
    
    return (
       <div>
      <SignUpSmall3 done={()=>{setMode("watch") } }/>
      </div>
      
      )
  }

}

export default SignUp;
