import React, {useState, useEffect, useContext} from "react";
import { Button, ClassSelect, ClearButton, Heading, Input, ConfirmationModal, LoadingScreen } from "..";
import { Logic } from "../../functions";
import {v4 as uuidv4} from "uuid"
import { ClassInterface } from "../../AppContent";
import { AppContext } from "../../AppContent";
import { useIdToken } from "react-firebase-hooks/auth";

const Schedule = ()=>{
    const appContext = useContext(AppContext)
    const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)
    const [edit, setEdit] = useState<boolean>()

    const [school, setSchool]= useState<string>()
    const [year, setYear]= useState<string>()
    const [eduLevel, seteduLevel]= useState<string>()

    const [newClass, setNewClass] = useState<boolean>(false)

    const [deleteItem, setDeleteItem] = useState<ClassInterface>()
    const [confQuestion, setConfQuestion] = useState<string>("Default")
    const [conf, setConf] = useState<boolean>(false)

    const [newClassName , setNewClassName] = useState<string>()

    const [user, loading, error] = useIdToken(logic.fb.auth)


    const updateInfo = ()=>{

            const bootstrapAsync = async()=>{


                 let x = await logic.generic.updateUser({eduLevel:eduLevel, year: year, school:school})
                
                    setEdit(false)
                

            } 
        bootstrapAsync()
    }

    const addClass = ()=>{
            const bootstrapAsync = async()=>{


                   if(appContext){
                    let newId = uuidv4()
                     let x = await logic.generic.addClasses( {name: newClassName, id: newId}, appContext.classes)
                     appContext.setClasses(x)                
                    setNewClass(false)

                   }
                

            } 
         
                     bootstrapAsync()

    }

    const handleDelete = async()=>{
        if(appContext){
        let x = await logic.generic.deleteClass(deleteItem, appContext.classes)
        appContext.setClasses(x)
        
        setConf(false)
        }
    
    }

    const queueDel = (x:ClassInterface)=>{

        setDeleteItem(x)
        setConfQuestion(`Are you sure you want to delete ${x.name}? This cannot be undone`)
        setConf(true)

    }
    const inputHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: Function
      ) => {
        const enteredName = event.target.value;
        setter(enteredName);
      };



      if(appContext){
    return(
    

    <div className="container">
    <ConfirmationModal open={conf} question={confQuestion} onConfirm={handleDelete} onCancel={()=>{setConf(false)}}/>
        <div className="row mx-auto" style={{paddingTop: "20vh"}}>
            <div className="col-lg-6 px-5">
                    <Heading size={3}>Current Schedule</Heading>
                    <ClassSelect onDelete={queueDel} del nav next={()=>{}}/>

                    {newClass && <>
                     <Input
            backgroundColor="transparent"
            primary
            className="text-center"
            posClassName="col-lg-6 mx-auto my-3"
            label="New Class"
            onChange={(e: any) => {
              inputHandler(e, setNewClassName);
            }}
          /> 
          {newClassName && 
            <Button posClassName="col-lg-4 mx-auto py-3" borderRadius onClick={addClass} >save</Button>}

          
          </>}
             

          </div>

          <div className="col-lg-6">
                    <Heading size={3}>{`${logic.generic.capitalize(appContext.user.firstName)} ${logic.generic.capitalize(appContext.user.lastName)} `}</Heading>
             

                <div className="pt-3 row " >       {/* <AppIcon onClick={editHandler} icon={mdiSchool} className="mx-auto" title="School"/> */}
                <Heading size={2} align="right" posClassName={`mx-auto ${edit? "col-lg-3": "col-lg-5"}`}  >School: </Heading>
                {edit?    <Input
                            backgroundColor="transparent"
                            primary
                            className="text-center"
                            posClassName="col-lg-6 mx-auto "
                            label={logic.generic.capitalize(appContext.user.school)}
                            onChange={(e: any) => {
                            inputHandler(e, setSchool);
                            }}
                        /> :
                <Heading size={2}  posClassName="mx-auto  col-lg-6" align="left" >{logic.generic.capitalize(appContext.user.school)}</Heading>}
                </div>
                <div className="pt-3 row " >       {/* <AppIcon onClick={editHandler} icon={mdiSchool} className="mx-auto" title="School"/> */}
                <Heading size={2} align="right" posClassName={`mx-auto ${edit? "col-lg-3": "col-lg-5"}`}  >Year: </Heading>
                {edit?    <Input
                            backgroundColor="transparent"
                            primary
                            className="text-center"
                            posClassName="col-lg-6 mx-auto "
                            label={logic.generic.capitalize(appContext.user.year)}
                            onChange={(e: any) => {
                            inputHandler(e, setYear);
                            }}
                        /> :
                <Heading size={2}  posClassName="mx-auto  col-lg-6" align="left" >{logic.generic.capitalize(appContext.user.year)}</Heading>}
                </div>
                <div className="pt-3 row " >       {/* <AppIcon onClick={editHandler} icon={mdiSchool} className="mx-auto" title="School"/> */}
                <Heading size={2} align="right" posClassName={`mx-auto ${edit? "col-lg-3": "col-lg-5"}`}  >Edu Level: </Heading>
                {edit?    <Input
                            backgroundColor="transparent"
                            primary
                            className="text-center"
                            posClassName="col-lg-6 mx-auto"
                            label={logic.generic.capitalize(appContext.user.eduLevel)}
                            onChange={(e: any) => {
                            inputHandler(e, seteduLevel);
                            }}
                        /> :
                        <Heading size={2}  posClassName="mx-auto  col-lg-6" align="left" >{logic.generic.capitalize(appContext.user.eduLevel)}</Heading>}
                </div>

                <div className="pt-3 " >       {/* <AppIcon onClick={editHandler} icon={mdiSchool} className="mx-auto" title="School"/> */}
                    <Button posClassName="col-lg-4 mx-auto py-3" borderRadius onClick={()=>{setNewClass(true)}} >Add Class</Button>
                    <Button posClassName="col-lg-4 mx-auto py-3" borderRadius onClick={edit? updateInfo : ()=>{setEdit(true)}} >{edit? "Save" : "Update Info"}</Button>

                </div>
                    

          </div>
        </div>

    </div>
    )
                        }
else{
    return<LoadingScreen />
}
              

}




export {Schedule}