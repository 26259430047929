import {v4 as uuidv4} from "uuid";
import React, { useRef, useState, useEffect } from "react";
import { throttle } from 'lodash';
import useResizeObserver from '@react-hook/resize-observer';
import { Note } from "../AppContent";
import { Generic } from "./components";

interface ScrollPosition {
    x: number;
    y: number;
}

class Singleton<T> {
    singleton: T;

    constructor(provider: () => T) {
        this.singleton = provider();
    }

    getSingleton() {
        return this.singleton;
    }
}

export const Hooks:{[key: string]: Function}  ={


     useComponentId(): string {
        const singleton = new Singleton<string>(uuidv4);
        return singleton.getSingleton();
    },

     useScrollDirection() {
        const prevScrollY = useRef(0);
        const [goingUp, setGoingUp] = useState(false);

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current < currentScrollY && goingUp) {
                setGoingUp(false);
            }
            if (prevScrollY.current > currentScrollY && !goingUp) {
                setGoingUp(true);
            }
            prevScrollY.current = currentScrollY;
        };

        useEffect(() => {
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, [goingUp]);
        return goingUp ? 'up' : 'down';
    },

     useScrollPosition() {
        const [scrollPosition, setScrollPosition] = useState(0);

        useEffect(() => {
            const updatePosition = () => {
                setScrollPosition(window.pageYOffset);
            }
            window.addEventListener("scroll", updatePosition);
            updatePosition();
            return () => window.removeEventListener("scroll", updatePosition);
        }, []);

        return scrollPosition;
    },

     useScroll(ref: React.RefObject<HTMLDivElement>) {
        const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

        useEffect(() => {
            const handleScroll = throttle((e: any) => {
                if (e.currentTarget) {
                    setScrollPosition({ x: e.currentTarget.scrollLeft, y: e.currentTarget.scrollTop });
                }
            }, 100);

            if (ref.current) {
                ref.current.addEventListener('scroll', handleScroll);
            }
            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('scroll', handleScroll);
                }
            };
        }, [ref]);
        console.log(scrollPosition)
        return scrollPosition;
    },

     useSize  (target: any) {
      const [size, setSize] = React.useState<any>()
    
      React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
      }, [target])
    
      // Where the magic happens
      useResizeObserver(target, (entry) => setSize(entry.contentRect))
      return size
    },
    
     useNoteSave(delay: number, apiCall: Function, initValue: any)  {
      
      const [notInit, setNotInit] = useState()
      const [state, setState] = useState(initValue);

      useEffect(() => {
        const throttledApiCall = throttle(async() => {
          let x =  await apiCall(state);

        }, delay);
        let x = JSON.stringify(state)
        let y = JSON.stringify(notInit)
        
        if(state.id !== "place" ){
        if(notInit){
        if(x !== y &&  state.id !== "place" ){
            
            throttledApiCall();
        }
      }
      else{
        setNotInit(state)
      }
      } 
      }, [state]);
    
      return [state, setState];
      
      },

     useIsInViewport (ref: React.RefObject<HTMLElement>){
        
        const [isInViewport, setIsInViewport] = useState(false);
      
        useEffect(() => {
          const handleIntersect = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                setIsInViewport(true);
              } else {
                setIsInViewport(false);
              }
            });
          };
      
          if (ref.current) {
            const observer = new IntersectionObserver(handleIntersect);
            observer.observe(ref.current);
            return () => observer.disconnect();
          }
        }, [ref]);
      
        return isInViewport;
      },

    

}



