import React, { useEffect, useState, useContext} from 'react';

import { Logic } from '../../functions';
import { useNavigate, useLocation } from 'react-router';

import { DoodleDiv, OverviewSlide, SlideControl } from '../../styled';
import { AppIcon, Button, Heading, Loading } from "../index";
import { mdiArrowLeftThick, mdiArrowRightThick } from '@mdi/js';
import { Form } from 'react-bootstrap';
import { ThemeContext } from '../../App';

const Next = (props:{onClick:Function})=>{

    return(
        <SlideControl className="w3-display-right">

            <AppIcon
                title="back"
                icon={mdiArrowRightThick}
                onClick={() => {props.onClick()
                  
                }}
            
              ></AppIcon>
        </SlideControl>

    )
}



const Back = (props:{onClick:Function})=>{

    return(
        <SlideControl className="w3-display-left">
            <AppIcon
                title="back"
                icon={mdiArrowLeftThick}
                onClick={() => {props.onClick()
                  
                }}
            
              ></AppIcon>
        </SlideControl>
        
    )
}

const Feedback = (props:{data:any})=>{


    useEffect(()=>{

        console.log(props)
    })


    return(<>
    
        
    </>)
}


const Quiz = (props:{ course:string, topic:string, item:any, history:Function})=>{
    const errorHandler = async()=>{

        console.log("errorHandler")
      };
      const permsHandler = async()=>{
          navigate("/learn?sub")
      }
    
      const logic = new Logic(permsHandler, errorHandler, errorHandler)
      const location = useLocation()
    const navigate = useNavigate()
    const themeC = useContext(ThemeContext).theme
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>()
    const [question, setQuestion] = useState<any>()
    const [answer, setAnswer] = useState<string>()
    const [testI, setTestI] = useState<number>(0)
    const [finalData, setFinalData] = useState<any>([])    
    const [feedback, setFeedback] = useState<boolean>(false)

    const [onDeck, setOnDeck] = useState<string>()
    const [sesh, setSesh] = useState<any[]>()


    useEffect(()=>{

        const bootstrapAsync = async () =>{

            
                let course = props.item.data.class
                let topic =  props.item.data.topic
            
            
            let x = await logic.eloise.practiceQuestion(course,topic)
            if(x){
            setQuestion(x)
            setSesh([x])

             
              setLoading(false)

            }

            //   navigate("/learn?el", {state: {prompt:"When is this test?", special: "testDate", course:props.course, topic:props.topic, data:{ query: `${props.topic}-Overview`, answer: x , type:"overview" ,class:props.course, topic:props.topic }}})

            
        }
    
    
          setLoading(true);
          if(location.search !== "?sub"){
            bootstrapAsync();
              }
      

        

    },[])

    const grade = (x:any)=>{
        const bootstrapAsync = async () =>{
                 setLoading(true)
              let x = await logic.eloise.grade({question:question, answer: answer})
              if(x){
              console.log(x)
              setQuestion(x)
              
              setFeedback(true)
              setLoading(false)
              }
              let y = await logic.eloise.practiceQuestion(props.course, props.topic, sesh)
              if(y){
                setOnDeck(y)
              }

            }
        bootstrapAsync()



    }
    


    const next = ()=>{

       setFeedback(false)
       setQuestion(onDeck)
       setAnswer(undefined)

    }

    const handleChange = (event: any) => {
        setAnswer(event.target.value);
    };
 
    return(
        <>
            {loading? <Loading />:
            < div className='container'>

           
           <>
            < div className='row'>

                <Heading size={2} posClassName="pb-5">{question}</Heading>


             <Form className="col-lg-6 mx-auto">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control className="writeBox" as="textarea" style={{ height: "20vh", border: themeC.mode=="chalk"? "4px solid white": "4px solid black"}} name="address" value={answer} onChange={handleChange} />
                            </Form.Group>
                        </Form>
            
            
            </div>
            < div className='row py-4'>

           {(answer  || feedback) && <Button borderRadius posClassName="col-lg-3 mx-auto" onClick={feedback? next: grade}>{!feedback? "Submit": "Next"}</Button> }
            </div>
                </> 


            
            </div>

     } </>
    )
}


export {Quiz}


