import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import {  Input, Button,} from "../";
import { useNavigate } from "react-router";

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Doodle } from "./doodle";
import { Logic } from "../../functions";
import { ThemeContext } from "../../App";

const Login = (props: { next: Function, className?: string }) => {
  const themeC = useContext(ThemeContext).theme
  const navigate = useNavigate();
  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>("Bad");

  const [password, setPassword] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();

  const [errors, setErrors] = useState<string[]>([]);

  const [user, loading, error] = useAuthState(logic.fb.auth);

  const submit = () => {
    // setLoading(true);

    if (email && password) {
      props.next(email, password);
    }

    // const userData: { [key: string]: string } = {
    //   email: email ? email : "email",
    //   password: password ? password : "password",
    // };

    // axios
    //   .post(
    //     `${logic.api}/login`,
    //     //  "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/api/login",

    //     userData
    //   )
    //   .then((response: any) => {
    //     localStorage.setItem("AuthToken", `Bearer ${response.data.auth}`);
    //     localStorage.setItem("refreshToken", response.data.refresh);

    //     localStorage.setItem("email", userData.email);

    //     if(props.next){
    //       props.next(`Bearer ${response.data.token}`)
    //     }

    //   })
    //   .catch((error: any) => {
    //     setErrors([...errors, error]);
    //   });
  };

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: Function
  ) => {
    const enteredName = event.target.value;
    setter(enteredName);
  };

  return (
        <div className={props.className ?? ""}>
        {email && password ? (
          <>
            <Button
              borderRadius
              posClassName="col-lg-6 mx-auto"
              fontSize="2.3rem"
              padding="0"
              style={{
                marginBottom: ".5rem",
              }}
              onClick={() => submit()}
            >
              Log in
            </Button>
          </>
        ) : (
          <>
            <Button
              noHover
              borderRadius
              posClassName="col-lg-6 mx-auto"
              fontSize="2.3rem"
              padding="0"
              transparent
              style={{
                marginBottom: ".5rem",
              }}
            >
              Log in
            </Button>
          </>
        )}

        
        <>
          <Input
            backgroundColor="transparent"
            primary
            className="text-center"
            posClassName="col-lg-9 mx-auto my-3"
            label="Email"
            onChange={(e: any) => {
              inputHandler(e, setEmail);
            }}
          />
          <Input
            
            primary
            type="password"
            className="text-center"
            posClassName="col-lg-9 mx-auto my-3 "
            label="Password"
            onChange={(e: any) => {
              inputHandler(e, setPassword);
            }}
          />
          <p  style={{color: themeC.mode == "chalk"? "white": "black" }}className="text-center">
            Don't Have an Account?{" "}
            <span onClick={() => navigate("/signup")}>
              <strong>Sign Up</strong>
            </span>
          </p>
        </>

        </div>
      )

      
};

export default Login;
