import React, { useState, useEffect, useRef, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

//Component Imports
import { Heading } from "../";
import { Logic } from "../../functions";
import { WriteHistoryItem } from "../../styled";
import { ThemeContext } from "../../App";

import { useNavigate } from "react-router";
import theme from "../../theme";

const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

const WriteHistory = (props: { historyHandler: Function }) => {
  
  const [history, setHistory] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>();
  const navigate = useNavigate();
  const themeC = useContext(ThemeContext).theme

  useEffect(() => {
    window.addEventListener("activity", () => {
      setRefresh(!refresh);
    });
  }, []);

  useEffect(() => {
    let x = localStorage.getItem("activity");

    if (x !== null) {
      let parsed = JSON.parse(x);
      let write = parsed.filter((x:any) => x.app == "write")
      setHistory(write);
    }
  }, [refresh]);

  return (
    <div className="row">
      {history &&
        history.map((item: any) => (
          <WriteHistoryItem
            key={uuidv4()}
            className="my-3 col  mx-3"
            onClick={() => {
              localStorage.setItem("writeItem", JSON.stringify(item));
              navigate("/collab");
            }}
            style={{
              border: themeC.mode === "chalk"? "4px solid rgba(255,255,255,.8)": "4px solid black"

            }}
          >
            <Heading
              style={{
                textDecoration: `underline solid 3px ${theme.primary} `,

              }}
              white
              size={1}
            >{item.data.name}</Heading>
          </WriteHistoryItem>
        ))}
    </div>
  );
};

export { WriteHistory };
