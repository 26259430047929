import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
//Component Imports
import {

  Heading, Life,

} from "../";
import { Logic } from "../../functions";
import {  HistoryItem } from "../../styled";


const LearnHistory = (props: { historyHandler: Function, mode:string, topic?: any}) => {
 
  const [history, setHistory] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false)



  useEffect(() => {
      window.addEventListener('activity', () => {
        setRefresh(!refresh);
      })

    
  }, []);

  useEffect(() => {

    if(props.topic){
    }
    let x = localStorage.getItem(`activity`);

    if (x !== null) {
      let parsed = JSON.parse(x);
      if(parsed !== history){
        let onMode = parsed.filter((x:any)=> x.app === props.mode)
      setHistory(onMode);
      }
      
    }
  }, [refresh]);



  if(props.mode==="learn"){
    
    
    if(props.topic){
      return(
      <div>
        <Heading size={4} handWritten style={{textDecoration:"underline"}}>{props.topic.data.name}</Heading>

        <Life />

      </div>
      )
    }
    else{
    return (
    <>
            <Heading size={4} handWritten style={{textDecoration:"underline"}}>Recent</Heading>

      {history &&
        history.map((item: any) => (
          <HistoryItem
            key={uuidv4()}
            className="my-3"
            onClick={() => {
              props.historyHandler(item);
            }}
          >
            
            <Heading white size={1}>{item.data.name}</Heading>
          </HistoryItem>
        ))}
    </>
  )}}
  else{
    if(props.topic){
      return(
      <div>
        <Heading size={4} handWritten style={{textDecoration:"underline"}}>{props.topic.data.name}</Heading>



      </div>
      )
    }
    else{
    return(
        <>
        <Heading size={4} handWritten style={{textDecoration:"underline"}}>Recent</Heading>

        {history &&
          history.map((item: any) => (
            <HistoryItem
              key={uuidv4()}
              className="my-3"
              onClick={() => {
                props.historyHandler(item);
              }}
            >
              
              <Heading white size={1}>{item.name}</Heading>
            </HistoryItem>
          ))}
      </>
    )
            }
  }
};

export { LearnHistory };
