import React, { useState, useEffect, createContext, useContext } from "react";

import { Frosted, Icycle, Time, Controls, ControlButton, Home, ChatOp } from "../../styled";
import { mdiHome } from "@mdi/js";
import Icon from "@mdi/react";

import { Navigate, useNavigate , useLocation} from "react-router";

import { AppIcon } from "../";


interface FrostBoxProps {
  transparent?: boolean;
  tour?: boolean;
  style?:any;
  children: React.ReactNode;
  gridId?: any;
  main?: boolean;
  minWidth?: 3 | 4 | 6 | "auto";
  minHeight?: any;
  shrink?: Function;
  mid?: boolean;
  parentProps?: any;
  noControls?: boolean;
  key: string;
  home?: boolean;
  noLeft?:boolean;
  noRight?:boolean;
  noTop?:boolean;
  noBottom?:boolean;
  className?:string;
  mode?: string;
}
const FrostContext = createContext<{ [key: string]: any }>({});

const FrostBox = (props: FrostBoxProps) => {

  const location = useLocation()
  let navigate = useNavigate();


  const [width, setWidth] = useState(props.minWidth);


  let test: boolean = props.noControls
    ? true
    : props.main
    ? props.mid
      ? props.main == true && props.mid == true
      : false
    : false;

  const ButtonControlHandler = () => {
   navigate("/")
  };

  

  return (
    <div style={{position: "relative"}}>
 {props.home && <Home onClick={()=>navigate("/")}/> }
      {props.home && <ChatOp onClick={()=>{
        let current = location.pathname
        let search = location.search
        let state = location.state
        if(search){
          navigate(current )
        }
        else{
        navigate(current + "?el", {state:state})}}}/> }

    
    <Frosted
    $mode={props.mode? props.mode: false}
    $noleft={props.noLeft}
    $noright={props.noRight}
    $notop={props.noTop}
    $nobottom={props.noBottom}
    $main={props.main}
      $height={props.minHeight}
      $transparent={props.transparent}
      $notouch={props.tour}
      className={props.className }
    >
    

      <div
        className={`mx-auto  `}
        style={
          {
           ...props.style,
          }
        }
      >
      
       

        <FrostContext.Provider value={{ ...props }}>
          {props.children}
        </FrostContext.Provider>
      </div>
    </Frosted>
    </div>
  );
};

export { FrostBox, FrostContext };
