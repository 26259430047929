// Normal Display Mode
// Dependency Imports
import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { ThemeContext } from "../../App";
//Component Imports
import {
  Heading,

  FrostBox, // Stuctural
  Weather,
  Loading,
  CountDown,
  Apps, // Widgets
  Input,
  ClearButton,
  Switch,
  Upcoming,
  Login,
  Chat,
  Todo,
} from "..";

import { Navigate, useLocation, useNavigate } from "react-router";
import { ChatMessage, Icycle } from "../../styled";
import { Logic } from "../../functions";
import axios from "axios";


const NormalTour = (props:{setEloise:Function}) => {

  const errorHandler = async()=>{};const logic = new Logic(errorHandler, errorHandler, errorHandler)

  const themeC = useContext(ThemeContext).theme
  const navigate = useNavigate();
  const [weather, setWeather] = useState<boolean>(false);
  const location = useLocation();
  const [modeSwitch, setModeSwitch] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false);

  const [educationLevel, setEducationLevel] = useState<string>()
  const [year, setYear] = useState<string>()
  const [classes, setClasses] = useState<string[]>()

  const [latest, setLatest] = useState<boolean>(true);
  const [apps, setApps] = useState<boolean>(true);

  const [history, setHistory] = useState<boolean>(true);
  const [countDown, setCountDown] = useState<boolean>(true);
  const [school, setSchool] = useState<string>()
  const [nextButton, setNextButton] = useState<boolean>(true);
  const [nextButton2, setNextButton2] = useState<boolean>(false);

  const [nextButtonText, setNextButtonText] = useState<string>("ok");
  const [ui, setUi] = useState<boolean>(false);
  const [tourSequence, setTourSequence] = useState<number>(0);

  const [answer, setAnswer] = useState<string>(
    "Welcome to your dashboard! Let me show you around a bit"
  );

  const next = (x?:string) => {
    const bootstrapAsync = async () => {
    switch (tourSequence) {
      case 0:
        setAnswer(
          "Alrighty! First off we have your apps over there, You can use these as shortcuts to get to different functions I offer. They scroll by the way..."
        );
        setModeSwitch(false)
        setHistory(false);
        setLatest(false);
        setCountDown(false);
        break;
      case 1:
        setAnswer(
          "Next we have Todos, I will add items here when needed for you to do. Right now all you have is setting up the rest of your classes"
        );
        setHistory(false);
        setLatest(false);
        setCountDown(true);
        setApps(false);
        break;
      case 2:
        setAnswer("This is where your upcoming assignments will be notice the test we added earlier.");
        setHistory(false);
        setLatest(true);
        setCountDown(false);
        setApps(false);
        break;
      case 3:
        setAnswer(
          "Over here we have your history, When you use different apps your work will be saved so you can bring it back later. Make sure to check out the guides I made for you"
        );
        setHistory(true);
        setLatest(false);
        setCountDown(false);
        setApps(false);
        break;
      case 4:
          setAnswer(
            "Here you have a little switch, This controls the color scheme of your app. Click it to change back and forth"
          );
          setModeSwitch(true)
          setHistory(false);
          setLatest(false);
          setCountDown(false);
          setApps(false);
          setUi(false);
          break;
      case 5:
        setAnswer(
          "Finally there is my block! We can talk about anything you want, Just ask, I know a lot about a lot of things...You get it?(type yes to continue)"
        );
        setModeSwitch(false)
        setHistory(false);
        setLatest(false);
        setCountDown(false);
        setApps(false);
        setNextButton(false);
        setUi(true);
        break;
      case 6:
        setAnswer(
          "Ok great, you're a fast learner. You are cleared to move on. I went ahead and added the course and test you entered earlier into your calender, visit the schedule app to add the rest of your classes"
        );
        setNextButton(true);
        setNextButtonText("Start Exploring");
        setUi(false);
        break;

    case 7:
        props.setEloise("Hey Friend! Im Eloise, Im looking forward to getting to know you and helping with your educational needs. I can make flashcards, give you writing assistance, and we can take notes right in class together! If you need me to take you anywhere on the site or just have some questions for me. Maybe you just wanna chat. Im always here. Ask me anything you want below and enjoy :)")
        navigate("/");
    }

  }
    bootstrapAsync()

    setTourSequence(tourSequence + 1);
  };



  return (
    <>
      <div>

       
          <div className=" row  g-0  flex-lg-nowrap">
            <Icycle className={`mx-auto col-lg-3  `} md="12" sm="12" xs="12">
              <FrostBox
                tour
                mode={themeC.mode}
                noTop
                noBottom
                noLeft
                noRight
                transparent={!latest}
                gridId={uuidv4()}
                key={uuidv4()}
                noControls
                minHeight={45}
              >
               <Upcoming  />
              </FrostBox>

              <FrostBox
                tour
                mode={themeC.mode}
                noLeft
                noRight
                transparent={!apps}
                noControls
                minHeight={15}
                gridId={uuidv4()}
                key={uuidv4()}
              >
                <Apps />
              </FrostBox>
              <FrostBox
                tour
                mode={themeC.mode}
                noTop
                noBottom
                noLeft
                noRight
                transparent={tourSequence !== 0}
                noControls
                minHeight={40}
                gridId={uuidv4()}
                key={uuidv4()}
              >
               <Login  next={()=>{}}/>
              </FrostBox>
            </Icycle>

            <Icycle className={`mx-auto col-lg-6  `} md="12" sm="12" xs="12">
              <FrostBox
                main
                mode={themeC.mode}
                noTop
                noBottom
                noControls
                minHeight={100}
                gridId={uuidv4()}
                key={uuidv4()}
              >


                <div className="p-4">
                  {loading? <Loading /> :
                  <>
                  {answer && (
                    <ChatMessage message={{eloise:answer.replace(/["]+/g, "")}} />
                  )}
                  </>}

                  {(ui && !loading) && (
                    <Input
                      backgroundColor="transparent"
                      primary
                      className="text-center"
                      posClassName="col-lg-6 mx-auto py-5"
                      onEnter={next}
                      label={" "}
                    />
                  )}
                  {nextButton && (
                    <ClearButton posClassName="col-lg-6 mx-auto mb-5" onClick={next}>
                      {nextButtonText}
                    </ClearButton>
                  )}
                   {nextButton2 && (
                    <ClearButton posClassName="col-lg-6 mx-auto" onClick={()=>{props.setEloise("Hey Friend! Im Eloise, Im looking forward to getting to know you and helping with your educational needs. I can make flashcards, give you writing assistance, and we can take notes right in class together! If you need me to take you anywhere on the site or just have some questions for me. Maybe you just wanna chat. Im always here. Ask me anything you want below and enjoy :)");
                    navigate("/")}}>
                      Set Up Later
                    </ClearButton>
                  )}
                </div>
              </FrostBox>
            </Icycle>

            <Icycle className={`mx-auto col-lg-3  `} md="12" sm="12" xs="12">
              <FrostBox
                tour
                mode={themeC.mode}
                noTop
                noBottom
                noLeft
                noRight
                transparent={!countDown}
                gridId={uuidv4()}
                key={uuidv4()}
                noControls
                minHeight={30}
              >
                <Todo />
              </FrostBox>
            <FrostBox
                mode={themeC.mode}
                    noLeft
                    noRight
                                       
                minHeight={10}
                transparent={!modeSwitch}

              gridId={uuidv4()}
                key={uuidv4()} 



            >
                <Switch isOn={false} handleToggle={()=>{}}/>
            </FrostBox>
              <FrostBox
                tour
                mode={themeC.mode}
                noTop
                noBottom
                noLeft
                noRight
                transparent={!history}
                noControls
                minHeight={60}
                gridId={uuidv4()}
                key={uuidv4()}
              >
                <Weather  handler={()=>{}}/>
              </FrostBox>
              
            </Icycle>
          </div>

      </div>
    </>
  );
};

export { NormalTour };
