import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  ClassSelect,
  ClearButton,
  Heading,
  Input,
  AddEvent,
  LoadingScreen,
  AppIcon,
} from "..";

import { ThemeContext } from "../../App";
import { Back, HistoryItem } from "../../styled";
import { Logic } from "../../functions";
import { useNavigate } from "react-router";
import { AppContext, EventInterface } from "../../AppContent";
import {
  mdiArrowRight,
  mdiArrowLeft,
  mdiPlus,
  mdiCalendarMonthOutline,
} from "@mdi/js";

const getOrdinalNum = (number: number) => {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ["th", "st", "nd", "rd", ""][selector];
};

const CalendarDay = (props: {
  day: Date;
  events: any;
  clickE: Function;
  classEvent: Function;
  small?: boolean;
  pref?: React.RefObject<HTMLDivElement>;
}) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const themeC = useContext(ThemeContext).theme;
  const [all, setAll] = useState<EventInterface[]>();
  const [tests, setTests] = useState<EventInterface[]>();
  const [exams, setExams] = useState<EventInterface[]>();
  const [day, setDay] = useState<Date>();
  const [month, setMonth] = useState<string>("January");
  const [off, setOff] = useState<number>(0);

  const errorHandler = async () => {};
  const logic = new Logic(errorHandler, errorHandler, errorHandler);

  const handleClick = (x: any) => {
    navigate("/event", { state: { event: x } });
  };

  useEffect(() => {
    if (appContext) {
      let tempDay = props.day;
      let tmonth = tempDay.toLocaleString("en-us", { month: "long" });
      let dow = tempDay.getDay();
      let test = appContext.events.filter(
        (x: any) =>
          new Date(x.date).toDateString() == new Date(tempDay).toDateString()
      );
      let ass = test.filter((x: any) => x.type === "ass");
      let tests2 = test.filter((x: any) => x.type === "exam");
      setOff(dow);
      setMonth(tmonth);
      setAll(test);
      setTests(tests2);
      setExams(ass);
      setDay(tempDay);
    }
  }, []);

  return (
    <>
      {day && (
        <>
          {day.getDate() === 1 && props.small && (
            <>
              <div className="row my-3">
                <Heading size={3}>{month}</Heading>
              </div>

              {Array.from({ length: off }, (v, k) => k + 1).map(
                (v: any, k: any) => (
                  <div
                    className="col"
                    key={`${month}off${k}`}
                    style={{
                      minWidth: props.small ? "14.25vw" : "20vw",
                      maxWidth: props.small ? "14vw" : "20vw",
                    }}
                  />
                )
              )}
            </>
          )}
          <div
            ref={props.pref ?? null}
            className="col"
            style={{
              minWidth: props.small ? "14.25vw" : "20vw",
              maxWidth: props.small ? "14vw" : "20vw",
            }}
          >
            {!props.small && (
              <Heading
                size={1}
                style={{ textDecoration: "underline" }}
              >{`${day.toLocaleString("en-us", {
                weekday: "long",
              })} ${day.toLocaleString("en-us", {
                month: "long",
              })} ${getOrdinalNum(day.getDate())}`}</Heading>
            )}

            <div
              style={{
                borderRadius: "5px",
                border: `2px solid ${
                  themeC.mode == "chalk" ? "white" : "black"
                }`,
                minHeight: props.small ? "30vh" : "77vh",
                padding: "10px",
              }}
            >
              {props.small && (
                <div className="d-flex justify-content-between">
                  <Heading size={2}>{JSON.stringify(day.getDate())}</Heading>
                  <AppIcon
                    icon={mdiPlus}
                    size={1.3}
                    onClick={() => {
                      props.clickE("none", day);
                    }}
                    title="new"
                    className="align-self-center p-o"
                  />
                </div>
              )}
              {props.small ? (
                <>
                  {all &&
                    all.map((test: any) => (
                      <HistoryItem
                        onClick={() => {
                          handleClick(test);
                        }}
                        key={uuidv4()}
                      >
                        <Heading key={uuidv4()} size={2}>
                          {test.name}
                        </Heading>
                      </HistoryItem>
                    ))}
                  <div className="mx-auto text-center">
                    {/* <AppIcon icon={mdiPlus} onClick={() => { props.clickE("none", day) }} title="new" /> */}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      minHeight: props.small ? "20vh" : "35vh",
                    }}
                  >
                    <Heading
                      style={{ textDecoration: "3px underline" }}
                      size={1}
                    >
                      Assignments
                    </Heading>
                    <Button
                      fontSize=".8rem"
                      onClick={() => {
                        props.clickE("ass", day);
                      }}
                      transparent
                      borderRadius
                      posClassName="pb-2"
                    >
                      New Assignment
                    </Button>

                    {exams &&
                      exams.map((test: any) => (
                        <HistoryItem
                          onClick={() => {
                            handleClick(test);
                          }}
                          key={uuidv4()}
                        >
                          <Heading key={uuidv4()} size={2}>
                            {test.name}
                          </Heading>
                        </HistoryItem>
                      ))}
                  </div>
                  <>
                    <Heading style={{ textDecoration: "underline" }} size={1}>
                      Tests
                    </Heading>
                    <Button
                      fontSize=".8rem"
                      onClick={() => {
                        props.clickE("exam", day);
                      }}
                      transparent
                      borderRadius
                      posClassName="pb-2"
                    >
                      New Test
                    </Button>

                    {tests &&
                      tests.map((test: any) => (
                        <HistoryItem
                          onClick={() => {
                            handleClick(test);
                          }}
                          key={uuidv4()}
                        >
                          <Heading size={2}>{test.name}</Heading>
                        </HistoryItem>
                      ))}
                  </>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
const Calander = (props: { classEvent: Function }) => {


  const errorHandler = async () => {};
  const logic = new Logic(errorHandler, errorHandler, errorHandler);
  const appContext = useContext(AppContext);
  const themeC = useContext(ThemeContext).theme;
  const [events, setEvents] = useState();
  const [week, setWeek] = useState<Date>(new Date());
  const [unchangedWeek, setUnchangedWeek] = useState<Date>(new Date());
  const [add, setAdd] = useState<boolean>();
  const [addDate, setAddDate] = useState<any>();
  const [type, setType] = useState<string>();
  const [headingText, setHeadingText] = useState<string>("This Week");
  const [smallView, setSmallView] = useState<boolean>(true);
  const [lastDay, setLastDay] = useState(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())))

  const compId = logic.hooks.useComponentId()
 const scrollContainerRef = useRef<HTMLDivElement>(null);
  const inView = logic.hooks.useIsInViewport(scrollContainerRef);

  const [days, setDays] = useState<any[]>([]);
  const [daysToLoad, setDaysToLoad] = useState(30); // initially load 30 days
  const [isLoading, setIsLoading] = useState(false);


  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const loadDays = () => {
    if (appContext) {    

    
        let newDays = [];
        for (let i = 0; i < daysToLoad; i++) {
          let day = new Date(lastDay);
          day.setDate(day.getDate() + i);
          newDays.push(
            <CalendarDay
              small={smallView}
              classEvent={props.classEvent}
              day={day}
              key={`${compId}.calDay.${day.getMonth()}.${day.getDate()}`}
              clickE={newHandler}
              events={appContext.events}
            ></CalendarDay>
          );
        }
        let lday = new Date(lastDay);
        lday.setDate(lday.getDate() + daysToLoad);
        setLastDay(lday);
        setDays([...days, ...newDays]);
    }
  }


  useEffect(() => {
    if (!isLoading && inView) {
      loadDays();
    }
  }, [inView]);
 

  const newHandler = (type: string, date: any) => {
    setAddDate(date);
    if (type !== "none") {
      setType(type);
    }
    setAdd(true);
  };

  const prevWeek = () => {
    let currentWeek = new Date(week);
    if (currentWeek) {
      currentWeek.setDate(currentWeek.getDate() - 7);
      setWeek(currentWeek);
      if (currentWeek.getDate() === unchangedWeek.getDate() - 7) {
        setHeadingText("Last Week");
      } else if (currentWeek.getDate() === unchangedWeek.getDate()) {
        setHeadingText("This Week");
      } else if (currentWeek.getDate() === unchangedWeek.getDate() + 7) {
        setHeadingText("Next Week");
      } else {
        setHeadingText("Week of " + currentWeek.toDateString());
      }
    }
  };

  const nextWeek = () => {
    let currentWeek = new Date(week);
    if (currentWeek) {
      currentWeek.setDate(currentWeek.getDate() + 7);
      setWeek(currentWeek);
      if (currentWeek.getDate() === unchangedWeek.getDate() - 7) {
        setHeadingText("Last Week");
      } else if (currentWeek.getDate() === unchangedWeek.getDate()) {
        setHeadingText("This Week");
      } else if (currentWeek.getDate() === unchangedWeek.getDate() + 7) {
        setHeadingText("Next Week");
      } else {
        setHeadingText("Week of " + currentWeek.toDateString());
      }
    }
  };

  const monthView = () => {
    setWeek(unchangedWeek);
    setSmallView(!smallView);
  };

  const save = (x: any) => {
   setAdd(false)
  };
  

 




  if (appContext) {
    return (
      <>
        {add ? (
          <AddEvent
            date={addDate}
            done={save}
            type={type}
            cancel={() => setAdd(false)}
          />
        ) : (
          <div>
            <Back onClick={monthView} icon={mdiCalendarMonthOutline} />

            <div
              className="d-flex justify-content-center align-items-center my-3"
               style={{ position: "sticky", top: "0", backgroundColor: "transparent", zIndex: 1 }}
            >
              {smallView && (
                <div className="d-flex">
                  {daysOfWeek.map((day, index) => (
                    <Heading
                      key={`${compId}.dow.${index}`}
                      size={3}
                      posClassName="col"
                      style={{ width: "14vw" }}
                      handWritten
                      className="text-center mr-3"
                    >
                      {day}
                    </Heading>
                  ))}
                </div>
              )}
              {!smallView && (
                <>
                  <AppIcon
                    className="mr-3"
                    onClick={prevWeek}
                    title="back"
                    icon={mdiArrowLeft}
                  />
                  <Heading size={5} handWritten className="text-center">
                    {headingText}
                  </Heading>
                  <AppIcon
                    className="ml-3"
                    onClick={nextWeek}
                    title="forward"
                    icon={mdiArrowRight}
                  />
                </>
              )}
            </div>

            {smallView ? (
              <div
              className="row g-0 mx-auto"
              style={{ overflowY: "scroll" , height: "92vh", width: "100%", position: "relative", zIndex: 2 }}              >
                {days}

                <div
                style={{minHeight: "10px"}}
                ref={scrollContainerRef}
                >
                </div>
              </div>
            ) : (
              // <div className="row g-0 mx-auto" style={{overflowY: "auto"}} >
              //     {week && Array.from({ length: 30 }, (v, k) => k + 1).map((v: any, k: any) =>{
              //             let day = new Date(unchangedWeek)
              //              day.setDate(day.getDate() + k)
              //     return  <CalendarDay small={smallView} classEvent={props.classEvent} day={day} key={uuidv4()} clickE={newHandler} events={appContext.events}></CalendarDay>

              //     }

              //     )}
              // </div>
              <div className="row g-0 mx-auto">
                {week &&
                  Array.from({ length: 5 }, (v, k) => k + 1).map(
                    (v: any, k: any) => {
                      let day = new Date(week);
                      day.setDate(day.getDate() + k);
                      return (
                        <CalendarDay
                          small={smallView}
                          classEvent={props.classEvent}
                          day={day}
                          key={uuidv4()}
                          clickE={newHandler}
                          events={appContext.events}
                        ></CalendarDay>
                      );
                    }
                  )}
              </div>
            )}
          </div>
        )}
      </>
    );
  } else {
    return <LoadingScreen />;
  }
};

export { Calander };
